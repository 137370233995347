import React, { Component } from 'react';
import './NotFound.css';

class NotFound extends Component {
  render(){
  return (
    
    <div className="row">
    <div className="medium-12 columns">
      <h3>404 Page</h3>
    </div>          
  </div>

  );
}
}
export default NotFound;
