import React, {useEffect, useState, useRef} from "react";
import axios from "axios";
import {  useParams } from "react-router-dom";
import { Document, Page } from "react-pdf";
import jsPDF from 'jspdf'
import 'jspdf-autotable';
import './exportToPdf.css'
import domtoimage from 'dom-to-image';
import signatureIcons from '../../../../media/signatureIcons.png';
import imageIcon from '../../../../media/imageIcon.png';
import logo from '../../../../media/WorxItLogo.png'


const ExportToPDF = ({mapRef}) => {

  const { palletReturnsNo } = useParams();  
  const [showPalletTbl, setShowPalletTbl] = useState([]);
  const [showPalletItemsTbl, setShowPalletItemsTbl] = useState([]);
  const [pdfOpen, setPdfOpen] = useState(false);
  const [file, setFile] = useState(null);

  useEffect(() => {
    palletTbl();
    palletItemsTbl();
    
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, []);

  const palletTbl = async () => {
    console.log(palletReturnsNo);
    const result = await axios.get(
      "https://worxit.co.za/api/zamatrack/showPalletDetails.php?palletReturnsNo=" +
      palletReturnsNo
    );
    setShowPalletTbl(result.data.records);
 
  };

  const palletItemsTbl = async () => {
    console.log(palletReturnsNo);
    const result = await axios.get(
      "https://worxit.co.za/api/zamatrack/palletItemsTable.php?palletReturnsNo=" +
      palletReturnsNo
    );
    setShowPalletItemsTbl(result.data.records)
  };
  

 

  //------------------COMBINED TABLES-----------------

  //------------------DISPATCH TABLE---------------
  const combinedTblPdfExport = async () => {
    const pdf = new jsPDF();

      //------Image------
    pdf.addImage(logo, "PNG", 10, 3, 20, 20);
   
    //-----Report Date------
    const reportDate = new Date().toLocaleDateString();
    pdf.setFontSize(10);
      pdf.text(`Report Date: ${reportDate}`, 155, 10);

    //------Title------
    pdf.setFontSize(12);
    pdf.setFont('helvetica', 'bold');
    pdf.text(80, 20, 'Proof of Pallet Returns Report');
    pdf.setFont('helvetica', 'normal');
   pdf.text(15, 30, 'Pallet Returns Details');   

    const data = 
    showPalletTbl.map((details) => [
      details.palletReturnsNo,
      details.dateTime,
      details.siteNo, 
      details.receiversName,
      details.guardsName, 
    ])

      const RecSignature = showPalletTbl.map((details) => details.signRec)[0];
      const guardsSignature = showPalletTbl.map((details) => details.signGuard)[0];
      const images = showPalletItemsTbl.map((details) => details.images);
      const images2 = showPalletItemsTbl.map((details) => details.images2);
        
      const mapContainer = mapRef.current;
      const canvas = await domtoimage.toPng(mapContainer.getContainer());
              
      const tableData = [['Pallet Returns No', ...data.map((row) => row[0])], 
      ['Date Time', ...data.map((row) => row[1])],
      ['Site No', ...data.map((row) => row[2])],
      ['Receivers Name', ...data.map((row) => row[3])],
      ['Signature'],
      ['Guards Name', ...data.map((row) => row[4])],
      ['Signature'],
      // ['Read view of load'],
      ['Map View'],
      // ['Video']
    ];

      pdf.autoTable({
        body: tableData,
        // theme: 'grid',
        startY: 35,
        alternateRowStyles: { 
                fillColor: [208, 216, 223],
              },
              
        didParseCell: function (data) {
          // check if the current cell is the one to add an image to
          if (data.row.index === 4 && data.column.index === 1) {
              // increase the height of the cell
            data.row.height = 25;
          }
          if (data.row.index === 6 && data.column.index === 1) {
            // increase the height of the cell
            data.row.height = 25;
          }
         
          if (data.row.index === 7 && data.column.index === 1) {
            // increase the height of the cell
            data.row.height = 62;
          }
        },
        didDrawCell: (data) => {
            // Add other image-related logic
            if (data.row.index === 4 && data.column.index === 1) {
              const RecSignatureIconLink = (signatureIcons); // Replace with the path to your video icon/image
             const iconSize = 12; // Adjust the size of the icon as needed
           
            const RecSignatureLink = { url: RecSignature }; // Replace 'video' with the actual video link
           
             pdf.link(
              data.cell.x + 5,
              data.cell.y +4,
              iconSize,
              iconSize,
              RecSignatureLink
             )

              pdf.addImage(RecSignatureIconLink, 'PNG', data.cell.x + 5, data.cell.y +4 , iconSize, iconSize);
            
            }
            if (data.row.index === 6 && data.column.index === 1) {
              const guardsSignatureIconLink = (signatureIcons); // Replace with the path to your video icon/image
             const iconSize = 12; // Adjust the size of the icon as needed
           
            const guardsSignatureLink = { url: guardsSignature }; // Replace 'video' with the actual video link
           
             pdf.link(
              data.cell.x + 5,
              data.cell.y +4,
              iconSize,
              iconSize,
              guardsSignatureLink
             )

              pdf.addImage(guardsSignatureIconLink, 'PNG', data.cell.x + 5, data.cell.y +4 , iconSize, iconSize);
            
            }
            if (data.row.index === 7 && data.column.index === 1) {
              // add the image to the cell
              pdf.addImage(canvas, 'PNG', data.cell.x + 2, data.cell.y + 4, 55, 55);
            }
          }
        // }             
                           
      });
         
  
//----------------PALLET ITEMS TABLE------------------

pdf.addPage()
  pdf.text(15, 10  , 'Pallet Returns Items Details');
 
  const tableDataDel = [
    ['Quantity', 'stack','Images',''], 
    ...showPalletItemsTbl.map(details => [details.qty, details.stack]),
  ];
  
  const firstRow = showPalletItemsTbl[0];

  const headerRow = tableDataDel.shift();


  pdf.autoTable({
    head: [headerRow],
    body: [firstRow, ...tableDataDel],
    startY: 15,
    alternateRowStyles: {fillColor: [208, 216, 223]},
    columnStyles: {
      0:{cellWidth:20},
      1:{cellWidth: 35},
      2: {cellWidth: 65}, 
      3: {cellWidth: 65},
     
    },
    didParseCell: function (data) {
      if (data.row.index >= 1 && data.column.index === 2) {
        data.row.height = 14;
      }
    },
    didDrawCell: (data) => {
      if (data.column.index === 2 && data.row.index >= 1) {
     
        const iconSize = 12; // Adjust the size of the icon as needed
        const image = images[data.row.index - 1];
        const imageIconLink = (imageIcon); // Replace with the path to your image icon

        const imageLink = { url: image };
        pdf.link(data.cell.x, data.cell.y + 2, iconSize, iconSize, imageLink);
        pdf.addImage(imageIconLink, data.cell.x, data.cell.y + 2, iconSize, iconSize);
             
    }
    if (data.column.index === 3 && data.row.index >= 1) {
    const iconSize = 12; // Adjust the size of the icon as needed
        const image = images2[data.row.index - 1];
        const imageIconLink = (imageIcon); // Replace with the path to your image icon

        const imageLink = { url: image };
        pdf.link(data.cell.x, data.cell.y + 2, iconSize, iconSize, imageLink);
        pdf.addImage(imageIconLink, data.cell.x, data.cell.y + 2, iconSize, iconSize);
    }
    },
       // Set margin to 20mm on all sides
    margin: {  bottom: 20 },
    }); 
    
    pdf.save('Full Report.pdf');
  }    

    return(
      <div>
        <button className="exportPDFBtn" onClick={combinedTblPdfExport}>Export to PDF</button>
          {pdfOpen && (
            <Document
              file={file}
              onLoadSuccess={() => setPdfOpen(false)}
              >
            <Page pageNumber={1} />
            </Document>
            )}
      </div>
    )
  }

  export default ExportToPDF;