import { atom, useResetRecoilState } from 'recoil';
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

export const auditSiteAtom = atom({
  key: 'auditSiteAtom',
  dangerouslyAllowMutability: true,
  default: {
    site: ""
  },
  effects_UNSTABLE: [persistAtom],
  // Add the following line to your code to clear the default state:
  initializeState: ({ set }) => {
    set(auditSiteAtom, []);
  },
});
