import React from 'react';
import './AdminDashboard.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import CreateDashUsers from '../../components/Create/CreateDashUsers/CreateDashUsers';
import ViewUsers from '../../components/View/ViewUsers/ViewUsers';
import CreateMobileUsers from '../../components/Create/CreateMobileUsers/CreateMobileUsers'
import ViewMobileUsers from '../../components/View/ViewMobileUsers/ViewMobileUsers';
import CreateDispatchSites from '../../components/Create/CreateDispatchSite/CreateDispatchSite';
import ViewDispatchSite from '../../components/View/ViewDispatchSite/ViewDispatchSite';
import CreateDeliveriesSite from '../../components/Create/CreateDeliveriesSite/CreateDeliveriesSite';
import ViewDeliveriesSite from '../../components/View/ViewDeliveriesSite/ViewDeliveriesSite';

const AdminDashboard  = () => {
    
return (
    
  <div className='adminContainer'> 

    <Tabs>
      <div className='trans'></div>
      <TabList className='tabsTitle'>
        <Tab>Dashboard Users</Tab>
        <Tab>Mobile Users</Tab>
        <Tab>Dispatch Sites</Tab>
        <Tab>Deliveries Sites</Tab>
      </TabList>

      <TabPanel >
        <div className='createColumn'>
          <CreateDashUsers/>
        </div>
        <div className='viewColumn'>
          <ViewUsers/>
        </div>       
      </TabPanel>

      <TabPanel>
        <div className='createColumn'>
          <CreateMobileUsers/>
        </div>       
        <div className='viewColumn'>
          <ViewMobileUsers/>
        </div>       
      </TabPanel>

      <TabPanel>
        <div className='createColumn'>
          <CreateDispatchSites/>
        </div>
        <div className='viewColumn'>
          <ViewDispatchSite/>
        </div>
      </TabPanel>

      <TabPanel>
        <div className='createColumn'>
          <CreateDeliveriesSite/>
        </div> 
        <div className='viewColumn'>
          <ViewDeliveriesSite/>
        </div>
      </TabPanel>

    </Tabs>
  
  </div>         
 
)
       
    
}
export default AdminDashboard ;