import React, { useEffect, useState } from 'react';
import {  useNavigate,Link } from 'react-router-dom';
import './Navbar.css';
import logo from '../../media/WorxItLogo.png';
import logoutBtn from '../../media/logoutBtn.png';
import Badge from '@mui/material/Badge';
import axios from 'axios'
import { AtomDispatchState } from "../RecoilStates/AtomDispatchState";
import { AtomDeliveriesState } from "../RecoilStates/AtomDeliveriesState";
import { useRecoilValue, useSetRecoilState, useResetRecoilState } from "recoil";

import { auditHeaderDataAtom } from '../../components/RecoilStates/auditHeaderDataAtom';
import { auditLogsAtom } from '../../components/RecoilStates/auditlogsAtom';
import { lpaAdminQuestionsState } from '../../components/RecoilStates/LpaAdminQuestionsState';
import { lpaBackupQuestionsState } from '../../components/RecoilStates/LpaBackupQuestionsState';
import { lpaFrontlineQuestionsState } from '../../components/RecoilStates/LpaFrontlineQuestionsState';
import { lpaSecurityQuestionsState } from '../../components/RecoilStates/LpaSecurityQuestionsState';
import { lpaReceivingQuestionsState } from '../../components/RecoilStates/LpaReceivingQuestionsState';
import { lpaSalesFloorQuestionsState } from '../../components/RecoilStates/LpaSalesFloorQuestionsState';
import { lpaCashOfficeQuestionsState } from '../../components/RecoilStates/LpaCashOfficeQuestionsState';


export default function Navbar ()  {

const [auth, setAuth]= useState();
const navigate = useNavigate();
const setOrdersProgDataState = useSetRecoilState(AtomDispatchState)
const setDailyStatusState = useSetRecoilState(AtomDeliveriesState)
const ordersProgData = useRecoilValue(AtomDispatchState)
const DailyStatusData = useRecoilValue(AtomDeliveriesState)


const resetAuditHeaderDataAtom = useResetRecoilState(auditHeaderDataAtom);
const resetAuditLogsAtom = useResetRecoilState(auditLogsAtom);
const resetLpaAdminQuestionsState = useResetRecoilState(lpaAdminQuestionsState);
const resetLpaBackupQuestions = useResetRecoilState(lpaBackupQuestionsState);
const resetLpaFrontlineQuestions = useResetRecoilState(lpaFrontlineQuestionsState);
const resetLpaSecurityQuestions = useResetRecoilState(lpaSecurityQuestionsState);
const resetLpaReceivingQuestions = useResetRecoilState(lpaReceivingQuestionsState);
const resetLpaSalesFloorQuestions = useResetRecoilState(lpaSalesFloorQuestionsState);
const resetLpaCashOfficeQuestions = useResetRecoilState(lpaCashOfficeQuestionsState);
   
      useEffect(()=>{
        dispatchTable();
        deliveriesTable();

         const intervalId = setInterval(dispatchTable, 5000);
         const intervalId2 = setInterval(deliveriesTable, 5000);
         return () => clearInterval(intervalId, intervalId2);
  
      },[])

      const dispatchTable = async () => {
        const response = await axios.get('https://worxit.co.za/api/zamatrack/dispatchView.php');
          const newData = response.data.records;
          console.log("Dispatch Atom:",  ordersProgData)
          setOrdersProgDataState(newData);
          console.log("Dispatch New Data:", newData)
      }

      const deliveriesTable = async () => {
        const response = await axios.get('https://worxit.co.za/api/zamatrack/deliveriesView.php');
          const newData = response.data.records;
          console.log("Diliveries Atom:",  DailyStatusData)
          setDailyStatusState(newData);
          console.log("Deliveries New Data:", newData)
      }


    useEffect(()=>{
    var auth = localStorage.getItem('username');
    setAuth(auth)
    },
    [])

    const LogOut= () =>{
      localStorage.removeItem('username');
      localStorage.clear();
      resetAuditHeaderDataAtom();
        resetAuditLogsAtom();
        resetLpaAdminQuestionsState();
        resetLpaBackupQuestions();
        resetLpaCashOfficeQuestions();
        resetLpaFrontlineQuestions();
        resetLpaReceivingQuestions();
        resetLpaSalesFloorQuestions();
        resetLpaSecurityQuestions();
      navigate('/');
      window.location.reload();
    }

    const getRangeForDate = (date) => {
      const currentDate = new Date();
      const timeDifference = currentDate.getTime() - date.getTime();
      // if (timeDifference <= 24 * 60 * 60 * 1000) {
      //   return "inProgress";
      // } else 
      if (timeDifference <= 24 * 60 * 60 * 1000) {
        return "low"
      } else if (timeDifference > 24 * 60 * 60 * 1000 && timeDifference <= 48 * 60 * 60 * 1000) {
        return "medium";
      } else {
        return "high";
      }
    };
    
     
    const lowCount = () => {
      const withinTimeRangeDates = ordersProgData && Array.isArray(ordersProgData)
        ? ordersProgData.filter(item => {
          const itemDate = new Date(item.dateTime);
          const range = getRangeForDate(itemDate);
          return range === "low";
        })
        : [];
  
      const countOfDates = withinTimeRangeDates.length;
      return countOfDates;
    };
  
    const mediumCount = () => {
      const withinTimeRangeDates = ordersProgData && Array.isArray(ordersProgData)
        ? ordersProgData.filter(item => {
          const itemDate = new Date(item.dateTime);
          const range = getRangeForDate(itemDate);
          return range === "medium";
        })
        : [];
  
      const countOfDates = withinTimeRangeDates.length;
      return countOfDates;
    };
  
    const highCount = () => {
      const withinTimeRangeDates = ordersProgData && Array.isArray(ordersProgData)
        ? ordersProgData.filter(item => {
          const itemDate = new Date(item.dateTime);
          const range = getRangeForDate(itemDate);
          return range === "high";
        })
        : [];
  
      const countOfDates = withinTimeRangeDates.length;
      return countOfDates;
    };
  
    const inProgressCount = () => {
      const withinTimeRangeDates = ordersProgData && Array.isArray(ordersProgData)
        ? ordersProgData.filter(item => {
          const itemDate = new Date(item.dateTime);
          const range = getRangeForDate(itemDate);
          return range === "low";
        })
        : [];
  
      const countOfDates = withinTimeRangeDates.length;
      return countOfDates;
    };
  
    const completedCount = () => {
      const withinTimeRangeDates = DailyStatusData && Array.isArray(DailyStatusData)
        ? DailyStatusData.filter(item => {
          const itemDate = new Date(item.dateTimeRec);
          const range = getRangeForDate(itemDate);
          return range === "low";
        })
        : [];
  
      const countOfDates = withinTimeRangeDates.length;
      return countOfDates;
    };
    

return (
    
    <div className="title-bar ">
      <div className="title-bar-left">    
        <div>  
          <span>
            <img className='logoSize' src={logo} alt="Logo" />
          </span>
        </div>
      </div>
      {/* <div className="title-bar-middle">
        <div className='fstColumn'>
          <div className='titleCol'>
            <Link className='titleColLink' to={'/nonCompliance'}><h5>Orders Progress</h5></Link>
          </div>
          <div className='subCol'>
            <text style={{paddingBottom: "5px"}}>Low</text>
            <text style={{paddingBottom: "5px"}}>Medium</text>
            <text style={{paddingBottom: "5px"}}>High </text>
          </div>
          <div className='badgeCol'>
            <div style={{paddingBottom: "5px"}}>
              <Badge badgeContent={lowCount(24) || "0"} slotProps={{ badge: { className: 'my-badge' } }}></Badge>
            </div>
            <div style={{paddingBottom: "5px"}}>
              <Badge badgeContent={mediumCount(48) || "0"} color="primary"></Badge>
            </div>
            <div style={{paddingBottom: "5px"}}>
              <Badge badgeContent={highCount(72) || "0"} color="secondary"></Badge>
            </div>
          </div>
        </div>
        <div className='sndColumn'>
          <div className='titleCol'>
            <h5>Daily Order Status</h5>
          </div>
          <div className='subCol'>
            <text style={{paddingBottom: "5px"}}>Completed</text>
            <text style={{paddingBottom: "5px"}}>In Progress</text>
            <text style={{paddingBottom: "5px"}}>Not Started </text>
          </div>
          <div className='badgeCol'>
            <div style={{paddingBottom: "5px"}}>
              <Badge badgeContent={completedCount() || "0"} color="success"></Badge>
            </div>
            <div style={{paddingBottom: "5px"}}>
              <Badge badgeContent={inProgressCount() || "0"} color="primary"></Badge>
            </div>
            <div style={{paddingBottom: "5px"}}>
              <Badge badgeContent={"0"} color="secondary"></Badge>
            </div>
          </div>
        </div>
      </div>   */}
      <div className="title-bar-right"> 
        <span className="title-bar-title">
          Welcome: {auth} 
          <img className="logoutBtn" src={logoutBtn} alt="Logo" onClick={LogOut}/> 
        </span>
      </div>
    </div>
  );
}

// export default Navbar;
