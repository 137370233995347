import React, { useEffect, useRef,useState } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import LpaAdminFeedback from '../../components/LPAStoreFeedback/LPAStoreFeedbackAdmin/LpaAdminFeedback';
import LpaBackupsFeedback from '../../components/LPAStoreFeedback/LPAStoreFeedbackBackups/LpaBackupsFeedback';
import LpaFrontLineFeedback from '../../components/LPAStoreFeedback/LPAStoreAnalysisFrontline/LpaFrontLineFeedback';
import LpaSecurityFeedback from '../../components/LPAStoreFeedback/LPAStoreFeedbackSecurity/LpaSecurityFeedback';
import LpaReceivingFeedback from '../../components/LPAStoreFeedback/LPAStoreFeedbackReceiving/LpaReceivingFeedback';
import LpaSalesFloorFeedback from '../../components/LPAStoreFeedback/LPAStoreFeedbackSalesFloor/LpaSalesFloorFeedback';
import LpaCashOfficeFeedback from '../../components/LPAStoreFeedback/LPAStoreFeedbackCashOffice/LpaCashOfficeFeedback';
import LpaFindings from '../LPAStoreFeedback/LPAStoreAnalysisFindings/LPAStoreAnalysisFindings';
import { auditLogsAtom } from '../RecoilStates/auditlogsAtom';
import { auditHeaderDataAtom } from '../../components/RecoilStates/auditHeaderDataAtom';
import { lpaAdminDetailsState } from '../../components/RecoilStates/LpaAdminDetailsState';
import { lpaBackupsDetailsState } from '../../components/RecoilStates/LpaBackupsDetailsState';
import { lpaFrontlineDetailsState } from '../../components/RecoilStates/LpaFrontlineDetailsState';
import { lpaSecurityDetailsState } from '../../components/RecoilStates/LpaSecurityDetailsState';
import { lpaReceivingDetailsState } from '../../components/RecoilStates/LpaReceivingDetailsState';
import { lpaSalesFloorDetailsState } from '../../components/RecoilStates/LpaSalesFloorDetailsState';
import { lpaCashOfficeDetailsState } from '../../components/RecoilStates/LpaCashOfficeDetailsState';
import ExportToPdf from '../../components/LPAStoreFeedback/exportToPdf.js'
import './feedbackPage.css';
import locationMarker from '../../media/locationMarker.png';
import { Chart } from "react-google-charts";
import { MapContainer, TileLayer, Marker,Popup } from 'react-leaflet';
import L from 'leaflet';


const FeedbackPage = () => {
  const chartRef = useRef(null);
  const [markerPopup, setMarkerPopup] = useState(false);
  const mapRef = useRef(null);
  

  const { auditCode } = useParams();
  const auditLogsData = useRecoilValue(auditLogsAtom)
  const auditHeaderData = useRecoilValue(auditHeaderDataAtom);
  const receivingScore = useRecoilValue(lpaReceivingDetailsState);
  const salesFloorScore = useRecoilValue(lpaSalesFloorDetailsState);
  const fronlineScore = useRecoilValue(lpaFrontlineDetailsState);
  const securityScore = useRecoilValue(lpaSecurityDetailsState);
  const backupsScore = useRecoilValue(lpaBackupsDetailsState);
  const adminScore = useRecoilValue(lpaAdminDetailsState);
  const cashOfficeScore = useRecoilValue(lpaCashOfficeDetailsState);


  const recieivingScoreArray = Array.isArray(receivingScore) ? receivingScore : [];
  const filteredReceivingDetails = recieivingScoreArray.filter(details => details.auditCode === auditCode);  

  const salesFloorScoreArray = Array.isArray(salesFloorScore) ? salesFloorScore : [];
  const filteredSalesFloorDetails = salesFloorScoreArray.filter(details => details.auditCode === auditCode);  

  const frontlineScoreArray = Array.isArray(fronlineScore) ? fronlineScore : [];
  const filteredFrontlineDetails = frontlineScoreArray.filter(details => details.auditCode === auditCode);  

  const securityScoreArray = Array.isArray(securityScore) ? securityScore : [];
  const filteredSecurityDetails = securityScoreArray.filter(details => details.auditCode === auditCode);  

  const backupsScoreArray = Array.isArray(backupsScore) ? backupsScore : [];
  const filteredBackupsDetails = backupsScoreArray.filter(details => details.auditCode === auditCode);  

  const adminScoreArray = Array.isArray(adminScore) ? adminScore : [];
  const filteredAdminDetails = adminScoreArray.filter(details => details.auditCode === auditCode);  
   
  const cashOfficeScoreArray = Array.isArray(cashOfficeScore) ? cashOfficeScore : [];
  const filteredCashOfficeDetails = cashOfficeScoreArray.filter(details => details.auditCode === auditCode);  
   
  const filterLatAndLong = auditLogsData.filter(details => details.auditCode === auditCode);

  console.log('filterLatAndLong--------', filterLatAndLong[0].latitude)
  console.log('filterLatAndLong--------', filterLatAndLong[0].longitude)


  const cashOfficeTotalScore = parseInt(filteredCashOfficeDetails[0].totalScore);
  //const adminTotalScore = parseInt(filteredAdminDetails[0].totalScore);
  const backupsTotalScore = parseInt(filteredBackupsDetails[0].totalScore);
  const securityTotalScore = parseInt(filteredSecurityDetails[0].totalScore);
  const frontlineTotalScore = parseInt(filteredFrontlineDetails[0].totalScore);
  //const salesFloorTotalScore = parseInt(filteredSalesFloorDetails[0].totalScore);
 // const receivingTotalScore = parseInt(filteredReceivingDetails[0].totalScore);

 useEffect(()=>{
   //console.log("adminTotalScore: ", adminTotalScore)
 })

  let cashOfficeColor;
  let adminColor;
  let backupsColor;
  let securityColor;
  let frontlineColor;
  let salesFloorColor;
  let receivingColor;
  
  if (cashOfficeTotalScore <= 50) {
    cashOfficeColor = "#D18678"; // Red
  } else if (cashOfficeTotalScore > 50 && cashOfficeTotalScore <= 70) {
    cashOfficeColor = "#78ABD1"; // BluefilteredCashOfficeDetails[0].totalScore
  } else {
    cashOfficeColor = "#5CB268"; // Green
  }

  // if (adminTotalScore <= 50) {
  //   adminColor = "#D18678"; // Red
  // } else if (adminTotalScore > 50 && adminTotalScore <= 70) {
  //   adminColor = "#78ABD1"; // Blue
  // } else {
  //   adminColor = "#5CB268"; // Green
  // }

  if (backupsTotalScore <= 50) {
    backupsColor = "#D18678"; // Red
  } else if (backupsTotalScore > 50 && backupsTotalScore <= 70) {
    backupsColor = "#78ABD1"; // Blue
  } else {
    backupsColor = "#5CB268"; // Green
  }

  if (securityTotalScore <= 50) {
    securityColor = "#D18678"; // Red
  } else if (securityTotalScore > 50 && securityTotalScore <= 70) {
    securityColor = "#78ABD1"; // Blue
  } else {
    securityColor = "#5CB268"; // Green
  }

  if (frontlineTotalScore <= 50) {
    frontlineColor = "#D18678"; // Red
  } else if (frontlineTotalScore > 50 && frontlineTotalScore <= 70) {
    frontlineColor = "#78ABD1"; // Blue
  } else {
    frontlineColor = "#5CB268"; // Green
  }

  // if (salesFloorTotalScore <= 50) {
  //   salesFloorColor = "#D18678"; // Red
  // } else if (salesFloorTotalScore > 50 && salesFloorTotalScore <= 70) {
  //   salesFloorColor = "#78ABD1"; // Blue
  // } else {
  //   salesFloorColor = "#5CB268"; // Green
  // }

  // if (receivingTotalScore <= 50) {
  //   receivingColor = "#D18678"; // Red
  // } else if (receivingTotalScore >=50 && receivingTotalScore <= 70) {
  //   receivingColor = "#78ABD1"; // Blue
  // } else {
  //   receivingColor = "#5CB268"; // Green
  // }


  const data = [
    [
      "Element",
      "Total Percentage",
      { role: "style" },
      {
        sourceColumn: 0,
        role: "annotation",
        type: "string",
        calc: "stringify",
        
      },
    ],
    ["Section A", backupsTotalScore, backupsColor, `${backupsTotalScore}%`],
    ["Section B", frontlineTotalScore, frontlineColor, `${frontlineTotalScore}%`],
    ["Section C", securityTotalScore, securityColor, `${securityTotalScore}%`],
    ["Section D", cashOfficeTotalScore, cashOfficeColor, `${cashOfficeTotalScore}%`],
   // ["Admin", adminTotalScore, adminColor, `${adminTotalScore}%`],
    //["Sales Floor", salesFloorTotalScore, salesFloorColor, `${salesFloorTotalScore}%`],
    //["Receiving", receivingTotalScore, receivingColor, `${receivingTotalScore}%`],
  ];

  
  const options = {
    title: "Shrinkage Barometer",
    width: 600,
    height: 400,
    bar: { groupWidth: "95%" },
    legend: { position: "none" },
    vAxis: {
      format: "#%", // Format values as percentages
      textStyle: {
        color: "black", // Change the color of the labels here
        textAlign: "start", // Align labels to the left
      }, // Align labels to the left
    },
    hAxis: {
      textStyle: {
        color: "black", // Change the color of the labels here
        textAlign: "start", // Align labels to the left
      }, // Align labels to the left
      ticks: [], // Remove x-axis ticks
    },
  };
  
  const customIcon = L.icon({
    iconUrl: locationMarker,
    iconSize: [14, 18], // Adjust the size as needed

    });

   
  return (
    <div className="feedbackContainer">
      <div>
        <ExportToPdf chartRef={chartRef}/>
      </div>
    <div id="mapAndChartContainer" className='mapAndGraph'>
      <div id="mapContainer" className='mapContainer'>
        <MapContainer
          center={[filterLatAndLong[0].latitude, filterLatAndLong[0].longitude]}
          zoom={18}
          style={{ height: "400px", width: "100%" }}
          className="map"
          ref={mapRef}
        >
          <TileLayer
            attribution="Map data &copy; OpenStreetMap contributors"
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />

          <Marker position={[filterLatAndLong[0].latitude, filterLatAndLong[0].longitude]} icon={customIcon}>
            <Popup onClose={() => setMarkerPopup(false)}>
              <div>
                Latitude: {[filterLatAndLong[0].latitude]}<br />
                Longitude: {[filterLatAndLong[0].longitude]}<br />
              </div>
            </Popup>
          </Marker>
        </MapContainer>  
      </div>
      <div id="chartContainer" className='chartContainer'>
        <Chart ref={chartRef} chartType="BarChart" width="80%" height="400px" data={data} options={options} />
      </div>
    </div>
      
      <LpaFindings/>
      {/* <LpaAdminFeedback /> */}
      <LpaBackupsFeedback />
      <LpaFrontLineFeedback />
       <LpaSecurityFeedback />
      {/*<LpaReceivingFeedback />
      <LpaSalesFloorFeedback /> */}
      <LpaCashOfficeFeedback />

      <div className='tableFeedback'>
        <div className='firstColumn'>
          <div className='userRow'>
            {auditHeaderData[0]?.user}
          </div>
          <div className='dateRow'>
            Date: {auditLogsData[0]?.completionDate}
          </div>
         
        </div>
        <div className='secondColumn'>
          <div className='headerRow'>
            <label className='headerTile'>Total LPA Score</label>
          </div>
          {/* <div className='firstRow'>
            {filteredReceivingDetails.length > 0 && (
                <label className='rowData'>Receiving = {filteredReceivingDetails[0].totalScore}%</label>
            )}
          </div> */}
          {/* <div className='seondRow'>
            {filteredSalesFloorDetails.length > 0 && (
                  <label className='rowData'>Sale Floor = {filteredSalesFloorDetails[0].totalScore}%</label>
              )}
          </div> */}
          <div className='firstRow'>
            {filteredBackupsDetails.length > 0 && (
                  <label className='rowData'>Section A = {filteredBackupsDetails[0].totalScore}%</label>
              )}
          </div>
          <div className='seondRow'>
            {filteredFrontlineDetails.length > 0 && (
                  <label className='rowData'>Section B = {filteredFrontlineDetails[0].totalScore}%</label>
              )}
          </div>
          <div className='thirdRow'>
            {filteredSecurityDetails.length > 0 && (
                  <label className='rowData'>Section C = {filteredSecurityDetails[0].totalScore}%</label>
              )}
          </div>
          
          {/* <div className='sixthRow'>
        {filteredAdminDetails.length > 0 && (
          <label className='rowData'>Admin = {filteredAdminDetails[0].totalScore}%</label>
        )}
      </div> */}
          <div className='forthRow'>
            {filteredCashOfficeDetails.length > 0 && (
                    <label className='rowData'>Section D = {filteredCashOfficeDetails[0].totalScore}%</label>
                )}
          </div>
          
        </div>
      </div>
      
    </div>
  );
};

export default FeedbackPage;
