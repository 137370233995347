import React, {useEffect, useState} from "react";
import axios from "axios";
import {  useParams,useNavigate } from "react-router-dom";
import { Document, Page } from "react-pdf";
import jsPDF from 'jspdf'
import 'jspdf-autotable';
import './exportToPdf.css'
import logo from '../../../../media/WorxItLogo.png';
import signatureIcons from '../../../../media/signatureIcons.png';
import imageIcon from '../../../../media/imageIcon.png';

function ExportToPDF () {

  const { orderNo } = useParams();  
  const [showClaimsTbl, setShowClaimsTbl] = useState([]);
  const [showClaimsItemsTbl, setShowClaimsItemsTbl] = useState([]);
  const [pdfOpen, setPdfOpen] = useState(false);
  const [file, setFile] = useState(null);

  useEffect(() => {
    claimsTbl();
    claimsItemsTbl();
         
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, []);

    const claimsTbl = async () => {
      console.log(orderNo);
      const result = await axios.get(
        "https://worxit.co.za/api/zamatrack/showClaimsDetails.php?orderNo=" + 
        orderNo);
      setShowClaimsTbl(result.data.records);
    };

    const claimsItemsTbl = async () => {
      console.log(orderNo);
      const result = await axios.get(
        "https://worxit.co.za/api/zamatrack/claimsItemsTable.php?orderNo=" + 
        orderNo);
      setShowClaimsItemsTbl(result.data.records);
    };

     //------------------COMBINED TABLES-----------------

    //----------------CLAIMS TABLE------------------
  const combinedTblPdfExport = async () => {
    const pdf = new jsPDF();

    //------Image------
  pdf.addImage(logo, "PNG", 10, 3, 20, 20);

  //-----Report Date------
  const reportDate = new Date().toLocaleDateString();
  pdf.setFontSize(10);
  pdf.text(`Report Date: ${reportDate}`, 155, 10);

  //------Title------
  pdf.setFontSize(12);
  pdf.setFont('helvetica', 'bold');
  pdf.text(80, 20, 'Proof of Claims Report');

  pdf.setFont('helvetica', 'normal');
  pdf.text(15, 30, 'Claims Details');

   
  //-------Data-----
  const dataSup = 
  showClaimsTbl.map((details) => [
    details.orderNo,
    details.dateTime,
    details.siteNo,
    details.receiversName, 
    details.guardsName,
  ])

    const recSignature = showClaimsTbl.map((details) => details.signRec)[0];
    const guardsSignature = showClaimsTbl.map((details) => details.signGuard)[0]
    const images = showClaimsItemsTbl.map((details) => details.images);

    //-------Headers------

    const tableDataSup = [['Order No', ...dataSup.map((row) => row[0])], 
    ['Date Time', ...dataSup.map((row) => row[1])],
    ['Site No', ...dataSup.map((row) => row[2])],
    ['Receivers Name', ...dataSup.map((row) => row[3])],
    ['Signature'],
    ['Guards Name', ...dataSup.map((row) => row[4])],
    ['Signature'],
  ];

    pdf.autoTable({
      body: tableDataSup,
      // theme: 'grid',
      startY: 35,
      alternateRowStyles: { 
              fillColor: [208, 216, 223],
            },
            
      didParseCell: function (data) {
        // check if the current cell is the one to add an image to
        if (data.row.index === 4 && data.column.index === 1) {
            // increase the height of the cell
          data.row.height = 25;
        }
        if (data.row.index === 6 && data.column.index === 1) {
          // increase the height of the cell
          data.row.height = 25;
        }
      },
      didDrawCell: (data) => {
        // check if the current cell is the one to add an image to
        if (data.row.index === 4 && data.column.index === 1) {
          const recSignatureIconLink = (signatureIcons); // Replace with the path to your video icon/image
          const iconSize = 12; // Adjust the size of the icon as needed
        
         const recSignatureLink = { url: recSignature }; // Replace 'video' with the actual video link
        
          pdf.link(
           data.cell.x + 5,
           data.cell.y +4,
           iconSize,
           iconSize,
           recSignatureLink
          )

           pdf.addImage(recSignatureIconLink, 'PNG', data.cell.x + 5, data.cell.y +4 , iconSize, iconSize);
         
        }
        if (data.row.index === 6 && data.column.index === 1) {
          const guardsSignatureIconLink = (signatureIcons); // Replace with the path to your video icon/image
          const iconSize = 12; // Adjust the size of the icon as needed
        
         const guardsSignatureLink = { url: guardsSignature }; // Replace 'video' with the actual video link
        
          pdf.link(
           data.cell.x + 5,
           data.cell.y +4,
           iconSize,
           iconSize,
           guardsSignatureLink
          )

           pdf.addImage(guardsSignatureIconLink, 'PNG', data.cell.x + 5, data.cell.y +4 , iconSize, iconSize);
         
        }
      },
     
          
      });

    //--------------------CLAIMS ITEMS TABLE-------------------------------

    pdf.addPage()
    pdf.text(15, 10, 'Claims Item Details');
   
    const tableData = [
      ['Quantity', 'Barcode', 'Description', 'Reason' ,'Images'], 
      ...showClaimsItemsTbl.map(details => [details.qtyNo, details.barcode, details.description || "No Desciption Found",details.reason ]),
    ];
    
    const firstRow = showClaimsItemsTbl[0];
  
    const headerRow = tableData.shift();
  
  
    pdf.autoTable({
      head: [headerRow],
      body: [firstRow, ...tableData],
      // theme: 'grid',
      startY: 15,
      alternateRowStyles: {fillColor: [208, 216, 223]},
      columnStyles: {
        0:{cellWidth:20},
        1:{cellWidth: 30},
        2: {cellWidth: 35},
        3: {cellWidth: 45},
        4: {cellWidth:50}
      },
      didParseCell: function (data) {
        if (data.row.index >= 1 && data.column.index ===4) {
          data.row.height = 14;
        }
      },
      didDrawCell: (data) => {
        if (data.column.index === 4 && data.row.index >= 1) {
          const iconSize = 12; // Adjust the size of the icon as needed
          const image = images[data.row.index - 1];
          const imageIconLink = (imageIcon); // Replace with the path to your image icon

          const imageLink = { url: image };
          pdf.link(data.cell.x, data.cell.y + 2, iconSize, iconSize, imageLink);
          pdf.addImage(imageIconLink, data.cell.x, data.cell.y + 2, iconSize, iconSize);
        
        }
      },
    });

    pdf.save('Full Report.pdf');
      
  }
  

     
    
  

    return(
      <div>
        <button className="exportPDFBtn" onClick={combinedTblPdfExport}>Export to PDF</button>
          {pdfOpen && (
            <Document
              file={file}
              onLoadSuccess={() => setPdfOpen(false)}
              >
              <Page pageNumber={1} />
            </Document>
          )}      
      </div>
    )
  }


export default ExportToPDF;