import React, {useEffect, useState} from "react";
import axios from "axios";
import {  useParams } from "react-router-dom";
import { Document, Page } from "react-pdf";
import jsPDF from 'jspdf'
import 'jspdf-autotable';
import './exportToPdf.css'
import logo from '../../../../media/WorxItLogo.png';
import signatureIcons from '../../../../media/signatureIcons.png';
import imageIcon from '../../../../media/imageIcon.png';

function ExportToPDF () {

  const { docNo } = useParams();  
  const [showSuppliersTbl, setShowSuppliersTbl] = useState([]);
  const [showSuppliersItemsTbl, setShowSuppliersItemsTbl] = useState([]);
  const [pdfOpen, setPdfOpen] = useState(false);
  const [file, setFile] = useState(null);

  useEffect(() => {
    suppliersTbl();
    suppliersItemsTbl();
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, []);

    const suppliersTbl = async () => {
      console.log(docNo);
      const result = await axios.get(
        "https://worxit.co.za/api/zamatrack/showSuppliersDetails.php?docNo=" + 
        docNo);
      setShowSuppliersTbl(result.data.records);
    };

    const suppliersItemsTbl = async () => {
      console.log(docNo);
      const result = await axios.get(
        "https://worxit.co.za/api/zamatrack/suppliersItemsTable.php?docNo=" + 
        docNo);
      setShowSuppliersItemsTbl(result.data.records);
    };

    //------------------COMBINED TABLES-----------------

    //----------------DELIVERIES TABLE------------------
  const combinedTblPdfExport = async () => {
    const pdf = new jsPDF();

    //------Image------
  pdf.addImage(logo, "PNG", 10, 3, 20, 20);

  //-----Report Date------
  const reportDate = new Date().toLocaleDateString();
  pdf.setFontSize(10);
  pdf.text(`Report Date: ${reportDate}`, 155, 10);

  //------Title------
  pdf.setFontSize(12);
  pdf.setFont('helvetica', 'bold');
  pdf.text(80, 20, 'Proof of Suppliers Report');

  pdf.setFont('helvetica', 'normal');
  pdf.text(15, 30, 'Suppliers Details');

   
  //-------Data-----
  const dataSup = 
  showSuppliersTbl.map((details) => [
    details.docNo,
    details.dateTime,
    details.supplierName,
    details.transporter,
    details.driverName,
    details.vehicleReg, 
    details.vehicleMake, 
    details.vehicleColour,
    details.receiversName, 
    details.guardsName
  ])

    const recSignature = showSuppliersTbl.map((details) => details.signRec)[0];
    const guardsSignature = showSuppliersTbl.map((details) => details.signGuard)[0]
    const images = showSuppliersItemsTbl.map((details) => details.images);
    //-------Headers------

    const tableDataSup = [['Doc No', ...dataSup.map((row) => row[0])], 
    ['Date Time', ...dataSup.map((row) => row[1])],
    ['Supplier Name', ...dataSup.map((row) => row[2])],
    ['Transporter', ...dataSup.map((row) => row[3])],
    ['Drivers Name', ...dataSup.map((row) => row[4])],
    ['Vehicle Reg', ...dataSup.map((row) => row[5])],
    ['Vehicle Make', ...dataSup.map((row) => row[6])],
    ['Vehicle Colour', ...dataSup.map((row) => row[7])],
    ['Receivers Name', ...dataSup.map((row) => row[8])],
    ['Signature'],
    ['Guards Name', ...dataSup.map((row) => row[9])],
    ['Signature']
  ];

    pdf.autoTable({
      body: tableDataSup,
      //theme: 'grid',
      startY: 35,
      alternateRowStyles: { 
              fillColor: [208, 216, 223],
            },
            
      didParseCell: function (data) {
        // check if the current cell is the one to add an image to
        if (data.row.index === 9 && data.column.index === 1) {
            // increase the height of the cell
          data.row.height = 25;
        }
        if (data.row.index === 11 && data.column.index === 1) {
          // increase the height of the cell
          data.row.height = 25;
        }
      },
      didDrawCell: (data) => {
        // check if the current cell is the one to add an image to
        if (data.row.index === 9 && data.column.index === 1) {
          const recSignatureIconLink = (signatureIcons); // Replace with the path to your video icon/image
          const iconSize = 12; // Adjust the size of the icon as needed
        
         const recSignatureLink = { url: recSignature }; // Replace 'video' with the actual video link
        
          pdf.link(
           data.cell.x + 5,
           data.cell.y +4,
           iconSize,
           iconSize,
           recSignatureLink
          )

           pdf.addImage(recSignatureIconLink, 'PNG', data.cell.x + 5, data.cell.y +4 , iconSize, iconSize);
         
        }
        if (data.row.index === 11 && data.column.index === 1) {
          const guardsSignatureIconLink = (signatureIcons); // Replace with the path to your video icon/image
          const iconSize = 12; // Adjust the size of the icon as needed
        
           const guardsSignatureLink = { url: guardsSignature }; // Replace 'video' with the actual video link
        
          pdf.link(
           data.cell.x + 5,
           data.cell.y +4,
           iconSize,
           iconSize,
           guardsSignatureLink
          )

           pdf.addImage(guardsSignatureIconLink, 'PNG', data.cell.x + 5, data.cell.y +4 , iconSize, iconSize);
         
        }
      },
     
          
      });

    //--------------------DELIVERIES ITEMS TABLE-------------------------------

    pdf.addPage();
    pdf.text(15,  10, 'Suppliers Item Details');
    const tableDataDel = [
      ['Quantity', 'Barcode', 'Description', 'Images'], 
      ...showSuppliersItemsTbl.map(details => [details.qtyNo, details.barcode, details.description || "No Desciption Found" ]),
    ];
    
    const firstRow = showSuppliersItemsTbl[0];
  
    const headerRow = tableDataDel.shift();
  
  
    pdf.autoTable({
      head: [headerRow],
      body: [firstRow, ...tableDataDel],
      startY:  15,
      alternateRowStyles: {fillColor: [208, 216, 223]},
      didParseCell: function (data) {
        if (data.row.index >= 1 && data.column.index === 3) {
          data.row.height = 14;
        }
      },
      didDrawCell: (data) => {
        if (data.column.index === 3 && data.row.index >= 1) {
          const iconSize = 12; // Adjust the size of the icon as needed
          const image = images[data.row.index - 1];
          const imageIconLink = (imageIcon); // Replace with the path to your image icon

          const imageLink = { url: image };
          pdf.link(data.cell.x, data.cell.y + 2, iconSize, iconSize, imageLink);
          pdf.addImage(imageIconLink, data.cell.x, data.cell.y + 2, iconSize, iconSize);
        }
      },
      margin: { bottom: 20}
    });
    

    pdf.save('Full Report.pdf');
      
    };
     
    return(
      <div>
        <button className="exportPDFBtn" onClick={combinedTblPdfExport}>Export to PDF</button>
              {pdfOpen && (
                <Document
                    file={file}
                    onLoadSuccess={() => setPdfOpen(false)}
                      >
                    <Page pageNumber={1} />
                </Document>
              )}
                 
      </div>
    )
  }


export default ExportToPDF;