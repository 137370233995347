import React from 'react';
import './SupervisorDashboard.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import ViewUsers from '../../components/View/ViewUsers/ViewUsers';
import ViewMobileUsers from '../../components/View/ViewMobileUsers/ViewMobileUsers';
import ViewDispatchSite from '../../components/View/ViewDispatchSite/ViewDispatchSite';
import ViewDeliveriesSite from '../../components/View/ViewDeliveriesSite/ViewDeliveriesSite';

const SupervisorDashboard  = () => {
    
return (
    
<div className='adminContainer'>  

  <Tabs>
    <div className='trans'></div>
    <TabList className='tabsTitle'>
      <Tab>Dashboard Users</Tab>
      <Tab>Mobile Users</Tab>
      <Tab>Dispatch Sites</Tab>
      <Tab>Deliveries Sites</Tab>
    </TabList>

    <TabPanel >
      <div className='viewColumn'>
        <ViewUsers/>
      </div>       
    </TabPanel>

    <TabPanel>      
      <div className='viewColumn'>
        <ViewMobileUsers/>
      </div>       
    </TabPanel>

    <TabPanel>
      <div className='viewColumn'>
        <ViewDispatchSite/>
      </div>
    </TabPanel>

    <TabPanel>
      <div className='viewColumn'>
        <ViewDeliveriesSite/>
      </div>
    </TabPanel>

  </Tabs>
  
</div>         
 
)
       
    
}
export default SupervisorDashboard ;