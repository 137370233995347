import React from 'react';
import './Orders.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import CreateOrders from '../../components/Create/CreateOrders/CreateOrders';


const Orders = () => {
    
return (
    
  <div className='superAdminContainer'>  

      <Tabs>
      <div className='transOrd'></div>
      <TabList className='tabsTitle'>
        <Tab>Create</Tab>
        <Tab>View</Tab>
      </TabList>

      <TabPanel >
        <div className='createColumn'>
          <CreateOrders/>
        </div>
      </TabPanel>

      <TabPanel >
        <div className='viewColumn'>
         <text>View Table</text>
        </div>       
      </TabPanel>

    </Tabs>
  
  </div>         
 
)
       
    
}
export default Orders;