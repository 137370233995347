import React, { useState } from "react";
import axios from "axios";
import {
	Container,
	Button,
	Grid,
	Paper,
  List,
	ListItem,
	ListItemText,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import './CreateOrders.css'

const CreateOrders = () =>{
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  // const [toDoList, setToDoList] = useState({
  //   items:"",
  //   qty:""
  // });

  const [toDoList, setToDoList] = useState([])

  const [data, setData] = useState({
    nameOfSchool: "",
    address:"",
    delDate:"",
  })

  const handleChange = (e) => {
    setData({...data, [e.target.name]: e.target.value});
    
 }

 const handleDeleteToDo = (index) => {
  const updatedList = [...toDoList];
  updatedList.splice(index, 1);
  setToDoList(updatedList);
};

const handleAddToDo = (e) => {
  e.preventDefault();
  const newItem = `${data.items} - ${data.qty}`;
  setToDoList([...toDoList, newItem]);
  setData({ ...data, items: "", qty: "" });
};

  const handleSubmit=(e)=>{
    e.preventDefault();

   const sendData={
    nameOfSchool:data.nameOfSchool,
    address:data.address,
    delDate:data.delDate,
    }

    const sendItems = {
      items: toDoList.map((item) => item.split(" - ")[0]), 
      qty: toDoList.map((item) => item.split(" - ")[1]), 
    };

    axios.post("https://worxit.co.za/api/zamatrack/create/createOrder.php",sendData)
    .then((res)=> {
      setMessage(res.data.message);   
      setData({nameOfSchool:"",address:""})   
    })
    .catch((err) => {
      console.error(err);
      setErrorMessage("An error occurred.");
    });

    axios
    .post(
      "https://worxit.co.za/api/zamatrack/create/createItems.php",
      sendItems
    )
    .then((res) => {
      setMessage(res.data.message); 
    })
    .catch((err) => {
      setErrorMessage("An error occurred.");
    });
    console.log(sendData);
    console.log(sendItems);

  }

    return(
        <div className="newOrder-form" >
		<Container maxWidth="sm" >
		<Grid
		container
		spacing={2}
		direction="column"
		justifyContent="center"
		style={{ minHeight: "60vh", }}
	>
	<Paper elelvation={2} sx={{ padding: 5 }}>
	<form >
	<Grid container direction="column" spacing={2}>
    <h4 className="regTitle">New Order</h4>
		<Grid item>
			<input
			    type="text"
                name="nameOfSchool"
				placeholder="Name"
				variant="outlined"
                required
                value={data.nameOfSchool}
                onChange={handleChange}
			/>
		</Grid>

		<Grid item>
		<input
			type= "text"
            name="address"
			placeholder="Address"
			variant="outlined"
			required
            value={data.address}
			onChange={handleChange}
		/>
		</Grid>

    <Grid item>
      <input
        type= "date"
        name="delDate"
        placeholder="delDate"
        variant="outlined"
        required
        value={data.delDate}
        onChange={handleChange}
        
      />
		</Grid>
   
	</Grid>
	</form>
	</Paper>
    <Paper className="toDoListContainer" elevation={2} sx={{ padding: 5 }}>
            <form onSubmit={handleAddToDo}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={5}>
                  <input
                    type="text"
                    name="items"
                    placeholder="Items"
                    variant="outlined"
                    value={data.items || ""}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={5}>
                  <input
                    type="text"
                    name="qty"
                    placeholder="Qty"
                    variant="outlined"
                    value={data.qty || ""}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Button type="submit" fullWidth variant="contained">
                    Add
                  </Button>
                </Grid>
              </Grid>
            </form>
            <div className="capturedItems">
               <h4>Items</h4>
            <List>
              {toDoList.map((item, index) => (
                <ListItem className="itemList" key={index}>
                  <ListItemText primary={item} />
                  <IconButton
                    aria-label="delete"
                    onClick={() => handleDeleteToDo(index)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItem>
              ))}
            </List>
            </div>
            <Grid item>
        <Button className="sumOrdBtn" type="submit" fullWidth variant="contained"  onClick={handleSubmit}>
          Submit Order
        </Button>
        {message && <p className="message">{message}</p>}
        {errorMessage && <p className="errorMsg">{errorMessage}</p>}
        </Grid>
          </Paper>
	</Grid>
	
	</Container>
			</div> 
    )
}

export default CreateOrders;