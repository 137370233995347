import React, { useState } from "react";
import { lpaAdminQuestionsState } from "../../components/RecoilStates/LpaAdminQuestionsState";
import { useSetRecoilState } from "recoil";
import AdminSection from "../../components/MaintenanceSections/AdminSection/AdminSection";
import BackupSection from "../../components/MaintenanceSections/BackupSection/BackupSection";
import CashOfficeSection from "../../components/MaintenanceSections/CashOfficeSection/CashOfficeSection";
import FrontlineSection from "../../components/MaintenanceSections/FrontlineSection/FrontlineSection";
import ReceivingSection from "../../components/MaintenanceSections/ReceivingSection/ReceivingSection";
import SalesFloorSection from "../../components/MaintenanceSections/SalesFloorSection/SalesFloorSection";
import SecuritySection from "../../components/MaintenanceSections/SecuritySection/SecuritySection";
import './AuditMaintenance.css'

const AuditMaintenance = () => {

    const setAdminQuestions = useSetRecoilState(lpaAdminQuestionsState);

    // State to track the active tab
    const [activeTab, setActiveTab] = useState("AdminSection");
  
    // Function to switch the active tab
    const handleTabClick = (tabName) => {
      setActiveTab(tabName);
    };

    

    return (
        <div>
          <h4 className="auditTitle">Audit Maintenance</h4>
          <div className="tab-container">
            {/* Horizontal tabs */}
           
           {/* <div
              className={`tab ${
                activeTab === "AdminSection" ? "active" : ""
              }`}
              onClick={() => handleTabClick("AdminSection")}
            >
              Section A
            </div>*/}
            <div
              className={`tab ${
                activeTab === "BackupSection" ? "active" : ""
              }`}
              onClick={() => handleTabClick("BackupSection")}
            >
              Section A
            </div>
            <div
              className={`tab ${activeTab === "FrontlineSection" ? "active" : ""}`}
              onClick={() => handleTabClick("FrontlineSection")}
            >
              Section B
            </div>
            <div
              className={`tab ${activeTab === "SecuritySection" ? "active" : ""}`}
              onClick={() => handleTabClick("SecuritySection")}
            >
              Section C
            </div>
            {/* <div
              className={`tab ${activeTab === "ReceivingSection" ? "active" : ""}`}
              onClick={() => handleTabClick("ReceivingSection")}
            >
              Receiving Section
            </div>
            <div
              className={`tab ${activeTab === "SalesFloorSection" ? "active" : ""}`}
              onClick={() => handleTabClick("SalesFloorSection")}
            >
              Sales Floor Section
            </div>*/}
            <div
              className={`tab ${activeTab === "CashOfficeSection" ? "active" : ""}`}
              onClick={() => handleTabClick("CashOfficeSection")}
            >
              Section D
            </div> 
            
          </div>
    
          {/* Render the selected section */}
          
          {activeTab === "AdminSection" && <AdminSection />}
          {activeTab === "BackupSection" && <BackupSection />}
          {activeTab === "FrontlineSection" && <FrontlineSection />}
          {activeTab === "SecuritySection" && <SecuritySection />}
          {activeTab === "ReceivingSection" && <ReceivingSection />}
          {activeTab === "SalesFloorSection" && <SalesFloorSection />}
          {activeTab === "CashOfficeSection" && <CashOfficeSection />}
          {/* Add conditions for other sections similarly */}
        </div>
      );
    };

export default AuditMaintenance;