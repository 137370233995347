import React, {useState, useEffect} from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import "./ChangeStatus.css";
import {
	Container,
	Grid,
	Paper,
} from "@mui/material";
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';


const ChangeStatus = () =>{

    const [changeStatus, setChangeStatus]= useState([]);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");
      
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const usersPerPage = rowsPerPage;
    const [pageNumber, setPageNumber] = useState(0);

    const handleRowsPerPageChange = (event) => {
      setRowsPerPage(parseInt(event.target.value));
      setPageNumber(0); // Reset the page number to 0
    };

    const companyTable = async () => {
      const response = await axios.get(
        'https://worxit.co.za/api/zamatrack/view/viewCompany.php'
      );
      const newData = response.data.records.map((record) => {
        // Check if the company ID exists in the changeStatus state
        const existingCompany = changeStatus.find(
          (viewCompany) => viewCompany.coID === record.coID
        );
        // If the company exists, use the existing status value
        // Otherwise, use the status value from the API response
        const status = existingCompany ? existingCompany.status : record.status;
        return {
          ...record,
          status,
        };
      });
      setChangeStatus(newData);
    };
    
    useEffect(() => {
      
      companyTable();
      // const intervalId = setInterval(companyTable, 3000);
      // return () => clearInterval(intervalId);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    

   
    const handleSwitchToggle = (coID, newStatus) => {
      // Make an API request to update the status value
      axios
        .post("https://worxit.co.za/api/zamatrack/update/updateCompanyStatus.php", {
          coID: coID,
          status: newStatus,
        })
        .then(() => {
          // If the API request is successful, update the status in the state
          setChangeStatus((prevState) =>
            prevState.map((viewCompany) => {
              if (viewCompany.coID === coID) {
                return {
                  ...viewCompany,
                  status: newStatus,
                };
              }
              return viewCompany;
            })
          );
        })
        .catch((error) => {
          // Handle any error that occurs during the API request
          console.error("Error updating status:", error);
        });
    };
    
  
    const AntSwitch = styled(Switch)(({ theme }) => ({
      width: 28,
      height: 16,
      padding: 0,
      display: "flex",
      "&:active": {
        "& .MuiSwitch-thumb": {
          width: 15,
        },
        "& .MuiSwitch-switchBase.Mui-checked": {
          transform: "translateX(9px)",
        },
      },
      "& .MuiSwitch-switchBase": {
        padding: 2,
        "&.Mui-checked": {
          transform: "translateX(12px)",
          color: "#fff",
          "& + .MuiSwitch-track": {
            opacity: 1,
            backgroundColor:
              theme.palette.mode === "dark" ? "#green" : "green",
          },
        },
      },
      "& .MuiSwitch-thumb": {
        boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
        width: 12,
        height: 12,
        borderRadius: 6,
        transition: theme.transitions.create(["width"], {
          duration: 200,
        }),
      },
      "& .MuiSwitch-track": {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor:
          theme.palette.mode === "dark" ? "red" : "red",
        boxSizing: "border-box",
      },
    }));
    return(
        
        <div className="companyStatusDetails">
           <Container maxWidth="sm" >
        <Grid
        container
        spacing={2}
        direction="column"
        justifyContent="center"
        style={{ minHeight: "30vh", }}
      >
      <Paper elelvation={2} sx={{ padding: 5 }}>
      <Grid container direction="column" spacing={2}>
        <Grid>
        <table className="companyStatusTbl">
            <thead>
                <tr>
                    <th>Company ID</th>
                    <th>Company Name</th>
                    <th>Status</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
            {loading ? (
                      <tr>
                        <td colSpan={11}>Loading...</td>
                      </tr>
                    ) : (        
                      changeStatus
                        // .sort((a, b) => new Date(b.dateTime) - new Date(a.dateTime))
                        .slice(pageNumber * usersPerPage, (pageNumber + 1) * usersPerPage)
                        .map((viewCompany, index) =>(
                            <tr key={index}>                                  
                                <td>{viewCompany.coID}</td> 
                                <td>{viewCompany.coName}</td> 
                                <td>{viewCompany.status}</td>
                                <td>
                                  <Stack direction="row" spacing={1} alignItems="center">
                                    <h6 style={{paddingRight:10}}>Suspended</h6>
                                    <AntSwitch
                                      checked={viewCompany.status === "1"}
                                      onChange={() => handleSwitchToggle(viewCompany.coID, viewCompany.status === "1" ? "0" : "1")}
                                      inputProps={{ 'aria-label': 'ant design' }}
                                    />

                                    <h6 style={{paddingLeft:10}}>Activate</h6>
                                  </Stack>
                                </td>
                            </tr>
                            ))
            )}
                </tbody>
            </table>   
            <div className="rows-per-page">
              <label className="lblRowCo">Rows per page:</label>
              <select className="pageRowsCo" value={rowsPerPage} onChange={handleRowsPerPageChange}>
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
              </select>
            

            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              pageCount={Math.ceil(changeStatus.length / usersPerPage)}
              onPageChange={({ selected }) => setPageNumber(selected)}
              containerClassName={"pagination"}
              previousLinkClassName={"pagination__link"}
              nextLinkClassName={"pagination__link"}
              disabledClassName={"pagination__link--disabled"}
              activeClassName={"pagination__link--active"}
            />
            </div>
            {message && <p className="NoMatchFoundMsg">{message}</p>} 
            </Grid>
      
      </Grid>
      </Paper>
      </Grid>
      
      </Container> 
        </div>
        
        
    )
}

export default ChangeStatus;