import React, {useEffect, useState,useRef} from "react";
import {  useParams,useNavigate } from "react-router-dom";
import axios from "axios";
import { CSVLink } from "react-csv";
import "./showPalletDetails.css";
import ReactPaginate from 'react-paginate';
import { Spin } from "react-cssfx-loading";
import ExportToPDF from "./ExportToPDF/exportToPdf";
import { MapContainer, TileLayer, Marker,Popup } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import locationMarker from '../../../media/locationMarker.png';
import {AtomPalletDetailsState} from '../../../components/RecoilStates/AtomPalletDetailsState';
import { AtomPalletDetatilsItemsState } from  '../../../components/RecoilStates/AtomPalletDetatilsItemsState';
import { useRecoilState } from "recoil";

function ShowPalletDetails() {

    const { palletReturnsNo } = useParams();  
    const [loading, setLoading] = useState(false);
    const [showPalletTbl, setShowPalletTbl] = useRecoilState(AtomPalletDetailsState)
    const [showPalletItemsTble, setShowPalletItemsTble] = useRecoilState(AtomPalletDetatilsItemsState);
    //const [latitude, setLatitude] = useState(showPalletTbl.latitude);
    //const [longitude, setLongitude] = useState(showPalletTbl.longitude);
    const [markerPopup, setMarkerPopup] = useState(false);
    const mapRef = useRef(null);
    const [videoDis, setVideoDis] = useState(null)

      //----------Pagination------------     
      const [rowsPerPage, setRowsPerPage] = useState(5);
      const usersPerPage = rowsPerPage;
      const [pageNumber, setPageNumber] = useState(0);
 
      const handleRowsPerPageChange = (event) => {
        setRowsPerPage(parseInt(event.target.value));
        setPageNumber(0); // Reset the page number to 0
      };

    useEffect(() => {
      palletTable();
      palletItemsTbl();
  
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const navigate = useNavigate();

    const receivingNavigate=()=>{
        navigate('/pallets')
    }

  
    const combinedTbleDataHeaders =[
      { label: 'Pallet Retuns No', key: 'palletReturnsNo' },
      { label: 'Date Time', key: 'dateTime' },
      { label: 'Site No', key: 'siteNo' },
      { label: 'Receivers Name', key: 'receiversName' },
      { label: 'Guards Name', key: 'guardsName' },
      { label: 'Latitude', key: 'latitude' },
      { label: 'Longitude', key: 'longitude' },
      { label: 'Qty No', key: 'qty' },
      { label: 'Stack', key: 'stack' },
      
    ];

    console.log("Show Pallet Tbl: ", showPalletTbl)
    
    const combinedTbleData = [
      ...showPalletTbl.map(row => ({
        palletReturnsNo: row.palletReturnsNo,
        dateTime: row.dateTime,
        siteNo: row.siteNo,
        receiversName: row.receiversName,
        guardsName: row.guardsName,
        latitude:row.latitude,
        longitude:row.longitude
      })),
      ...showPalletItemsTble.map(row => ({
        qty: row.qty,
        stack: row.stack,
      })),
     
     
   ];

    const palletTable = async () => {
      console.log(palletReturnsNo);
      setLoading(true);
      const result = await axios.get(
        "https://worxit.co.za/api/zamatrack/showPalletDetails.php?palletReturnsNo=" +
        palletReturnsNo
      );
      setShowPalletTbl(result.data.records);
      setLoading(false);

     
    };


    const palletItemsTbl = async () => {
      console.log(palletReturnsNo);
      setLoading(true);
      const result = await axios.get(
        "https://worxit.co.za/api/zamatrack/palletItemsTable.php?palletReturnsNo=" +
        palletReturnsNo
      );
      setShowPalletItemsTble(result.data.records);
      setLoading(false);
      console.log(result)
    };
    // Create a custom marker icon
  const customIcon = L.icon({
  iconUrl: locationMarker,
  iconSize: [14, 18], // Adjust the size as needed

  });

    
    return (
      <div>

       <div className="palletReturnsTbl">
        <div>
          <label>Pallet Returns</label>
        </div>
          <table>
              <thead>
                <tr>
                  <th>Pallet Returns No</th>
                  <th>Date Time</th>
                  <th>Site No</th>
                  <th>Receivers Name</th>
                  <th>Guards Name</th>    
                </tr>
              </thead>
              <tbody>
              {loading ? (
                      <tr>
                        <td colSpan={11}>Loading...</td>
                      </tr>
                        ) : (showPalletTbl && showPalletTbl.map((details,index) => (
                  <tr key={index}>
                    <td>{palletReturnsNo}</td> 
                    <td>{details.dateTime}</td> 
                    <td>{details.siteNo}</td> 
                    <td>{details.receiversName}</td>
                    <td>{details.guardsName}</td> 
                  </tr>
                )))}
                
              </tbody>
            </table>
        </div>      
        <div className="viewContainer">
              {showPalletTbl.length > 0 ? (
                <>
                <div id="map-container" className="map-container">
                  <MapContainer
                    center={[showPalletTbl[0].latitude, showPalletTbl[0].longitude]}
                    zoom={18}
                    style={{ height: "400px", width: "100%" }}
                    className="map"
                    ref={mapRef}
                  >
                    <TileLayer
                      attribution="Map data &copy; OpenStreetMap contributors"
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />

                    <Marker position={[showPalletTbl[0].latitude, showPalletTbl[0].longitude]} icon={customIcon}>
                      <Popup onClose={() => setMarkerPopup(false)}>
                        <div>
                          Latitude: {[showPalletTbl[0].latitude]}<br />
                          Longitude: {[showPalletTbl[0].longitude]}<br />
                        </div>
                      </Popup>
                    </Marker>
                  </MapContainer>  
                  
                </div> 
              </>
              ): (
                <tr>
                  <td colSpan={6}>No Data Found
                    {/* <div className="loadingSpinComponent">
                      <Spin />
                    </div> */}
                  </td> 
                </tr>
              )} 
            
            </div>
        <div className="palletsItemsTbl">    
          <div>
            <label>Pallet Returns Items</label>
          </div>   
            <table>
              <thead>
                <tr>
                  <th>pallet Returns No</th> 
                  <th>Quantity Number</th>
                  <th>Stack</th>
                </tr>
              </thead>
              <tbody>
              {showPalletItemsTble.length > 0 ? (    
                          showPalletItemsTble && showPalletItemsTble
                          .slice(pageNumber * usersPerPage, (pageNumber + 1) * usersPerPage)
                          .map((details,index) => (
                  <tr key={index}>
                    <td>{palletReturnsNo}</td> 
                    <td>{details.qty}</td> 
                    <td>{details.stack}</td>
                  </tr>
                ))
                ) : (
                  <tr>
                    {/* colSpan={5}. The 5 is the amount of columns */}
                    <td colSpan={6}>
                    <div className="loadingSpinComponent">
                        <Spin />
                      </div>
                    </td> 
                  </tr>
                )}
                
              </tbody>
            </table>

            <div className="rows-per-page">
              <label>Rows per page:</label>
              <select className="pageRows" value={rowsPerPage} onChange={handleRowsPerPageChange}>
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
              </select>
            

            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              pageCount={Math.ceil(showPalletItemsTble.length / usersPerPage)}
              onPageChange={({ selected }) => setPageNumber(selected)}
              containerClassName={"pagination"}
              previousLinkClassName={"pagination__link"}
              nextLinkClassName={"pagination__link"}
              disabledClassName={"pagination__link--disabled"}
              activeClassName={"pagination__link--active"}
            />
            </div>
        </div>   
          <div className="actionBtns">    
            <button className="orderBackBtn" onClick={receivingNavigate}>Back</button>

            <CSVLink className="exportToCSVBtn"
              data={combinedTbleData}
              headers={combinedTbleDataHeaders}
              filename={"combinedTbleData.csv"}
              target="_blank">
                Export to csv
            </CSVLink>

            <ExportToPDF mapRef={mapRef}/>
        </div>
      </div>
    );
  }
  
  export default ShowPalletDetails;
  
  