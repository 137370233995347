import React from 'react';
import './SuperAdminDashboard.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import CreateAdmin from '../../components/Create/CreateAdmin/CreateAdmin';
import ViewAllUsers from '../../components/View/ViewAllUsers/ViewAllUsers';
import CreateCompany from '../../components/Create/CreateCompany/CreateCompany';
import ViewCompany from '../../components/View/ViewCompany/ViewCompany';
import ChangeStatus from '../../components/View/ChangeStatus/ChangeStatus';

const SuperAdminDashboard = () => {
    
return (
    
  <div className='superAdminContainer'>  

      <Tabs>
      <div className='transSU'></div>
      <TabList className='tabsTitle'>
        <Tab>Company</Tab>
        <Tab>Admin User</Tab>
        <Tab>Change Status</Tab>
      </TabList>

      <TabPanel >
        <div className='createColumn'>
          <CreateAdmin/>
        </div>
        <div className='viewColumn'>
          <ViewAllUsers/>
        </div>       
      </TabPanel>

      <TabPanel>
        <div className='createColumn'>
          <CreateCompany/>
        </div>       
        <div className='viewColumn'>
          <ViewCompany/>
        </div>       
      </TabPanel>

      <TabPanel>
        <div className='createColumn'>
          <ChangeStatus/>
        </div>
      </TabPanel>

    </Tabs>
  
  </div>         
 
)
       
    
}
export default SuperAdminDashboard;