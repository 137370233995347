import React, { useState,useEffect } from "react";
import axios from "axios";
import {
	Container,
	Button,
	Grid,
	Paper,
  Alert,
} from "@mui/material";
import {  useNavigate } from "react-router-dom";
import './CreateDevice.css'

const CreateDevice = () => {
  const navigate = useNavigate();
  const [message, setMessage] = useState("");

  const [data, setData] = useState({
    deviceId: "",  
    coID:"",
  })
  

  const handleChange = (e) => {
    setData({...data, [e.target.name]: e.target.value});
    
 }
  const handleSubmit=(e)=>{
    e.preventDefault();

    // if (data.password !== data.confirmPassword) {
    //   setMessage("Passwords do not match.");
    // return;
    // }

   const sendData={
    deviceId:data.deviceId,
    coID:data.coID
    }
    console.log(sendData)
    axios.post("https://worxit.co.za/api/zamatrack/create/createDevice.php",sendData)
    .then((res)=> {
      setMessage(res.data.message);
      setData({deviceId:"", coID:""})
    })
    .catch((err) => {
      console.error(err);
      setMessage("An error occurred.");
    });
  }

  return (
    <div className="createDevices-form" >
		<Container maxWidth="sm" >
		<Grid
		container
		spacing={2}
		direction="column"
		justifyContent="center"
		style={{ minHeight: "60vh", }}
	>
	<Paper elelvation={2} sx={{ padding: 5 }}>
	<form  onSubmit={handleSubmit}>
	<Grid container direction="column" spacing={2}>
    <h4 className="regTitle">Create Device</h4>
		<Grid item>
			<input
				type="text"
        name="deviceId"
				placeholder="Android ID"
				variant="outlined"
        required
        value={data.deviceId}
        onChange={handleChange}
			/>
		</Grid>
    <Grid item>
      <input
        type= "text"
        name="coID"
        placeholder="Company ID"
        variant="outlined"
        required
        value={data.coID}
        onChange={handleChange}
        
      />
		</Grid>
		<Grid item>
        <Button type="submit" fullWidth variant="contained">
			Create Device
		</Button>
    {message && <p className="errorMsg">{message}</p>}
		</Grid>
	</Grid>
	</form>
	</Paper>
	</Grid>
	
	</Container>
			</div> 
  );
};

export default CreateDevice;
