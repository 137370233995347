import React, {useState, useEffect} from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import "./Suppliers.css";

import 'jspdf-autotable';

const Suppliers = () =>{

    const [supplied, setSupplied]= useState([]);
    const [loading, setLoading] = useState(false);
    const [docNoSearch, setDocNoSearch] = useState("");
    const [dateTimeStart, setDateTimeStart] = useState(""); 
    const [message, setMessage] = useState("");
  
    
    const [rowsPerPage, setRowsPerPage] = useState(5);
      const usersPerPage = rowsPerPage;
      const [pageNumber, setPageNumber] = useState(0);
 
      const handleRowsPerPageChange = (event) => {
        setRowsPerPage(parseInt(event.target.value));
        setPageNumber(0); // Reset the page number to 0
      };


    useEffect(() => {
      const fetchData = async () => {
        const response = await axios.get('https://worxit.co.za/api/zamatrack/suppliersView.php');
        const newData = response.data.records;
        setSupplied(newData);
      };
      fetchData();
      const intervalId = setInterval(fetchData, 3000);
      return () => clearInterval(intervalId);
    }, []);
    
    const filteredSuppliers= supplied.filter(supplied => {

        if (docNoSearch !== "" || dateTimeStart !== "") {
          if (
            supplied.docNo.includes(docNoSearch) &&
            supplied.dateTime.includes(dateTimeStart)
          ) {
            return supplied;
          }
          return false;
        }
        return supplied;
        });
      
      useEffect(() => {
        if (filteredSuppliers.length === 0 && (docNoSearch !== "" || dateTimeStart !== "")) {
          setMessage("No Match Found");
        } else {
          setMessage("");
        }
      }, [filteredSuppliers, docNoSearch, dateTimeStart]);


    return(
        
        <div style={{width: "100%"}}>
        <div className=" row filter" >
            <div className="column1">
                <h5>Doc Number:</h5>           
            </div>
            <div className="column2"> 
                <input
                className="searchInput"
                type="text"
                placeholder= "Search..."
                onChange={(e) => setDocNoSearch(e.target.value)}/>  
            </div>
            <div className="column3">
                <h5>Date:</h5>
            </div>
                <div className="column4">
                    <input
                        className="searchInput"
                        type="date"
                        placeholder= "Search..."
                        onChange={(e) => setDateTimeStart(e.target.value, "from")}/>    
                </div>                  
    </div>
        <h7>Latest 5 Orders Completed...</h7>
        <table>
            <thead>
                <tr>
                    <th>Doc No</th>
                    <th>Suppliers Name</th>
                    <th>Date Time</th>
                    <th>Transporter</th>
                    <th>Driver Name</th>
                    <th>Vehicle Reg</th>
                    <th>Vehicle Make</th>
                    <th>Vehicle colour</th>
                    <th>Receivers Name</th>
                    <th>Guards Name</th>
                </tr>
            </thead>
            <tbody>
                {loading ? (
                        <tr>
                            <td colSpan={11}>Loading...</td>
                        </tr>
                    ) : (        
                        filteredSuppliers
                        .sort((a, b) => new Date(b.dateTime) - new Date(a.dateTime))
                        .slice(pageNumber * usersPerPage, (pageNumber + 1) * usersPerPage)
                        .map((supplied, index) =>(
                            <tr key={index}>   
                                <td ><Link className="btn btn-success" to={`/showSuppliersDetails/${supplied.docNo}`}>
                                    {supplied.docNo}
                                    </Link>
                                </td>
                                {/* <td ><Link className="btn btn-success" to={`/showAddress/${dispatch.storeSiteNo}`}>
                                    {dispatch.storeSiteNo}
                                    </Link> 
                                </td> */}
                                 {/* <td>{receive.orderNo}</td>  */}
                                <td>{supplied.supplierName}</td> 
                                <td>{supplied.dateTime}</td> 
                                <td>{supplied.transporter}</td> 
                                <td>{supplied.driverName}</td>
                                <td>{supplied.vehicleReg}</td>
                                <td>{supplied.vehicleMake}</td>
                                <td>{supplied.vehicleColour}</td>
                                <td>{supplied.receiversName}</td>
                                <td>{supplied.guardsName}</td>
                            </tr>
                            ))
            )}
                </tbody>
            </table>   
            <div className="rows-per-page">
              <label>Rows per page:</label>
              <select className="pageRows" value={rowsPerPage} onChange={handleRowsPerPageChange}>
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
              </select>
            

            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              pageCount={Math.ceil(filteredSuppliers.length / usersPerPage)}
              onPageChange={({ selected }) => setPageNumber(selected)}
              containerClassName={"pagination"}
              previousLinkClassName={"pagination__link"}
              nextLinkClassName={"pagination__link"}
              disabledClassName={"pagination__link--disabled"}
              activeClassName={"pagination__link--active"}
            />
            </div>
            {message && <p className="NoMatchFoundMsg">{message}</p>}  
        </div>
        
        
    )
}

export default Suppliers;