import React, {useEffect, useState} from "react";
import axios from "axios";
import {  useParams } from "react-router-dom";
import { Document, Page } from "react-pdf";
import jsPDF from 'jspdf'
import 'jspdf-autotable';
import domtoimage from 'dom-to-image';
//import './exportToPdf.css'
import { lpaFindingsState } from "../RecoilStates/LpaFindingsState";
import { lpaAdminDetailsState } from "../RecoilStates/LpaAdminDetailsState";
import { lpaBackupsDetailsState } from "../RecoilStates/LpaBackupsDetailsState";
import { lpaFrontlineDetailsState } from "../RecoilStates/LpaFrontlineDetailsState";
import { lpaSecurityDetailsState } from '../RecoilStates/LpaSecurityDetailsState';
import { lpaReceivingDetailsState } from '../RecoilStates/LpaReceivingDetailsState';
import { lpaSalesFloorDetailsState } from '../RecoilStates/LpaSalesFloorDetailsState';
import { lpaCashOfficeDetailsState } from '../RecoilStates/LpaCashOfficeDetailsState';
import failCircle from '../../media/failCircle.png';
import passCircle from '../../media/passCircle.png';
import naCircle from '../../media/naCircle.png';
import boxerLogo from '../../media/boxerLogo.jpg'
import zamatrackLogo from '../../media/zamatrackHeaderLogo.jpg';
import { useRecoilValue } from "recoil";
import { auditLogsAtom } from "../RecoilStates/auditlogsAtom";
import { auditHeaderDataAtom } from "../RecoilStates/auditHeaderDataAtom";

const ExportToPDF = ({chartRef}) => {
  const { auditCode } = useParams();  
  const auditHeaderData = useRecoilValue(auditHeaderDataAtom);
 const auditLogsData = useRecoilValue(auditLogsAtom)
  const findingsDetails = useRecoilValue(lpaFindingsState);
  const adminDetails = useRecoilValue(lpaAdminDetailsState);
  const backupsDetails = useRecoilValue(lpaBackupsDetailsState);
  const frontlineDetails = useRecoilValue(lpaFrontlineDetailsState);
  const securityDetails = useRecoilValue(lpaSecurityDetailsState);
  const receivingDetails = useRecoilValue(lpaReceivingDetailsState);
  const salesFloorDetails = useRecoilValue(lpaSalesFloorDetailsState);
  const cashOfficeDetails = useRecoilValue(lpaCashOfficeDetailsState);
 
  const [pdfOpen, setPdfOpen] = useState(false);
  const [file, setFile] = useState(null);

  
    if (!Array.isArray(adminDetails)) {
    return <div>Loading...</div>; // or return an empty component if desired
  }

  if (!Array.isArray(backupsDetails)) {
    return <div>Loading...</div>; // or return an empty component if desired
  }

  if (!Array.isArray(frontlineDetails)) {
    return <div>Loading...</div>; // or return an empty component if desired
  }

  if (!Array.isArray(securityDetails)) {
    return <div>Loading...</div>; // or return an empty component if desired
  }

  if (!Array.isArray(receivingDetails)) {
    return <div>Loading...</div>; // or return an empty component if desired
  }

  if (!Array.isArray(salesFloorDetails)) {
    return <div>Loading...</div>; // or return an empty component if desired
  }

  if (!Array.isArray(cashOfficeDetails)) {
    return <div>Loading...</div>; // or return an empty component if desired
  }

  

  const filteredAuditLogs = auditLogsData.filter(details => details.auditCode === auditCode);
  const filteredFindingDetails = findingsDetails.filter(details => details.auditCode === auditCode);
  const filteredAdminDetails = adminDetails.filter(details => details.auditCode === auditCode);
  const filteredBackupsDetails = backupsDetails.filter(details => details.auditCode === auditCode);
  const filteredFrontlineDetails = frontlineDetails.filter(details => details.auditCode === auditCode);
  const filteredSecurityDetails = securityDetails.filter(details => details.auditCode === auditCode);
  const filteredReceivingDetails = receivingDetails.filter(details => details.auditCode === auditCode);
  const filteredSalesFloorDetails = salesFloorDetails.filter(details => details.auditCode === auditCode);
  const filteredCashOfficeDetails = cashOfficeDetails.filter(details => details.auditCode === auditCode);

  const cashOfficeScore = parseInt(filteredCashOfficeDetails[0].totalScore);
  //const adminScore = parseInt(filteredAdminDetails[0].totalScore);
  const backupsScore = parseInt(filteredBackupsDetails[0].totalScore);
  const securityScore = parseInt(filteredSecurityDetails[0].totalScore);
  const frontlineScore = parseInt(filteredFrontlineDetails[0].totalScore);
  //const salesFloor = parseInt(filteredSalesFloorDetails[0].totalScore);
  //const receivingScore = parseInt(filteredReceivingDetails[0].totalScore);

  // const totalScoreAll = (cashOfficeScore + adminScore + backupsScore +
  // securityScore + frontlineScore + salesFloor + receivingScore)
  const totalScoreAll = (cashOfficeScore + backupsScore +
    frontlineScore + securityScore )

  let scoreCard = (totalScoreAll / 700) * 100;
  scoreCard = Math.ceil(scoreCard);

   
  //------------------COMBINED TABLES-----------------

  //------------------DISPATCH TABLE---------------
  const combinedTblPdfExport = async () => {
    const pdf = new jsPDF();

    pdf.addImage(zamatrackLogo, "PNG", 0, 0, 210, 50); 

    //------Title------
    pdf.setFontSize(12);
    pdf.setFont('helvetica', 'bold');
    pdf.text(80, 70, 'Loss Prevention Awareness');
    pdf.setFont('helvetica', 'normal');

    const reportDate = new Date().toLocaleDateString();
    pdf.setFontSize(10);
   
    const data = 
    filteredAuditLogs.map((details) => [
        details.auditCode,
        details.site,
        details.latitude,
        details.longitude, 
      ])
  
      const tableData = [
      ['auditCode', ...data.map((row) => row[0])],
      ['Site', ...data.map((row) => row[1])], 
      ['Date Time', `${reportDate}`],
      ['Latitude', ...data.map((row) => row[2])],
      ['Longitude', ...data.map((row) => row[3])],
           
    ];

       
    pdf.autoTable({
      body: tableData,
      theme: 'grid',
      startY: 80,
      tableWidth: 121, 
      columnStyles: {
        2: { cellWidth: 40 }, // This sets the width of the 3rd column
      },
      alternateRowStyles: {
        fillColor: [208, 216, 223],
      },
      didParseCell: function (data) {
        if (data.column.index === 0) {
          data.cell.styles.fontStyle = 'bold';
        }
      },
    });
               
    const lastRowX = 135; // X position of the last column
    const lastRowY = 80; // Y position of the last row
    
    // Draw merged cell and text
    pdf.setFillColor(192, 197, 201); // Set the fill color to the desired color
    pdf.rect(lastRowX, lastRowY, 60, 38, 'F'); // Draw the filled rectangle
    pdf.setDrawColor(0); // Set the border color to black
    pdf.rect(lastRowX, lastRowY, 60, 38); // Draw the border of the rectangle
    pdf.setFontSize(14);
    pdf.setFont('helvetica', 'bold');
    pdf.setTextColor(0); // Set text color to black
    pdf.text('Scorecard', lastRowX + 30, lastRowY + 16, { align: 'center' });
    pdf.text(scoreCard.toString(), lastRowX + 30, lastRowY + 22, { align: 'center' });
    
//---------------------- CHART--------------

pdf.setDrawColor(0); // Set the border color to black

// Draw a rectangle around the mapContainer
const mapContainer = document.getElementById("mapContainer");
if (mapContainer) {
  const mapCanvas = await domtoimage.toPng(mapContainer);
  pdf.addImage(mapCanvas, "PNG", 7, 130, 90, 120);
  pdf.rect(13, 137, 79, 105); // Draw the border of the rectangle around the mapContainer
}

// Draw a rectangle around the chartContainer
const chartContainer = document.getElementById("chartContainer");
if (chartContainer) {
  const chartCanvas = await domtoimage.toPng(chartContainer);
  pdf.addImage(chartCanvas, "PNG", 93, 130, 105, 120);
  pdf.rect(97, 137, 96, 105); // Draw the border of the rectangle around the chartContainer
}

// // Draw a rectangle around both containers
// pdf.rect(10, 130, 190, 120); // 
  //----------------DISPATCH ITEMS TABLE------------------

  pdf.addPage()

  const headerAlerttitle = ['Alert'];

  pdf.autoTable({
    head: [headerAlerttitle],
    startY: 15,
    styles: {
      cellHeader: {
        fontSize: 18,      // Adjust font size as needed
        fontStyle: 'bold', // Apply bold font style
        fillColor: [233, 233, 233], // Background color for header cell
      }
    },
    headStyles: {
      fillColor: [168, 167, 167], 
      textColor: 0 ,
      halign: 'center',             
    }
  });
 
  const findingHeadersDets = ['Type', 'Responses', 'Actions', 'Responsible Person', 'Due Date'];
  const findingsData = filteredFindingDetails.map(details => ['',  `${details.questions} - ${details.responses}`,  details.actions,  details.responsPerson,  details.dueDate])
  
  pdf.autoTable({
    head: [findingHeadersDets],
    theme: 'grid',
    startY:pdf.autoTable.previous.finalY,
    styles: {
      cellHeader: {
        fillColor: [233, 233, 233], // Background color for header cells
        fontStyle: 'bold', // Bold font style for header cells
        textColor: 0, // Text color for header cells
        halign: 'center', // Center align the header text
        cellWidth: 40, // Adjust as needed
      }
    },
    headStyles: {
      2: { cellWidth: 65 } // Adjust width for the "Actions" header cell
    }
  });
  
    
  pdf.autoTable({
    body: findingsData,
    theme: 'grid',
    startY:pdf.autoTable.previous.finalY,
    columnStyles: {
      0:{cellWidth:12},
      1:{cellWidth: 65},
      2: {cellWidth: 40}, 
      3: {cellWidth: 35},
      4: {cellWidth:30}
    },
    didDrawCell: (data) => {
      // Check if the cell is in the "Type" column and it's the first row
      if (data.column.index === 0 && data.row.index >= 0) {
        pdf.addImage(failCircle, data.cell.x + 2, data.cell.y, 7, 7);
      }
           
      }           
                       
  });
   
  // const headerAdmintitle = ['LPA Store Analysis : Admin'];

  // pdf.autoTable({
  //   head: [headerAdmintitle],
  //   startY:pdf.autoTable.previous.finalY + 15,
  //   styles: {
  //     cellHeader: {
  //       fontSize: 18,      // Adjust font size as needed
  //       fontStyle: 'bold', // Apply bold font style
  //       fillColor: [233, 233, 233], // Background color for header cell
  //     }
  //   },
  //   headStyles: {
  //     fillColor: [168, 167, 167], 
  //     textColor: 0 ,
  //     halign: 'center',             
  //   }
  // });

  //   const adminHeaderDets = ['Type', 'LPA Questions', 'Observations/Responses', 'Notes'];
  //   const adminData = filteredAdminDetails.map(details => ['', details.question, details.responses, details.notes]);
  
  //   const adminScores = filteredAdminDetails.map(details => details.scores);

  //   const adminDataWithBlankRow = [[], ...adminData];

  //   pdf.autoTable({
  //     head: [adminHeaderDets],
  //     body: adminDataWithBlankRow,
  //     theme: 'grid',
  //     startY: pdf.autoTable.previous.finalY,
  //     didDrawCell: data => {
  //       if (data.column.index === 0 && data.row.index > 0) {
  //         const score = adminScores[data.row.index - 1]; // Subtract 1 to align with the data array
  //         let image = null;
          
  //         if (score === 'Pass') {
  //           image = passCircle;
  //         } else if (score === 'Fail') {
  //           image = failCircle;
  //         } else if (score === 'N/A') {
  //           image = naCircle;
  //         }

  //         if (image) {
  //           pdf.addImage(image, data.cell.x + 2, data.cell.y +1  , 7, 7); // Adjust image size and position
  //         }
  //       }
  //     },
  //   });

    const headerBackupstitle = ['Section A'];

  pdf.autoTable({
    head: [headerBackupstitle],
    startY:pdf.autoTable.previous.finalY + 15,
    styles: {
      cellHeader: {
        fontSize: 18,      // Adjust font size as needed
        fontStyle: 'bold', // Apply bold font style
        fillColor: [233, 233, 233], // Background color for header cell
      }
    },
    headStyles: {
      fillColor: [168, 167, 167], 
      textColor: 0 ,
      halign: 'center',             
    }
  });


    const backupsHeaderDets = ['Type', 'LPA Questions', 'Observations/Responses', 'Notes'];
    const backupsData = filteredBackupsDetails.map(details => ['', details.question, details.responses, details.notes]);
  
    const backupsScores = filteredBackupsDetails.map(details => details.scores);

    const backupsDataWithBlankRow = [[], ...backupsData];

    pdf.autoTable({
      head: [backupsHeaderDets],
      body: backupsDataWithBlankRow,
      theme: 'grid',
      startY: pdf.autoTable.previous.finalY ,
      didDrawCell: data => {
        if (data.column.index === 0 && data.row.index > 0) {
          const score = backupsScores[data.row.index - 1]; // Subtract 1 to align with the data array
          let image = null;
          
          if (score === 'Pass') {
            image = passCircle;
          } else if (score === 'Fail') {
            image = failCircle;
          } else if (score === 'N/A') {
            image = naCircle;
          }

          if (image) {
            pdf.addImage(image, data.cell.x + 2, data.cell.y, 7, 7); // Adjust image size and position
          }
        }
      },
    });

    const headerFrontlinetitle = ['Section B'];

    pdf.autoTable({
      head: [headerFrontlinetitle],
      startY:pdf.autoTable.previous.finalY + 15,
      styles: {
        cellHeader: {
          fontSize: 18,      // Adjust font size as needed
          fontStyle: 'bold', // Apply bold font style
          fillColor: [233, 233, 233], // Background color for header cell
        }
      },
      headStyles: {
        fillColor: [168, 167, 167], 
        textColor: 0 ,
        halign: 'center',             
      }
    });

    const frontlineHeaderDets = ['Type', 'LPA Questions', 'Observations/Responses', 'Notes'];
    const frontlineData = filteredFrontlineDetails.map(details => ['', details.question, details.responses, details.notes]);
  
    const frontlineScores = filteredFrontlineDetails.map(details => details.scores);

    const frontlineDataWithBlankRow = [[], ...frontlineData];

    pdf.autoTable({
      head: [frontlineHeaderDets],
      body: frontlineDataWithBlankRow,
      theme: 'grid',
      startY: pdf.autoTable.previous.finalY,
      didDrawCell: data => {
        if (data.column.index === 0 && data.row.index > 0) {
          const score = frontlineScores[data.row.index - 1]; // Subtract 1 to align with the data array
          let image = null;
          
          if (score === 'Pass') {
            image = passCircle;
          } else if (score === 'Fail') {
            image = failCircle;
          } else if (score === 'N/A') {
            image = naCircle;
          }

          if (image) {
            pdf.addImage(image, data.cell.x + 2, data.cell.y , 7, 7); // Adjust image size and position
          }
        }
      },
    });


    const headerSecurtytitle = ['Section C'];

    pdf.autoTable({
      head: [headerSecurtytitle],
      startY:pdf.autoTable.previous.finalY + 15,
      styles: {
        cellHeader: {
          fontSize: 18,      // Adjust font size as needed
          fontStyle: 'bold', // Apply bold font style
          fillColor: [233, 233, 233], // Background color for header cell
        }
      },
      headStyles: {
        fillColor: [168, 167, 167], 
        textColor: 0 ,
        halign: 'center',             
      }
    });

    const securityHeaderDets = ['Type', 'LPA Questions', 'Observations/Responses', 'Notes'];
    const securityData = filteredSecurityDetails.map(details => ['', details.question, details.responses, details.notes]);
  
    const securityScores = filteredSecurityDetails.map(details => details.scores);

    const securityDataWithBlankRow = [[], ...securityData];


    pdf.autoTable({
      head: [securityHeaderDets],
      body: securityDataWithBlankRow,
      theme: 'grid',
      startY: pdf.autoTable.previous.finalY,
      didDrawCell: data => {
        if (data.column.index === 0 && data.row.index > 0) {
          const score = securityScores[data.row.index - 1]; // Subtract 1 to align with the data array
          let image = null;
          
          if (score === 'Pass') {
            image = passCircle;
          } else if (score === 'Fail') {
            image = failCircle;
          } else if (score === 'N/A') {
            image = naCircle;
          }

          if (image) {
            pdf.addImage(image, data.cell.x + 2, data.cell.y , 7, 7); // Adjust image size and position
          }
        }
      },
    });

    // const headerReceivingtitle = ['LPA Store Analysis : Receiving'];

    // pdf.autoTable({
    //   head: [headerReceivingtitle],
    //   startY:pdf.autoTable.previous.finalY + 15,
    //   styles: {
    //     cellHeader: {
    //       fontSize: 18,      // Adjust font size as needed
    //       fontStyle: 'bold', // Apply bold font style
    //       fillColor: [233, 233, 233], // Background color for header cell
    //     }
    //   },
    //   headStyles: {
    //     fillColor: [168, 167, 167], 
    //     textColor: 0 ,
    //     halign: 'center',             
    //   }
    // });

    // const receivingHeaderDets = ['Type', 'LPA Questions', 'Observations/Responses', 'Notes'];
    // const receivingData = filteredReceivingDetails.map(details => ['', details.question, details.responses, details.notes]);
  
    // const receivingScores = filteredReceivingDetails.map(details => details.scores);

    // const receivingDataWithBlankRow = [[], ...receivingData];

    // pdf.autoTable({
    //   head: [receivingHeaderDets],
    //   body: receivingDataWithBlankRow,
    //   theme: 'grid',
    //   startY: pdf.autoTable.previous.finalY ,
    //   didDrawCell: data => {
    //     if (data.column.index === 0 && data.row.index > 0) {
    //       const score = receivingScores[data.row.index - 1]; // Subtract 1 to align with the data array
    //       let image = null;
          
    //       if (score === 'Pass') {
    //         image = passCircle;
    //       } else if (score === 'Fail') {
    //         image = failCircle;
    //       } else if (score === 'N/A') {
    //         image = naCircle;
    //       }

    //       if (image) {
    //         pdf.addImage(image, data.cell.x + 2, data.cell.y +1  , 7, 7); // Adjust image size and position
    //       }
    //     }
    //   },
    // });

    // const headerSalesFloortitle = ['LPA Store Analysis : Sales Floor'];

    // pdf.autoTable({
    //   head: [headerSalesFloortitle],
    //   startY:pdf.autoTable.previous.finalY + 15,
    //   styles: {
    //     cellHeader: {
    //       fontSize: 18,      // Adjust font size as needed
    //       fontStyle: 'bold', // Apply bold font style
    //       fillColor: [233, 233, 233], // Background color for header cell
    //     }
    //   },
    //   headStyles: {
    //     fillColor: [168, 167, 167], 
    //     textColor: 0 ,
    //     halign: 'center',             
    //   }
    // });

    // const salesFloorHeaderDets = ['Type', 'LPA Questions', 'Observations/Responses', 'Notes'];
    // const SalesFloorData = filteredSalesFloorDetails.map(details => ['', details.question, details.responses, details.notes]);

    // const salesFloorScores = filteredSalesFloorDetails.map(details => details.scores);

    // const salesFloorDataWithBlankRow = [[], ...SalesFloorData];

    // pdf.autoTable({
    //   head: [salesFloorHeaderDets],
    //   body: salesFloorDataWithBlankRow,
    //   theme: 'grid',
    //   startY: pdf.autoTable.previous.finalY,
    //   didDrawCell: data => {
    //     if (data.column.index === 0 && data.row.index > 0) {
    //       const score = salesFloorScores[data.row.index - 1]; // Subtract 1 to align with the data array
    //       let image = null;
          
    //       if (score === 'Pass') {
    //         image = passCircle;
    //       } else if (score === 'Fail') {
    //         image = failCircle;
    //       } else if (score === 'N/A') {
    //         image = naCircle;
    //       }

    //       if (image) {
    //         pdf.addImage(image, data.cell.x + 2, data.cell.y +1  , 7, 7); // Adjust image size and position
    //       }
    //     }
    //   },
    // });

    const headerCashOfficetitle = ['Section C'];

    pdf.autoTable({
      head: [headerCashOfficetitle],
      startY:pdf.autoTable.previous.finalY + 15,
      styles: {
        cellHeader: {
          fontSize: 18,      // Adjust font size as needed
          fontStyle: 'bold', // Apply bold font style
          fillColor: [233, 233, 233], // Background color for header cell
        }
      },
      headStyles: {
        fillColor: [168, 167, 167], 
        textColor: 0 ,
        halign: 'center',             
      }
    });

    const cashOfficeHeaderDets = ['Type', 'LPA Questions', 'Observations/Responses', 'Notes'];
    const cashOfficeData = filteredCashOfficeDetails.map(details => ['', details.question, details.responses, details.notes]);
  
    const cashOfficeScores = filteredCashOfficeDetails.map(details => details.scores);

    const cashOfficeDataWithBlankRow = [[], ...cashOfficeData];

    pdf.autoTable({
      head: [cashOfficeHeaderDets],
      body: cashOfficeDataWithBlankRow,
      theme: 'grid',
      startY: pdf.autoTable.previous.finalY,
      didDrawCell: data => {
        if (data.column.index === 0 && data.row.index > 0) {
          const score = cashOfficeScores[data.row.index - 1]; // Subtract 1 to align with the data array
          let image = null;
          
          if (score === 'Pass') {
            image = passCircle;
          } else if (score === 'Fail') {
            image = failCircle;
          } else if (score === 'N/A') {
            image = naCircle;
          }

          if (image) {
            pdf.addImage(image, data.cell.x + 2, data.cell.y , 7, 7); // Adjust image size and position
          }
        }
      },
    });

    
    const lasttableY = pdf.autoTable.previous.finalY +25
    const lastTableRecY = pdf.autoTable.previous.finalY + 15
    const userName = auditHeaderData[0]?.user;
    const completionDate = auditLogsData[0]?.completionDate;

   
   pdf.setFontSize(12);
   pdf.setFont('helvetica', 'bold');
   pdf.setTextColor(0); // Set text color to black
   pdf.text(userName, 30, lasttableY, { align: 'center' });
   pdf.setFontSize(8);
   pdf.text('Date : ',22, lasttableY + 5, { align: 'center' });
   pdf.text(completionDate, 55, lasttableY + 5, { align: 'center' });
   pdf.rect(15, lastTableRecY, 80, 51); // Draw the border of the rectangle


  const totalScoresTitle = ['Total LPA Score'];

  //const receivingTotalScore = `Receiving = ${filteredReceivingDetails[0].totalScore}%`;
  //const salesFloorTotalScore = `Sales Floor = ${filteredSalesFloorDetails[0].totalScore}%`;
  const backupsTotalScore = `Section A = ${filteredBackupsDetails[0].totalScore}%`;
  const frontlineTotalScore = `Section B = ${filteredFrontlineDetails[0].totalScore}%`;
  const securityTotalScore = `Section C = ${filteredSecurityDetails[0].totalScore}%`;
  //const adminTotalScore = `Admin = ${filteredAdminDetails[0].totalScore}%`;
  const cashOfficeTotalScore = `Section D = ${filteredCashOfficeDetails[0].totalScore}%`;

  const totalScoreAllData = [
    //receivingTotalScore, 
    //salesFloorTotalScore, 
    frontlineTotalScore,
    securityTotalScore,
    backupsTotalScore,
    //adminTotalScore,
    cashOfficeTotalScore  
  ];



   pdf.autoTable({
    head: [totalScoresTitle],
    body: totalScoreAllData.map(score => [score]),
    theme: 'grid',
    startY: pdf.autoTable.previous.finalY + 15,
    tableWidth: 100,
    margin: { left: 95 },
    styles: {
      fontSize: 7 ,
      fontStyle: 'bold',
      halign: 'center'
    }
  });
  pdf.rect(95, lastTableRecY, 100, 51);

   pdf.save('Full Report.pdf');
  
  }    

  //-----------TEST -----------------

  

    return(
      <div>
        
        <button className="exportPDFBtn" onClick={combinedTblPdfExport}>Export to PDF</button>

          {pdfOpen && (
            <Document
              file={file}
              onLoadSuccess={() => setPdfOpen(false)}
              >
            <Page pageNumber={1} />
            </Document>
            )}
      </div>
    )
  }

  export default ExportToPDF;