import React, {useEffect, useState} from "react";
import {  useParams,useNavigate } from "react-router-dom";
import axios from "axios";
import { CSVLink } from "react-csv";
import 'jspdf-autotable';
import "./showSuppliersDetails.css";
import Modal from 'react-modal';
import ExportToPDF from "./ExportToPDF/exportToPdf";


function ShowSuppliersDetails() {

    const { docNo } = useParams();  
    const [loading, setLoading] = useState(false);
    const [showSuppliersTbl, setShowSuppliersTbl] = useState([]);
    const [showSuppliersItemsTbl, setShowSuppliersItemsTbl] = useState([]);
  
    useEffect(() => {
        suppliersTbl();
        suppliersItemsTbl();
             
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const navigate = useNavigate();

    const suppliersNavigate=()=>{
        navigate('/suppliers')
    }

    const combinedTbleDataHeaders =[
      {label: "Doc No", key: "docNo"},
      {label: "Suppliers Name", key: "supplierName"},
      {label: "Transporter", key: "transporter"},
      {label: "Driver", key: "driverName"},
      {label: "Date Time", key: "dateTime"},
      {label: "Vehicle Reg", key: "vehicleReg"},
      {label: "Vehicle Make", key: "vehicleMake"},
      {label: "Vehicle Colour", key: "vehicleColour"},
      {label: "Receivers Name", key: "receiversName"},
      {label: "Guards Name", key: "guardsName"},
      {label: "Quantity", key: "qtyNo"},
      {label: "Barcode", key: "barcode"},
      {label: "Description", key: "description"},
      // {label: "Weight", key: "weight"},
    ];

    const combinedTbleData = [
      ...showSuppliersTbl.map(row => ({
        docNo: row.docNo,
        supplierName: row.supplierName,
        transporter: row.transporter,
        driverName: row.driverName,
        dateTime: row.dateTime,
        vehicleReg: row.vehicleReg,
        vehicleMake: row.vehicleMake,
        vehicleColour: row.vehicleColour,
        receiversName: row.receiversName,
        guardsName: row.guardsName,          
      })),
      ...showSuppliersItemsTbl.map(row => ({
        qtyNo: row.qtyNo,
        barcode: row.barcode,
        description: row.description,
        // weight: row.weight,
      })),
    ];

    
    const suppliersTbl = async () => {
      console.log(docNo);
      setLoading(true);
      const result = await axios.get(
        "https://worxit.co.za/api/zamatrack/showSuppliersDetails.php?docNo=" + 
        docNo);
      setShowSuppliersTbl(result.data.records);
      setLoading(false);
    };

    const suppliersItemsTbl = async () => {
      console.log(docNo);
      setLoading(true);
      const result = await axios.get(
        "https://worxit.co.za/api/zamatrack/suppliersItemsTable.php?docNo=" + 
        docNo);
      setShowSuppliersItemsTbl(result.data.records);
      setLoading(false);
    };

 
    return (
      <div>
        <div className="suppliersTbl">
         <div>
            <label>Suppliers</label>
          </div>   
       <table>
          <thead>
            <tr>
              <th>Doc No</th>
              <th>Suppliers Name</th>
              <th>Date Time</th>
              <th>Transporter</th>
              <th>Driver Name</th>
              <th>Vehicle Reg</th>
              <th>Vehicle Make</th>
              <th>Vehicle colour</th>
              <th>Receivers Name</th>
              <th>Guards Name</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
                        <tr>
                          <td colSpan={11}>Loading...</td>
                        </tr>
                    ) : (    
            showSuppliersTbl && showSuppliersTbl.map((details,index) => (
              <tr key={index}>
                <td>{docNo}</td> 
                <td>{details.supplierName}</td> 
                <td>{details.dateTime}</td> 
                <td>{details.transporter}</td> 
                <td>{details.driverName}</td>
                <td>{details.vehicleReg}</td>
                <td>{details.vehicleMake}</td>
                <td>{details.vehicleColour}</td>
                <td>{details.receiversName}</td>
                <td>{details.guardsName}</td>
              </tr>
            )))}
            
          </tbody>
        </table>
        </div>
        <div className="suppliersItemsTbl">    
          <div>
            <label>Suppliers Items</label>
          </div>   
            <table>
              <thead>
                <tr>
                  <th>Doc No</th> 
                  <th>Quantity</th>
                  <th>Barcode</th>
                  <th>Description</th>          
                  {/* <th>Weight</th> */}
                </tr>
              </thead>
              <tbody>
                {loading ? (
                        <tr>
                          <td colSpan={11}>Loading...</td>
                        </tr>
                          ) : (    
                            showSuppliersItemsTbl && showSuppliersItemsTbl.map((details,index) => (
                  <tr key={index}>
                    <td>{docNo}</td> 
                    <td>{details.qtyNo}</td> 
                    <td>{details.barcode}</td> 
                    <td>{details.description ? details.description : 'No description found'}</td> 
                    {/* <td>{details.weight}</td> */}
                  </tr>
                ))
                
                )}
                
              </tbody>
            </table>
        </div>  
        <div className="actionBtns">    
        <button className="orderBackBtn" onClick={suppliersNavigate}>Back</button>
              
          <CSVLink className="exportToCSVBtn"
            data={combinedTbleData}
            headers={combinedTbleDataHeaders}
            filename={"Full_Report.csv"}
            target="_blank">
              Export to csv
          </CSVLink>

          <ExportToPDF/>   

        </div>
      </div>
    );
  }
  
  export default ShowSuppliersDetails;
  