import React, { useEffect, useState} from 'react';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import {  useNavigate } from "react-router-dom";
import axios from 'axios';
import { auditHeaderDataAtom } from '../../components/RecoilStates/auditHeaderDataAtom';
import { auditCodeAtom } from '../../components/RecoilStates/auditCodeAtom';
import { auditSiteAtom } from '../RecoilStates/auditSiteAtom';

import { lpaAdminQuestionsState } from '../../components/RecoilStates/LpaAdminQuestionsState'
import { lpaAdminDataState } from '../../components/RecoilStates/LpaAdminDataState';
import { lpaAdminDetailsState } from '../../components/RecoilStates/LpaAdminDetailsState';

import { lpaBackupQuestionsState } from '../../components/RecoilStates/LpaBackupQuestionsState';
import { lpaBackupDataState } from '../../components/RecoilStates/LpaBackupDataState';
import { lpaBackupsDetailsState } from '../../components/RecoilStates/LpaBackupsDetailsState';

import { lpaFrontlineQuestionsState } from '../../components/RecoilStates/LpaFrontlineQuestionsState';
import { lpaFrontlineDataState } from '../../components/RecoilStates/LpaFrontLineDataState';
import { lpaFrontlineDetailsState } from '../../components/RecoilStates/LpaFrontlineDetailsState';

import { lpaSecurityQuestionsState } from '../../components/RecoilStates/LpaSecurityQuestionsState';
import { lpaSecurityDataState } from '../../components/RecoilStates/LpaSecurityDataState';
import { lpaSecurityDetailsState } from '../../components/RecoilStates/LpaSecurityDetailsState';

import { lpaReceivingQuestionsState } from '../../components/RecoilStates/LpaReceivingQuestionsState';
import { lpaReceivingDataState } from '../../components/RecoilStates/LpaReceivingDataState';
import { lpaReceivingDetailsState } from '../../components/RecoilStates/LpaReceivingDetailsState';

import { lpaSalesFloorQuestionsState } from '../../components/RecoilStates/LpaSalesFloorQuestionsState'
import { lpaSalesFloorDataState } from '../../components/RecoilStates/LpaSalesFloorDataState';
import { lpaSalesFloorDetailsState } from '../../components/RecoilStates/LpaSalesFloorDetailsState';

import { lpaCashOfficeQuestionsState } from '../../components/RecoilStates/LpaCashOfficeQuestionsState';
import { lpaCashOfficeDataState } from '../../components/RecoilStates/LpaCashOfficeDataState';
import { lpaCashOfficeDetailsState } from '../../components/RecoilStates/LpaCashOfficeDetailsState';

import '../../styles/LpaStoreAnalysisStyles.css'

const FindingsLogs = () => {

    const [message, setMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const auditHeaderData = useRecoilValue(auditHeaderDataAtom);
    const auditSite = useRecoilValue(auditSiteAtom);
    const auditCodeValue = useRecoilValue(auditCodeAtom);
    const [isAllActionsFilled, setIsAllActionsFilled] = useState(false);
    const setAuditCodeValue = useSetRecoilState(auditCodeAtom);
    const [values, setValues] = useState({ site: '' });

    
    const navigate = useNavigate();
    
       //------------TEST --------------- 
    const lpaAdminData = useRecoilValue(lpaAdminDataState)
    const lpaAdminQuestion = useRecoilValue(lpaAdminQuestionsState)

    const lpaBackupsData = useRecoilValue(lpaBackupDataState);
    const lpaBackupsQuestion = useRecoilValue(lpaBackupQuestionsState);

    const lpaFrontlineData = useRecoilValue(lpaFrontlineDataState)
    const lpaFrontlineQuestions = useRecoilValue(lpaFrontlineQuestionsState);

    const lpaSecurityData = useRecoilValue(lpaSecurityDataState);
    const lpaSecurityQuestions = useRecoilValue(lpaSecurityQuestionsState);

    const lpaReceivingData = useRecoilValue(lpaReceivingDataState);
    const lpaReceivingQuestions = useRecoilValue(lpaReceivingQuestionsState);

    const lpaSalesFloorData = useRecoilValue(lpaSalesFloorDataState);
    const lpaSalesFloorQuestions = useRecoilValue(lpaSalesFloorQuestionsState);

    const lpaCashOfficeData = useRecoilValue(lpaCashOfficeDataState);
    const lpaCashOfficeQuestions = useRecoilValue(lpaCashOfficeQuestionsState);

    const resetAuditCode = useResetRecoilState(auditCodeAtom)
    const resetAuditSite = useResetRecoilState(auditSiteAtom)

    const resetLpaAdminDetailsState = useResetRecoilState(lpaAdminDetailsState)
    const resetLpaAdminDataState = useResetRecoilState(lpaAdminDataState)
    
    const resetLpaBackupsDetailsState = useResetRecoilState(lpaBackupsDetailsState)
    const resetLpaBackupsDataState = useResetRecoilState(lpaBackupDataState)

    const resetLpaFrontlineDetailsState = useResetRecoilState(lpaFrontlineDetailsState)
    const resetlpaFrontlineDataState = useResetRecoilState(lpaFrontlineDataState)
    
    const resetLpaSecurityDetailsState = useResetRecoilState(lpaSecurityDetailsState)
    const resetLpaSecurityDataState = useResetRecoilState(lpaSecurityDataState)
    
    const resetLpaReceivingDetailsState = useResetRecoilState(lpaReceivingDetailsState)
    const resetLpaReceivingDataState = useResetRecoilState(lpaReceivingDataState)
    
    const resetLpaSalesFloorDetailsState = useResetRecoilState(lpaSalesFloorDetailsState)
    const resetLpaSalesFloorDataState = useResetRecoilState(lpaSalesFloorDataState)
    
    const resetLpaCashOfficeDetailsState= useResetRecoilState(lpaCashOfficeDetailsState)
    const resetLpaCashOfficeDataState = useResetRecoilState(lpaCashOfficeDataState)

    
    useEffect(() => {
        // Remove persisted data from local storage when the component mounts
        localStorage.removeItem('auditCodeAtom');
        
        localStorage.removeItem('lpaAdminDataState');
        localStorage.removeItem('lpaAdminDetailsState');

        localStorage.removeItem('lpaBackupDataState');
        localStorage.removeItem('lpaBackupsDetailsState');

        localStorage.removeItem('lpaCashOfficeDataState');
        localStorage.removeItem('lpaCashOfficeDetailsState');

        localStorage.removeItem('lpaFrontlineDataState');
        localStorage.removeItem('lpaFrontlineDetailsState');

        localStorage.removeItem('lpaReceivingDataState');
        localStorage.removeItem('lpaReceivingDetailsState');

        localStorage.removeItem('lpaSalesFloorDataState');
        localStorage.removeItem('lpaSalesFloorDetailsState');

        localStorage.removeItem('lpaSecurityDataState');
        localStorage.removeItem('lpaSecurityDetailsState');
      }, []);

    const combinedQuestions = {
        LPAQuestions: [
            ...lpaAdminQuestion.LPAQuestions, 
            ...lpaBackupsQuestion.LPAQuestions,
            ...lpaFrontlineQuestions.LPAQuestions,
            ...lpaSecurityQuestions.LPAQuestions,
            ...lpaReceivingQuestions.LPAQuestions,
            ...lpaSalesFloorQuestions.LPAQuestions,
            ...lpaCashOfficeQuestions.LPAQuestions
        ],
    };

    const combinedData = [
        ...lpaAdminData.responses.map((response, index) => ({
          quesId: lpaAdminData.quesId[index],
          question: combinedQuestions.LPAQuestions.find(question => question.quesId === lpaAdminData.quesId[index])?.questions || "",
          responses: response,
          scores: lpaAdminData.scores[index],
        })),
        ...lpaBackupsData.responses.map((response, index) => ({
          quesId: lpaBackupsData.quesId[index],
          question: combinedQuestions.LPAQuestions.find(question => question.quesId === lpaBackupsData.quesId[index])?.questions || "",
          responses: response,
          scores: lpaBackupsData.scores[index],
        })),
        ...lpaFrontlineData.responses.map((response, index) => ({
            quesId: lpaFrontlineData.quesId[index],
            question: combinedQuestions.LPAQuestions.find(question => question.quesId === lpaFrontlineData.quesId[index])?.questions || "",
            responses: response,
            scores: lpaFrontlineData.scores[index],
          })),
        ...lpaSecurityData.responses.map((response, index) => ({
            quesId: lpaSecurityData.quesId[index],
            question: combinedQuestions.LPAQuestions.find(question => question.quesId === lpaSecurityData.quesId[index])?.questions || "",
            responses: response,
            scores: lpaSecurityData.scores[index],
          })),
        ...lpaReceivingData.responses.map((response, index) => ({
            quesId: lpaReceivingData.quesId[index],
            question: combinedQuestions.LPAQuestions.find(question => question.quesId === lpaReceivingData.quesId[index])?.questions || "",
            responses: response,
            scores: lpaReceivingData.scores[index],
          })),
        ...lpaSalesFloorData.responses.map((response, index) => ({
            quesId: lpaSalesFloorData.quesId[index],
            question: combinedQuestions.LPAQuestions.find(question => question.quesId === lpaSalesFloorData.quesId[index])?.questions || "",
            responses: response,
            scores: lpaSalesFloorData.scores[index],
          })),
        ...lpaCashOfficeData.responses.map((response, index) => ({
            quesId: lpaCashOfficeData.quesId[index],
            question: combinedQuestions.LPAQuestions.find(question => question.quesId === lpaCashOfficeData.quesId[index])?.questions || "",
            responses: response,
            scores: lpaCashOfficeData.scores[index],
          })),
      ];
      
    const filteredDataDetails = combinedData.filter(details => details.scores === 'Fail');
    console.log("filteredDataDetails: ",filteredDataDetails)
    const someLength = filteredDataDetails.length;

    const initialData = {
      creationDate: "",
      responses: "",
      actions: Array(someLength).fill(""), // Initialize with empty strings
      responsPerson: Array(someLength).fill(""),
      dueDate: Array(someLength).fill(""),
    };

    const [findingsData, setFindingsData] = useState(initialData);
   
    useEffect(() => {
      // Check if all actions are filled
      const areActionsFilled =  findingsData.actions.every(action => action.trim() !== "" || 0) && 
                                findingsData.responsPerson.every(action => action.trim() !== "" || 0) &&
                                findingsData.dueDate.every(action => action.trim() !== "" || 0);
      setIsAllActionsFilled(areActionsFilled);
    }, [findingsData.actions, findingsData.responsPerson, findingsData.dueDate]);

    console.log("Data Actions: ", findingsData.actions)
            
   

    const clearState = () => {
        resetAuditCode()
        resetAuditSite()

        resetLpaAdminDetailsState();
        resetLpaAdminDataState()

        resetLpaBackupsDetailsState();
        resetLpaBackupsDataState();

        resetLpaCashOfficeDetailsState();
        resetLpaCashOfficeDataState();

        resetLpaFrontlineDetailsState();
        resetlpaFrontlineDataState();

        resetLpaReceivingDetailsState();
        resetLpaReceivingDataState();

        resetLpaSalesFloorDetailsState();
        resetLpaSalesFloorDataState();

        resetLpaSecurityDetailsState();
        resetLpaSecurityDataState();
    }

    const site = auditSite.site;
console.log('site-----------', site)

    const handleSubmit=(e)=>{
        e.preventDefault();
        
        const dateTime = new Date().toLocaleString('en-US', {
            weekday: 'long',
            month: 'long',
            day: 'numeric',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
        });

        const combinedQuestions = 
        filteredDataDetails
            .map((details) => (details.question));

        const combinedResponses = 
        filteredDataDetails
            .map((details) => (details.responses ));

                    
        const auditCode = auditCodeValue.auditCode;
        const user = auditHeaderData[0]?.user;
        const site = auditSite.site;
        const longitude = auditHeaderData[0]?.longitude;
        const latitude = auditHeaderData[0]?.latitude;

        const sendData={
            auditCode: auditCode,
            creationDate: dateTime,
            questions: combinedQuestions,
            responses: combinedResponses,
            actions: findingsData.actions,
            responsPerson: findingsData.responsPerson,
            dueDate: findingsData.dueDate,
        }

        const sendAuditLogs={
            auditCode: auditCode,
            completionDate: dateTime,
            user: user,
            site: site,
            longitude: longitude,
            latitude: latitude,
        }
            console.log('sendData-----------', sendData)
            console.log('sendAuditLogs----------',sendAuditLogs )
            axios.post("https://worxit.co.za/api/zamatrack/create/findingLogs.php",sendData)
            axios.post("https://worxit.co.za/api/zamatrack/create/auditLogs.php",sendAuditLogs)

            const date = new Date().toLocaleString('en-US', {
                weekday: 'long',
                day: 'numeric',
                month: 'long',
                year: 'numeric',
              });
    
              setAuditCodeValue((prevLpaData) => ({
                ...prevLpaData,
                date,
              }));
    
              const adminQuestions = lpaAdminQuestion.LPAQuestions.map((questionObj) => questionObj.quesId);
              const backupQuestions = lpaBackupsQuestion.LPAQuestions.map((questionObj) => questionObj.quesId);
              const frontlineQuestions = lpaFrontlineQuestions.LPAQuestions.map((questionObj) => questionObj.quesId);
              const securityQuestions = lpaSecurityQuestions.LPAQuestions.map((questionObj) => questionObj.quesId);
              const receivingQuestions = lpaReceivingQuestions.LPAQuestions.map((questionObj) => questionObj.quesId);
              const salesFloorQuestions = lpaSalesFloorQuestions.LPAQuestions.map((questionObj) => questionObj.quesId);
              const cashOfficeQuestions = lpaCashOfficeQuestions.LPAQuestions.map((questionObj) => questionObj.quesId);
          
                const promises = [
                  // axios.post("https://worxit.co.za/api/zamatrack/create/sectionAData.php", {
                  //   auditCode: lpaAdminData.auditCode,
                  //   section: lpaAdminData.section,
                  //   quesId: adminQuestions,
                  //   responses: lpaAdminData.responses,
                  //   notes: lpaAdminData.notes,
                  //   scores: lpaAdminData.scores,
                  //   totalScore: lpaAdminData.totalScore
                  // }),
                  axios.post("https://worxit.co.za/api/zamatrack/create/sectionBData.php", {
                  auditCode: lpaBackupsData.auditCode,
                  section: lpaBackupsData.section,
                  quesId: backupQuestions,
                  responses: lpaBackupsData.responses,
                  notes: lpaBackupsData.notes,
                  scores: lpaBackupsData.scores,
                  totalScore: lpaBackupsData.totalScore
                }),
                axios.post("https://worxit.co.za/api/zamatrack/create/sectionCData.php", {
                  auditCode: lpaFrontlineData.auditCode,
                  section: lpaFrontlineData.section,
                  quesId: frontlineQuestions,
                  responses: lpaFrontlineData.responses,
                  notes: lpaFrontlineData.notes,
                  scores: lpaFrontlineData.scores,
                  totalScore: lpaFrontlineData.totalScore
                }),
                axios.post("https://worxit.co.za/api/zamatrack/create/sectionDData.php", {
                  auditCode: lpaSecurityData.auditCode,
                  section: lpaSecurityData.section,
                  quesId: securityQuestions,
                  responses: lpaSecurityData.responses,
                  notes: lpaSecurityData.notes,
                  scores: lpaSecurityData.scores,
                  totalScore: lpaSecurityData.totalScore
                }),
                // axios.post("https://worxit.co.za/api/zamatrack/create/sectionEData.php", {
                //   auditCode: lpaReceivingData.auditCode,
                //   section: lpaReceivingData.section,
                //   quesId: receivingQuestions,
                //   responses: lpaReceivingData.responses,
                //   notes: lpaReceivingData.notes,
                //   scores: lpaReceivingData.scores,
                //   totalScore: lpaReceivingData.totalScore
                // }),
                // axios.post("https://worxit.co.za/api/zamatrack/create/sectionFData.php", {
                //   auditCode: lpaSalesFloorData.auditCode,
                //   section: lpaSalesFloorData.section,
                //   quesId: salesFloorQuestions,
                //   responses: lpaSalesFloorData.responses,
                //   notes: lpaSalesFloorData.notes,
                //   scores: lpaSalesFloorData.scores,
                //   totalScore: lpaSalesFloorData.totalScore
                // }),
                axios.post("https://worxit.co.za/api/zamatrack/create/sectionGData.php", {
                  auditCode: lpaCashOfficeData.auditCode,
                  section: lpaCashOfficeData.section,
                  quesId: cashOfficeQuestions,
                  responses: lpaCashOfficeData.responses,
                  notes: lpaCashOfficeData.notes,
                  scores: lpaCashOfficeData.scores,
                  totalScore: lpaCashOfficeData.totalScore
                })
                ];
                Promise.all(promises)
            .then((res)=> {
                //setMessage(res.data.message);   
                setErrorMessage("");
                setFindingsData({responses:"",actions:"",responsPerson:"",dueDate:""})   
                navigate('/storeAudit')
                clearState();
            })
            .catch((err) => {
                console.error(err);
                setErrorMessage("An error occurred.");
                setMessage("");
            });
    }
   
    return (
      <div className="findingLogsContainer">
           <div className='titleHeader'>
                  <h4 className='titleh4'>Alert</h4>
              </div>
         <table className='lpaStoreAnalysisTbl'>
              <thead className='lpaStoreAnalysisHeader'>
                  <tr className='lpaStoreAnalysisHeaderRow'>
                      <th className="typeColWidth">Type</th>
                      <th className="responsesColWidth">Responses</th>
                      <th className="actionColWidth">Action</th>
                      <th className="responPersColWidth">Responsible Person</th>
                      <th className="dueDateColWidth">Due Date</th>
                  </tr>
              </thead>
              <tbody className='lpaStoreAnalysisBody'>
              {filteredDataDetails
                  .map((details, index) => (
                  <tr className='lpaStoreAnalysisBodyRow' key={index}>
                      <td className="typeColWidth">
                      <div className='failCircle'></div>
                      </td>
                      <td className="responsesColWidth">
                      <div>{details.question} - {details.responses}</div>
                      </td>
                      <td className="actionColWidth">
                          <input
                              type= "text"
                              name="actions"
                              variant="outlined"
                              required
                              value={findingsData.actions[index]}
                              onChange={(e) => {
                                  const newActions = [...findingsData.actions]; // Create a copy of the actions array
                                  newActions[index] = e.target.value; // Update the value at the current index
                                  setFindingsData({ ...findingsData, actions: newActions }); // Update the state with the new array
                              }}
              />
                      </td>
                      <td className="responPersColWidth">
                          <input
                              type= "text"
                              name="responsPerson"
                              variant="outlined"
                              required
                              value={findingsData.responsPerson[index]}
                              onChange={(e) => {
                                  const newResponsPerson = [...findingsData.responsPerson]; // Create a copy of the actions array
                                  newResponsPerson[index] = e.target.value; // Update the value at the current index
                                  setFindingsData({ ...findingsData, responsPerson: newResponsPerson }); // Update the state with the new array
                              }}					
                          />
                      </td>
                      <td className="dueDateColWidth">
                      <input
                              type= "date"
                              name="dueDate"
                              variant="outlined"
                              required
                              value={findingsData.dueDate[index]}
                              onChange={(e) => {
                                  const newDueDates = [...findingsData.dueDate]; // Create a copy of the dueDate array
                                  newDueDates[index] = e.target.value; // Update the value at the current index
                                  setFindingsData({ ...findingsData, dueDate: newDueDates }); // Update the state with the new array
                              }}				
                          />
                      </td>
                  </tr>
              ))}
              </tbody>
          </table>
          <div className='submitAuditContainer'>
            <button className={`auditBtn ${!isAllActionsFilled ? 'disabled' : ''}`} onClick={handleSubmit} disabled={!isAllActionsFilled}>Submit Audit for Completion</button>
          </div>
      </div>
    );
  };
  
  export default FindingsLogs;