import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import {
  Container,
  Button,
  Grid,
  Paper,
} from "@mui/material";
import './EditDispatchSite.css'

const EditDispatchSite = () => {
  const navigate = useNavigate();
  const { dispatchSiteNo } = useParams();

  const [message, setMessage] = useState("");
  const [data, setData] = useState({
    address: "",
    dispatchSiteNo: ""
  })

  useEffect(()=>{
    showDispatchSites();

// eslint-disable-next-line react-hooks/exhaustive-deps
},[]);

  const { address} = data

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  }

  const handleUpdate =  async (e)=>{
    e.preventDefault();

   
    await axios.post("https://worxit.co.za/api/zamatrack/update/updateDispatchSite.php", data)
      .then((res) => {
        if(res.data.status ==='valid'){
          setMessage("Successfully Updated");
          navigate(-1)
      }
      })
      .catch((err) => {
        console.error(err);
        setMessage("An error occurred.");
      });

  }

  const showDispatchSites = async () =>{
    const result = await axios.get("https://worxit.co.za/api/zamatrack/edit/editDispatchSite.php?dispatchSiteNo="+dispatchSiteNo);
    setData(result.data);
    
}

  return (
    <div className="createCompany-form" >
      <Container maxWidth="sm" >
        <Grid
          container
          spacing={2}
          direction="column"
          justifyContent="center"
          style={{ minHeight: "60vh", }}
        >
          <Paper elelvation={2} sx={{ padding: 5 }}>
            <form onSubmit={handleUpdate}>
              <Grid container direction="column" spacing={2}>
                <h4 className="regTitle">Edit Dispatch Sites</h4>
                <Grid item>
                  <label>Dispatch Site No</label>
                  <input
                    type="text"
                    name="dispatchSiteNo"
                    placeholder="Dispatch Site No"
                    variant="outlined"
                    required
                    value={dispatchSiteNo}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item>
                <label>Address</label>
                  <input
                    type="text"
                    name="address"
                    placeholder="Address"
                    variant="outlined"
                    required
                    value={address}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item>
                  <Button type="submit" fullWidth variant="contained">
                    Update Dispatch Sites
                  </Button>
                  {message && <p className="errorMsg">{message}</p>}
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Grid>

      </Container>
    </div>
  );
};

export default EditDispatchSite;
