import React, { useState } from "react";
import axios from "axios";
import {
	Container,
	Button,
	Grid,
	Paper,
} from "@mui/material";
import {  useNavigate } from "react-router-dom";
import './CreateDispatchSite.css'

const CreateDispatchSite = () => {
  const navigate = useNavigate();

  const [message, setMessage] = useState("");
  const [data, setData] = useState({
   dispatchSiteNo: "",
   address: ""
  })

  const handleChange = (e) => {
    setData({...data, [e.target.name]: e.target.value});
    
 }
  const handleSubmit=(e)=>{
    e.preventDefault();

   const sendData={
    dispatchSiteNo:data.dispatchSiteNo,
    address:data.address,
    }
    console.log(sendData)
    axios.post("https://worxit.co.za/api/zamatrack/create/createDispatchSite.php",sendData)
    .then((res)=> {
      setMessage(res.data.message);   
      setData({dispatchSiteNo:"", address:""})    
    })
    .catch((err) => {
      console.error(err);
      setMessage("An error occurred.");
    });
  }

  return (
    <div className="dispatch-form" >
		<Container maxWidth="sm" >
		<Grid
		container
		spacing={2}
		direction="column"
		justifyContent="center"
		style={{ minHeight: "60vh", }}
	>
	<Paper elelvation={2} sx={{ padding: 5 }}>
	<form  onSubmit={handleSubmit}>
	<Grid container direction="column" spacing={2}>
  <h4 className="regTitle">Create Dispatch Site</h4>
		<Grid item>
			<input
				type="text"
        name="dispatchSiteNo"
				placeholder="Dispatch Site No"
				variant="outlined"
        required
        value={data.dispatchSiteNo}
        onChange={handleChange}
			/>
		</Grid>

		<Grid item>
		<input
			type= "text"
      name="address"
			placeholder="Address"
			variant="outlined"
			required
      value={data.address}
			onChange={handleChange}
			
		/>
		</Grid>
   
    {/* <Grid item>
      <select value={data.role} onChange={(e) => setData({ ...data, role: e.target.value })}>
        <option value="Dispatcher">Dispatcher</option>
        <option value="Driver">Driver</option>
      </select>
    </Grid> */}
		<Grid item>
        <Button type="submit" fullWidth variant="contained">
			Create Dispatch Site
		</Button>
    {message && <p className="errorMsg">{message}</p>}
		</Grid>
	</Grid>
	</form>
	</Paper>
	</Grid>
	
	</Container>
			</div> 
  );
};

export default CreateDispatchSite;
