import React, { useState,useEffect } from "react";
import axios from "axios";
import {
	Container,
	Button,
	Grid,
	Paper,
} from "@mui/material";
import {  useNavigate } from "react-router-dom";
import './CreateMobileUsers.css'

const CreateMobileUsers = () => {
  const [message, setMessage] = useState("");
  const [data, setData] = useState({
    username: "",
    password:"",
    confirmPassword:"",
    role:"Select Role",
    coID:"",
  })

  const handleChange = (e) => {
    setData({...data, [e.target.name]: e.target.value});
    
 }
  const handleSubmit=(e)=>{
    e.preventDefault();

    if (data.password !== data.confirmPassword) {
      setMessage("Passwords do not match.");
    return;
    }

   const sendData={
      username:data.username,
      password:data.password,
      confirmPassword:data.confirmPassword,
      role:data.role,
      coID:data.coID
    }
    console.log(sendData)
    axios.post("https://worxit.co.za/api/zamatrack/create/createMobileUsers.php",sendData)
    .then((res)=> {
      setMessage(res.data.message);
      setData({username:"", password:"",confirmPassword:"",role:"Select Role", coID:""})
    })
    .catch((err) => {
      console.error(err);
      setMessage("An error occurred.");
    });
  }

  return (
    <div className="createMobUser" >
		<Container maxWidth="sm" >
		<Grid
		container
		spacing={2}
		direction="column"
		justifyContent="center"
		style={{ minHeight: "60vh", }}
	>
	<Paper elelvation={2} sx={{ padding: 5 }}>
	<form  onSubmit={handleSubmit}>
	<Grid container direction="column" spacing={2}>
  <h4 className="regTitle">Create Mobile User</h4>
		<Grid item>
			<input
				type="text"
        name="username"
				placeholder="Username"
				variant="outlined"
        required
        value={data.username}
        onChange={handleChange}
			/>
		</Grid>

		<Grid item>
		<input
			type= "password"
      name="password"
			placeholder="Password"
			variant="outlined"
			required
      value={data.password}
			onChange={handleChange}
			
		/>
		</Grid>

    <Grid item>
      <input
        type= "password"
        name="confirmPassword"
        placeholder="Confirm Password"
        variant="outlined"
        required
        value={data.confirmPassword}
        onChange={handleChange}
        
      />
		</Grid>
    <Grid item>
      <input
        type= "text"
        name="coID"
        placeholder="Company ID"
        variant="outlined"
        required
        value={data.coID}
        onChange={handleChange}
      />
		</Grid>
   
    <Grid item>
      <select value={data.role} onChange={(e) => setData({ ...data, role: e.target.value })}>
        <option>Select Role</option>
        <option value="Dispatcher">Dispatcher</option>
        <option value="Receiver">Receiver</option>
      </select>
    </Grid>
		<Grid item>
        <Button type="submit" fullWidth variant="contained">
			Create User
		</Button>
    {message && <p className="errorMsg">{message}</p>}
		</Grid>
	</Grid>
	</form>
	</Paper>
	</Grid>
	
	</Container>
			</div> 
  );
};

export default CreateMobileUsers;
