import React, {useEffect, useState} from "react";
import axios from "axios";
import {  useParams,useNavigate } from "react-router-dom";
import { Document, Page } from "react-pdf";
import jsPDF from 'jspdf'
import 'jspdf-autotable';
import './exportToPdf.css'
import logo from '../../../../media/WorxItLogo.png';
import domtoimage from 'dom-to-image';
import signatureIcons from '../../../../media/signatureIcons.png';
import imageIcon from '../../../../media/imageIcon.png';

const ExportToPDF = ({mapRef}) => {

  const { orderNo } = useParams();  
  const [showDispatchTbl, setShowDispatchTbl] = useState([]);
  const [showDispatchItemsTbl, setShowDispatchItemsTbl] = useState([]);
  const [showDeliveriesTbl, setShowDeliveriesTbl] = useState([]);
  const [showDeliveriesItemsTbl, setShowDeliveriesItemsTbl] = useState([]);
  const [pdfOpen, setPdfOpen] = useState(false);
  const [file, setFile] = useState(null);

  useEffect(() => {
    dispatchTbl();
    dispatchItemsTbl();
    deliveriesTbl();
    deliveriesItemsTbl();
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, []);

  const dispatchTbl = async () => {
    console.log(orderNo);
    const result = await axios.get(
      "https://worxit.co.za/api/zamatrack/dispatchShowOrderDetails.php?orderNo=" +
        orderNo
    );
    setShowDispatchTbl(result.data.records);
  };

  const dispatchItemsTbl = async () => {
    console.log(orderNo);
    const result = await axios.get(
      "https://worxit.co.za/api/zamatrack/dispatchItemsTable.php?orderNo=" +
        orderNo
    );
    setShowDispatchItemsTbl(result.data.records);
    console.log(result)
  };

  const deliveriesTbl = async () => {
    console.log(orderNo);
    const result = await axios.get(
      "https://worxit.co.za/api/zamatrack/dispatchShowDelDetails.php?orderNo=" +
        orderNo
    );
    setShowDeliveriesTbl(result.data.records);
  };

  const deliveriesItemsTbl = async () => {
    console.log(orderNo);
    const result = await axios.get(
      "https://worxit.co.za/api/zamatrack/deliveriesItemsTable.php?orderNo=" +
        orderNo
    );
    setShowDeliveriesItemsTbl(result.data.records);
  };
     

  //------------------COMBINED TABLES-----------------

  //------------------DISPATCH TABLE---------------
  const combinedTblPdfExport = async () => {
    const pdf = new jsPDF('p', 'mm', 'a4');

   
    //------Image------
  pdf.addImage(logo, "PNG", 10, 3, 20, 20);

  //-----Report Date------
  const reportDate = new Date().toLocaleDateString();
  pdf.setFontSize(10);
  pdf.text(`Report Date: ${reportDate}`, 155, 10);

  //------Title------
  pdf.setFontSize(12);
  pdf.setFont('helvetica', 'bold');
  pdf.text(65, 20, 'Proof of Dispatch and Deliveries Report');

  pdf.setFont('helvetica', 'normal');
  pdf.text(15, 30, 'Dispatch Details');

   
      //-------Data-----
      const data = 
      showDispatchTbl.map((details) => [
        details.orderNo,
        details.dateTime,
        details.dispatchSiteNo, 
        details.deliveryAddNo,
        details.dispatcherName, 
        details.guardsName,
        details.doorNo,
        details.sealNo,
        details.vehicleReg, 
        details.vehicleMake, 
      ])
  
      const guardsSignatureDis = showDispatchTbl.map((details) => details.signGuard)[0];
      const disSignature = showDispatchTbl.map((details) => details.signDis)[0];
      const imageUriAll = showDispatchTbl.map((details) => details.imageUriAll)[0];
      const images = showDispatchItemsTbl.map((details) => details.images);

        const mapContainer = mapRef.current;
        const canvas = await domtoimage.toPng(mapContainer.getContainer());

        const tableData = [['Order No', ...data.map((row) => row[0])], 
        ['Date Time', ...data.map((row) => row[1])],
        ['Dispatch Site No', ...data.map((row) => row[2])],
        ['Delivery Add No', ...data.map((row) => row[3])],
        ['dispatcher Name', ...data.map((row) => row[4])],
        ['Signature'],
        ['Guards Name', ...data.map((row) => row[5])],
        ['Signature'],
        ['Door No', ...data.map((row) => row[6])],
        ['Seal No', ...data.map((row) => row[7])],
        ['Vehicle Reg', ...data.map((row) => row[8])],
        ['Vehicle make', ...data.map((row) => row[9])],
        ['Read view of load'],
        ['Map View']
      ];
  
        pdf.autoTable({
          body: tableData,
          // theme: 'grid',
          startY: 35,
          alternateRowStyles: { 
                  fillColor: [208, 216, 223],
                },
                
          didParseCell: function (data) {
            // check if the current cell is the one to add an image to
            if (data.row.index === 5 && data.column.index === 1) {
                // increase the height of the cell
              data.row.height = 25;
            }
            if (data.row.index === 7 && data.column.index === 1) {
              // increase the height of the cell
              data.row.height = 25;
            }
            if (data.row.index === 12 && data.column.index === 1) {
              // increase the height of the cell
              data.row.height = 25;
            }
            if (data.row.index === 13 && data.column.index === 1) {
              // increase the height of the cell
              data.row.height = 62;
            }
          },
          didDrawCell: (data) => {
            // check if the current cell is the one to add an image to
            if (data.row.index === 5 && data.column.index === 1) {
              const disSignatureIconLink = (signatureIcons); // Replace with the path to your video icon/image
             const iconSize = 12; // Adjust the size of the icon as needed
           
            const disSignatureLink = { url: disSignature }; // Replace 'video' with the actual video link
           
             pdf.link(
              data.cell.x + 5,
              data.cell.y +4,
              iconSize,
              iconSize,
              disSignatureLink
             )

              pdf.addImage(disSignatureIconLink, 'PNG', data.cell.x + 5, data.cell.y +4 , iconSize, iconSize);
            
            }
            if (data.row.index === 7 && data.column.index === 1) {
              const guardSignatureIconLink = (signatureIcons); // Replace with the path to your video icon/image
              const iconSize = 12; // Adjust the size of the icon as needed
            
             const guardsSignatureLink = { url: guardsSignatureDis }; // Replace 'video' with the actual video link
            
              pdf.link(
                data.cell.x + 5,
                data.cell.y +4 ,
               iconSize,
               iconSize,
               guardsSignatureLink
              )
 
               pdf.addImage(guardSignatureIconLink, 'PNG', data.cell.x + 5, data.cell.y +4 , iconSize, iconSize);
             
            }
            if (data.row.index === 12 && data.column.index === 1) {
                const imageAllIconLink = (imageIcon); // Replace with the path to your video icon/image
                const iconSize = 12; // Adjust the size of the icon as needed
              
              const imageAllLink = { url: imageUriAll }; // Replace 'video' with the actual video link
              
                pdf.link(
                  data.cell.x + 5,
                  data.cell.y +4 ,
                iconSize,
                iconSize,
                imageAllLink
                )
  
                pdf.addImage(imageAllIconLink, 'PNG', data.cell.x + 5, data.cell.y +4 , iconSize, iconSize);
             
            }
            if (data.row.index === 13 && data.column.index === 1) {
              // add the image to the cell
              pdf.addImage(canvas, 'PNG', data.cell.x + 2, data.cell.y + 4,55, 55);
            }
          }
        });
    
      
        //----------------DISPATCH ITEMS TABLE------------------

        pdf.addPage();
        pdf.text(15,  10  , 'Dispatch Items Details');
        const tableDataDis = [
          ['Quantity', 'Barcode', 'Description', 'Pallet No','Images'], 
          ...showDispatchItemsTbl.map(details => [details.qtyNo, details.barcode, details.description || "No Desciption Found", details.palletNoDis ]),
        ];
        
        const firstRow = showDispatchItemsTbl[0];
      
        const headerRow = tableDataDis.shift();
      
      
        pdf.autoTable({
          head: [headerRow],
          body: [firstRow, ...tableDataDis],
          startY: 15,
          alternateRowStyles: {fillColor: [208, 216, 223]},
          columnStyles: {
            0:{cellWidth:20},
            1:{cellWidth: 35},
            2: {cellWidth: 45}, 
            3: {cellWidth: 40},
            4: {cellWidth:45}
          },
          didParseCell: function (data) {
            if (data.row.index >= 1 && data.column.index === 4) {
              data.row.height = 14;
            }
          },
          didDrawCell: (data) => {
            if (data.column.index === 4 && data.row.index >= 1) {
              const iconSize = 12; // Adjust the size of the icon as needed
              const image = images[data.row.index - 1];
              const imageIconLink = (imageIcon); // Replace with the path to your image icon

              const imageLink = { url: image };
              pdf.link(data.cell.x, data.cell.y + 2, iconSize, iconSize, imageLink);
              pdf.addImage(imageIconLink, data.cell.x, data.cell.y + 2, iconSize, iconSize);
            }
          },
             // Set margin to 20mm on all sides
          margin: {  bottom: 20 },
          });

  
    //----------------DELIVERIES TABLE------------------

  pdf.addPage();
  pdf.text(15,  10, 'Deliveries Details');
  //-------Data-----
  const dataDel = 
  showDeliveriesTbl.map((details) => [
    details.orderNo,
    details.dateTimeRec,
    details.sealInRec,
    details.sealOutRec,
    details.receiversName,
    details.guardsName,
  ])

    const recSignature = showDeliveriesTbl.map((details) => details.signRec)[0];
    const guardsSignature = showDeliveriesTbl.map((details) => details.signGuard)[0];
    const imageUriAllRec = showDeliveriesTbl.map((details) => details.imageUriAll)[0];
    const imagesRec = showDispatchItemsTbl.map((details) => details.images);


    const tableDataDel = [['Order No', ...dataDel.map((row) => row[0])], 
    ['Date Time', ...dataDel.map((row) => row[1])],
    ['Seal In', ...dataDel.map((row) => row[2])],
    ['Seal Out', ...dataDel.map((row) => row[3])],
    ['Receivers Name', ...dataDel.map((row) => row[4])],
    ['Signature'],
    ['Guards Name', ...dataDel.map((row) => row[5])],
    ['Signature'],
    ['Rear view of load'],
    ['Map View']
  ];

    pdf.autoTable({
      body: tableDataDel,
      //theme: 'grid',
      startY: 15,
      alternateRowStyles: { 
              fillColor: [208, 216, 223],
            },
            
      didParseCell: function (data) {
        // check if the current cell is the one to add an image to
        if (data.row.index === 5 && data.column.index === 1) {
            // increase the height of the cell
          data.row.height = 25;
        }
        if (data.row.index === 7 && data.column.index === 1) {
          // increase the height of the cell
          data.row.height = 25;
        }
        if (data.row.index === 8 && data.column.index === 1) {
          // increase the height of the cell
          data.row.height = 25;
        }
        if (data.row.index === 9 && data.column.index === 1) {
          // increase the height of the cell
          data.row.height = 62;
        }
      },
      didDrawCell: (data) => {
        // check if the current cell is the one to add an image to
        if (data.row.index === 5 && data.column.index === 1) {
          const recSignatureIconLink = (signatureIcons); // Replace with the path to your video icon/image
          const iconSize = 12; // Adjust the size of the icon as needed
        
         const recSignatureLink = { url: recSignature }; // Replace 'video' with the actual video link
        
          pdf.link(
           data.cell.x + 5,
           data.cell.y +4,
           iconSize,
           iconSize,
           recSignatureLink
          )

           pdf.addImage(recSignatureIconLink, 'PNG', data.cell.x + 5, data.cell.y +4 , iconSize, iconSize);
         
        }
        if (data.row.index === 7 && data.column.index === 1) {
          const guardsSignatureIconLink = (signatureIcons); // Replace with the path to your video icon/image
          const iconSize = 12; // Adjust the size of the icon as needed
        
         const guardsSignatureLink = { url: guardsSignature }; // Replace 'video' with the actual video link
        
          pdf.link(
           data.cell.x + 5,
           data.cell.y +4,
           iconSize,
           iconSize,
           guardsSignatureLink
          )

           pdf.addImage(guardsSignatureIconLink, 'PNG', data.cell.x + 5, data.cell.y +4 , iconSize, iconSize);
         
        }
        if (data.row.index === 8 && data.column.index === 1) {
          const imageAllIconLink = (imageIcon); // Replace with the path to your video icon/image
          const iconSize = 12; // Adjust the size of the icon as needed
        
         const imageAllLink = { url: imageUriAllRec }; // Replace 'video' with the actual video link
        
          pdf.link(
            data.cell.x + 5,
            data.cell.y +4 ,
           iconSize,
           iconSize,
           imageAllLink
          )

           pdf.addImage(imageAllIconLink, 'PNG', data.cell.x + 5, data.cell.y +4 , iconSize, iconSize);
         
        }
        if (data.row.index === 9 && data.column.index === 1) {
          // add the image to the cell
          pdf.addImage(canvas, 'PNG', data.cell.x + 2, data.cell.y + 4,55, 55);
        }
      },
     
          
      });

    //--------------------DELIVERIES ITEMS TABLE-------------------------------

    pdf.addPage();
    pdf.text(15,  10, 'Deliveries Item Details');
    const tableDataDelItms = [
      ['Quantity', 'Barcode', 'Description', 'Pallet No', 'Images'], 
      ...showDeliveriesItemsTbl.map(details => [details.qtyNo, details.barcode, details.description || "No Desciption Found", details.palletNoRec ]),
    ];
    
    const firstRowDelItms = showDeliveriesItemsTbl[0];
  
    const headerRowDelItms = tableDataDelItms.shift();
  
  
    pdf.autoTable({
      head: [headerRowDelItms],
      body: [firstRowDelItms, ...tableDataDelItms],
      startY: 15,
      alternateRowStyles: {fillColor: [208, 216, 223]},
      columnStyles: {
        0:{cellWidth:20},
        1:{cellWidth: 35},
        2: {cellWidth: 45}, 
        3: {cellWidth: 40},
        4: {cellWidth:45}
      },
      didParseCell: function (data) {
        if (data.row.index >= 1 && data.column.index === 4) {
          data.row.height = 14;
        }
      },
      didDrawCell: (data) => {
        if (data.column.index === 4 && data.row.index >= 1) {
          const iconSize = 12; // Adjust the size of the icon as needed
              const image = imagesRec[data.row.index - 1];
              const imageIconLink = (imageIcon); // Replace with the path to your image icon

              const imageLink = { url: image };
              pdf.link(data.cell.x, data.cell.y + 2, iconSize, iconSize, imageLink);
              pdf.addImage(imageIconLink, data.cell.x, data.cell.y + 2, iconSize, iconSize);
            }
      },
      margin: {  bottom: 20 },
    });

    pdf.save('Full Report.pdf');
      
    };

    return(
      <div>
        <div >
          <button className="exportPDFBtn" onClick={combinedTblPdfExport}>Export to PDF</button>
            {pdfOpen && (
              <Document
                file={file}
                  onLoadSuccess={() => setPdfOpen(false)}
              >
              <Page pageNumber={1} />
              </Document>
              )}
        </div>        
      </div>
    )
  }


export default ExportToPDF;