import React, {useEffect, useState} from "react";
import {  useParams,useNavigate } from "react-router-dom";
import axios from "axios";
import "./showDeliveryAddress.css";


function ShowDispatchAddress(){

    const { deliveryAddNo } = useParams();
    const [showAddressDetails, setShowAddressDetails] = useState({
        
          address: ""
        
    })
    const navigate = useNavigate();

    const dispatchNavigate=()=>{
        navigate('/dispatched')
    }

    useEffect(()=>{
        showAddress();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const { address } = showAddressDetails;

      const showAddress= async () =>{
        console.log(deliveryAddNo)
        const result = await axios
        .get("https://worxit.co.za/api/zamatrack/dispatchShowDeliveryAddress.php?deliveryAddNo="+deliveryAddNo );
        setShowAddressDetails(result.data);
        
    }  
    
      return(

        <div>
             <table>
          <thead>
            <tr>
              <th>Delivery Address</th> 
              <th>Address</th>
            </tr>
          </thead>
          <tbody>
            <tr>
                <td> {deliveryAddNo} </td> 
                <td>{address}</td>
              </tr>
          </tbody>
        </table>
        <button className="addressBackBtn" onClick={dispatchNavigate}>Back</button>
        </div>
   
    )
}

export default ShowDispatchAddress;