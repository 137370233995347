import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import "../../../styles/LpaStoreAnalysisStyles.css";
import { lpaFrontlineDetailsState } from '../../../components/RecoilStates/LpaFrontlineDetailsState';

const LpaFrontLineFeedback = () => {
  const { auditCode } = useParams();

  const lpaFrontlineDetails = useRecoilValue(lpaFrontlineDetailsState);

  if (!Array.isArray(lpaFrontlineDetails)) {
    return <div>Loading...</div>; // or return an empty component if desired
  }

  const filteredFrontlineDetails = lpaFrontlineDetails.filter(details => details.auditCode === auditCode);
  

  
    return (
        <div className='questionContainer'>
            <div className='titleHeader'>
                <h4 className='titleh4'>Section B</h4>
            </div>
            <table className='lpaStoreAnalysisTbl' >
                <thead className='lpaStoreAnalysisHeader'>
                    <tr className='lpaStoreAnalysisHeaderRow'>
                        {/* <th className="typeWidth">Type</th> */}
                        <th className="scoreTypeColumnWidth">Type</th>
                        <th className="questionsColumnWidth">LPA Questions</th>
                        <th className="answersColumnWidth" >Observation/Responses</th>
                        <th className="notesColumnWidth">Notes </th>
                    </tr>
                </thead>
                <tbody className='lpaStoreAnalysisBody'>
                    {filteredFrontlineDetails.map((question, index) => (
                        <tr className='lpaStoreAnalysisBodyRow' key={index}>
                        <td className="scoreTypeColumnWidth">
                            {question.scores === 'Pass' ? (
                                <div className='passCircle'></div>
                            ) : question.scores === 'Fail' ? (
                                <div className='failCircle'></div>
                            ) : (
                                <div className='naCircle'></div>
                            )}
                        </td>
                        <td className="questionsColumnWidth">{question.question}</td>
                        <td className="answersColumnWidth">{question.responses} </td>
                        <td className="notesColumnWidth">{question.notes} </td>
                        </tr>
                    ))}
                    </tbody>
            </table>  
            <div className='titleFooter'>
                {filteredFrontlineDetails.length > 0 && (
                    <h4 className='titleFooterH4'>Section B Score = {filteredFrontlineDetails[0].totalScore}%</h4>
                )}
            </div>
        </div>
   
    )
}

export default LpaFrontLineFeedback;