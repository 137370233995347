import React, { useEffect, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import LpaAdmin from '../../components/LPAStoreAnalysis/LPAStoreAnalysisAdmin/LpaAdmin';
import LpaBackups from '../../components/LPAStoreAnalysis/LPAStoreAnalysisBackups/LpaBackups';
import LpaCashOffice from '../../components/LPAStoreAnalysis/LPAStoreAnalysisCashOffice/LpaCashOffice';
import LpaFrontline from '../../components/LPAStoreAnalysis/LPAStoreAnalysisFrontline/LpaFrontLine';
import LpaReceiving from '../../components/LPAStoreAnalysis/LPAStoreAnalysisReceiving/LpaReceiving';
import LpaSalesFloor from '../../components/LPAStoreAnalysis/LPAStoreAnalysisSalesFloor/LpaSalesFloor';
import LpaSecurity from '../../components/LPAStoreAnalysis/LPAStoreAnalysisSecurity/LpaSecurity';
import { auditSiteAtom } from '../../components/RecoilStates/auditSiteAtom';
import {
	Container,
	Button,
	Grid,
	Paper,
	TextField,
	IconButton,
	InputAdornment,
} from "@mui/material";
import './AuditerPage.css';

const AuditerPage = () => {
  const setAuditSite = useSetRecoilState(auditSiteAtom)
  const [values, setValues] = useState({
		site: "",
	});

  const handleAddSiteToAtom = () => {
    setAuditSite(
      {
        site: values.site,
      },
    );
  };
  
  return (
    <div className="App">
      <div>
        <h2>New Audit</h2>
      </div>
      <div className='siteCont'>
        <div className='siteTitle'>
          <h5>Site: </h5>
        </div>
        <div className='siteTextField'>
          <TextField
            type="text"
            fullWidth
            label="Enter your Site Name/Number"
            placeholder="Site Name/Number"
            variant="outlined"
            required
            onChange={(e) => setValues({ ...values, site: e.target.value })}
          />
        </div>
        <div className='addSiteBtnContainer'>
          <button className='addSiteBtn' onClick={handleAddSiteToAtom}>Add Site</button>
        </div>
      </div> 
      
     

      {/* <LpaAdmin/>  */}
      <LpaBackups/> 
      <LpaFrontline/> 
        <LpaSecurity/>
      {/*<LpaReceiving/> 
       <LpaSalesFloor/> */}
      <LpaCashOffice/> 
     
    </div>
  );
}

export default AuditerPage;
