import React, {useState, useEffect,useMemo} from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import "./viewDispatch.css";
import {  useRecoilValue} from "recoil";
import { AtomDispatchState } from "../../components/RecoilStates/AtomDispatchState";


const ViewDispatch = () =>{

    const dispatchData = useRecoilValue(AtomDispatchState)
    // const [dispatch, setDispatch]= useState([]);
    const [loading, setLoading] = useState(false);
    const [orderNoSearch, setorderNoSearch] = useState("");
    const [dateTimeStart, setDateTimeStart] = useState(""); 
    const [dateTimeEnd, setDateTimeEnd] = useState("");
    const [message, setMessage] = useState("");
    
  
    
    //---------REFRESH PAGE AUTOMATICALLY----------
    

    // useEffect(() => {
    //     const fetchData = async () => {
    //     const response = await axios.get('https://worxit.co.za/api/zamatrack/dispatchView.php');
    //     const newData = response.data.records;
    //     setDispatch(newData);
    //   }; 
     
    //   fetchData();
    //   const intervalId = setInterval(fetchData, 3000);
    //   return () => clearInterval(intervalId);
    // }, []);

    const filteredDispatches = useMemo(() => {
      if (!Array.isArray(dispatchData)) return [];
      
      return dispatchData.filter((dispatch) => {
        if (orderNoSearch !== "" || dateTimeStart !== "") {
          if (
            dispatch.orderNo.includes(orderNoSearch) &&
            dispatch.dateTime.includes(dateTimeStart)
          ) {
            return dispatch;
          }
          return false;
        }
        return dispatch;
      });
    }, [dispatchData, orderNoSearch, dateTimeStart]);
  
    useEffect(() => {
      if (
        filteredDispatches.length === 0 &&
        (orderNoSearch !== "" || dateTimeStart !== "")
      ) {
        setMessage("No Match Found");
      } else {
        setMessage("");
      }
      

    }, [filteredDispatches, orderNoSearch, dateTimeStart]);
  

     //----------Pagination------------     
      const [rowsPerPage, setRowsPerPage] = useState(5);
      const usersPerPage = rowsPerPage;
      const [pageNumber, setPageNumber] = useState(0);
 
      const handleRowsPerPageChange = (event) => {
        setRowsPerPage(parseInt(event.target.value));
        setPageNumber(0); // Reset the page number to 0
      };


    return(
      <>
        <div style={{width: "100%"}}>
            <div className=" row filter" >
                <div className="column1">
                    <h5>Order Number:</h5>           
                </div>
                <div className="column2"> 
                    <input
                    className="searchInput"
                    type="text"
                    placeholder= "Search..."
                    onChange={(e) => setorderNoSearch(e.target.value)}/>  
                </div>
                <div className="column3">
                    <h5>Date:</h5>
                </div>
                    <div className="column4">
                        <input
                            className="searchInput"
                            type="date"
                            placeholder= "Search..."
                            onChange={(e) => setDateTimeStart(e.target.value)}/>
                    </div> 
                    
                    {/* <div className="column5">
                    <h5>Date To:</h5>
                </div>
                    <div className="column6">
                        <input
                            className="searchInput"
                            type="date"
                            placeholder= "Search..."
                            onChange={(e) => setDateTimeEnd(e.target.value)}/>    
                    </div>                       */}
        </div>
        <h6>Latest 5 Order Entries...</h6>
            <table>
                <thead>
                    <tr>
                        <th className="orderNoWidth">Order No</th>
                        <th className="dateTimeWidth">Date Time</th>
                        <th>Dispatch Site No</th>
                        <th className="delAddNoWidth">Delivery Address No </th>
                        <th>Dispatcher Name</th>
                        <th>Guards Name</th>
                        <th>Door</th>
                        <th>Vehicle Reg</th>
                        <th>Vehicle Make</th>
                        <th>Seal No</th>                    
                    </tr>
                </thead>
                <tbody>
                {loading ? (
                      <tr>
                        <td colSpan={11}>Loading...</td>
                      </tr>
                    ) : (        
                                       
                      filteredDispatches
                        .sort((a, b) => new Date(b.dateTime) - new Date(a.dateTime))
                        .slice(pageNumber * usersPerPage, (pageNumber + 1) * usersPerPage)
                        .map((dispatch, index) =>(
                            <tr key={index}>   
                                <td ><Link className="btn btn-success" to={`/showOrderDetails/${dispatch.orderNo}`}>
                                    {dispatch.orderNo}
                                    </Link>
                                </td> 
                                {/* <td>{new Date(dispatch.dateTime).toLocaleDateString()}</td>  */}
                                 <td>{dispatch.dateTime}</td> 
                                 <td ><Link className="btn btn-success" to={`/showDispatchAddress/${dispatch.dispatchSiteNo}`}>
                                    {dispatch.dispatchSiteNo}
                                    </Link> 
                                </td>
                                {/* <td><Link className="btn btn-success" onClick={() => openModalDisSiteNo()}>{dispatch.dispatchSiteNo}</Link></td>  */}

                                <td ><Link className="btn btn-success" to={`/showDeliveryAddress/${dispatch.deliveryAddNo}`}>
                                {dispatch.deliveryAddNo}
                                    </Link> 
                                </td>
                                {/* <td><Link className="btn btn-success" onClick={() => openModal(`${dispatch.orderNo}`)}>{dispatch.orderNo}</Link></td>*/}
                                
                                {/* <td>{dispatch.dispatchSiteNo}</td>  */}
                                <td>{dispatch.dispatcherName}</td> 
                                <td>{dispatch.guardsName}</td>
                                <td>{dispatch.doorNo}</td> 
                                <td>{dispatch.vehicleReg}</td> 
                                <td>{dispatch.vehicleMake}</td> 
                                <td>{dispatch.sealNo}</td> 
                            </tr>
                            ))
            )}
                </tbody>
               
            </table>  
            <div className="rows-per-page">
              <label>Rows per page:</label>
              <select className="pageRows" value={rowsPerPage} onChange={handleRowsPerPageChange}>
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
              </select>
            

            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              pageCount={Math.ceil(filteredDispatches.length / usersPerPage)}
              onPageChange={({ selected }) => setPageNumber(selected)}
              containerClassName={"pagination"}
              previousLinkClassName={"pagination__link"}
              nextLinkClassName={"pagination__link"}
              disabledClassName={"pagination__link--disabled"}
              activeClassName={"pagination__link--active"}
            />
            </div>
            {message && <p className="NoMatchFoundMsg">{message}</p>}
        </div>
      </>
    )
}


export default ViewDispatch;