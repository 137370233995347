import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import {
  Container,
  Button,
  Grid,
  Paper,
} from "@mui/material";
import './EditCompany.css'

const EditCompany = () => {
  const navigate = useNavigate();
  const { coID } = useParams();

  const [message, setMessage] = useState("");
  const [data, setData] = useState({
    coName: "",
    coID: ""
  })

  useEffect(()=>{
    showCompanyDetails();

// eslint-disable-next-line react-hooks/exhaustive-deps
},[]);

  const { coName } = data

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  }

  const handleUpdate =  async (e)=>{
    e.preventDefault();

   
    await axios.post("https://worxit.co.za/api/zamatrack/update/updateCompany.php", data)
      .then((res) => {
        if(res.data.status ==='valid'){
          setMessage("Successfully Updated");
          navigate("/superAdmin")
      }
      })
      .catch((err) => {
        console.error(err);
        setMessage("An error occurred.");
      });

  }

  const showCompanyDetails = async () =>{
    const result = await axios.get("https://worxit.co.za/api/zamatrack/edit/editCompany.php?coID="+coID);
    setData(result.data);
    
}

  return (
    <div className="createCompany-form" >
      <Container maxWidth="sm" >
        <Grid
          container
          spacing={2}
          direction="column"
          justifyContent="center"
          style={{ minHeight: "60vh", }}
        >
          <Paper elelvation={2} sx={{ padding: 5 }}>
            <form onSubmit={handleUpdate}>
              <Grid container direction="column" spacing={2}>
                <h4 className="regTitle">Edit Company Details</h4>
                <Grid item>
                  <label>Company Name</label>
                  <input
                    type="text"
                    name="coName"
                    placeholder="Company Name"
                    variant="outlined"
                    required
                    value={coName}
                    onChange={handleChange}
                  />
                </Grid>
                {/* <Grid item>
                <label>Company ID</label>
                  <input
                    type="text"
                    name="coID"
                    placeholder="Company ID"
                    variant="outlined"
                    required
                    value={coID}
                    onChange={handleChange}
                  />
                </Grid> */}
                <Grid item>
                  <Button type="submit" fullWidth variant="contained">
                    Update Company
                  </Button>
                  {message && <p className="errorMsg">{message}</p>}
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Grid>

      </Container>
    </div>
  );
};

export default EditCompany;
