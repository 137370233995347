import React, {useState} from "react";
import './OrdersProgress.css'
import { AtomDispatchState } from "../../components/RecoilStates/AtomDispatchState";
import {  useRecoilValue} from "recoil";
import ReactPaginate from 'react-paginate';

const OrdersProgress = () =>{
  
    const nonCompData = useRecoilValue(AtomDispatchState)
    const [loading, setLoading] = useState(false);

    const [rowsPerPage, setRowsPerPage] = useState(5);
    const usersPerPage = rowsPerPage;
    const [pageNumber, setPageNumber] = useState(0);
    const [filterOption, setFilterOption] = useState(""); 

    const handleRowsPerPageChange = (event) => {
      setRowsPerPage(parseInt(event.target.value));
      setPageNumber(0); // Reset the page number to 0
    };

    const handleFilterOptionChange = (event) => {
        setFilterOption(event.target.value);
        setPageNumber(0); // Reset the page number to 0
      };
    
      const calculateTimeDifference = (dateTime) => {
        const currentDate = new Date();
        const itemDate = new Date(dateTime);
        const timeDifference = (currentDate - itemDate) / (1000 * 60 * 60); // Difference in hours
        return timeDifference;
      };

      const filteredData = Array.isArray(nonCompData)
      ? nonCompData.filter((item) => {
          if (filterOption === "low") {
            // Filter for low
            const timeDifference = calculateTimeDifference(item.dateTime);
            return timeDifference >= 0 && timeDifference <= 24;
          } else if (filterOption === "medium") {
            // Filter for medium
            const timeDifference = calculateTimeDifference(item.dateTime);
            return timeDifference > 24 && timeDifference <= 48;
          } else if (filterOption === "high") {
            // Filter for high
            const timeDifference = calculateTimeDifference(item.dateTime);
            return timeDifference > 48;
          } else {
            // No filter applied, return all data
            return true;
          }
        })
      : [];
    
    
     

    return(
        <div>
           <div className="filterContainer">
            <div className="filterTitle">
                <h5>Filter by:</h5>
            </div>
            <div className="filterOptions">
                 <select value={filterOption} onChange={handleFilterOptionChange}>
                    <option value="">All</option>
                    <option value="low">Low</option>
                    <option value="medium">Medium</option>
                    <option value="high">High</option>
                </select>
            </div>
               
            </div>
             <table>
                <thead>
                    <tr>
                        <th className="orderNoWidth">Order No</th>
                        <th className="dateTimeWidth">Date Time</th>
                        <th>Dispatch Site No</th>
                        <th className="delAddNoWidth">Delivery Address No </th>
                        <th>Dispatcher Name</th>
                        <th>Guards Name</th>
                        <th>Door</th>
                        <th>Vehicle Reg</th>
                        <th>Vehicle Make</th>
                        <th>Seal No</th>                    
                    </tr>
                </thead>
                <tbody>
                    {loading ? (
                        <tr>
                        <td colSpan={11}>Loading...</td>
                        </tr>
                    ) : Array.isArray(filteredData) ? (
                        filteredData
                        .sort((a, b) => new Date(b.dateTime) - new Date(a.dateTime))
                        .slice(pageNumber * usersPerPage, (pageNumber + 1) * usersPerPage)
                        .map((dispatch, index) => (
                            <tr key={index}>
                            <td>{dispatch.orderNo}</td>
                            <td>{dispatch.dateTime}</td>
                            <td>{dispatch.dispatchSiteNo}</td>
                            <td>{dispatch.deliveryAddNo}</td>
                            <td>{dispatch.dispatcherName}</td>
                            <td>{dispatch.guardsName}</td>
                            <td>{dispatch.doorNo}</td>
                            <td>{dispatch.vehicleReg}</td>
                            <td>{dispatch.vehicleMake}</td>
                            <td>{dispatch.sealNo}</td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                        <td colSpan={11}>No data available</td>
                        </tr>
                    )}
                </tbody>
            </table>  
            <div className="rows-per-page">
              <label>Rows per page:</label>
              <select className="pageRows" value={rowsPerPage} onChange={handleRowsPerPageChange}>
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
              </select>
            

            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              pageCount={Math.ceil(filteredData.length / usersPerPage)}
              onPageChange={({ selected }) => setPageNumber(selected)}
              containerClassName={"pagination"}
              previousLinkClassName={"pagination__link"}
              nextLinkClassName={"pagination__link"}
              disabledClassName={"pagination__link--disabled"}
              activeClassName={"pagination__link--active"}
            />
            </div>
        </div>
    )
}

export default OrdersProgress;