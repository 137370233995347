import React from 'react';
import './WorxitAdminDashboard.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import CreateManger from '../../components/Create/CreateManger/CreateManger';
import ViewAllUsers from '../../components/View/ViewAllUsers/ViewAllUsers';
import CreateMobileUsers from '../../components/Create/CreateMobileUsers/CreateMobileUsers';
import ViewAllMobileUsers from '../../components/View/ViewAllMobileUsers/ViewAllMobileUsers';
import CreateDispatchSites from '../../components/Create/CreateDispatchSite/CreateDispatchSite';
import ViewDispatchSite from '../../components/View/ViewDispatchSite/ViewDispatchSite';
import CreateDeliveriesSite from '../../components/Create/CreateDeliveriesSite/CreateDeliveriesSite';
import ViewDeliveriesSite from '../../components/View/ViewDeliveriesSite/ViewDeliveriesSite'
import CreateDevice from '../../components/Create/CreateDevice/CreateDevice';
import ViewDevices from '../../components/View/ViewDevices/ViewDevices';
import CreateBarcodes from '../../components/Create/CreateBarcodes/CreateBarcodes';

const WorxitAdminDashboard = () => {
    
return (
    
  <div className='adminContainer'>         
      
    <Tabs>
      <div className='trans'></div>
      <TabList className='tabsTitle'>
        <Tab>Dashboard Users</Tab>
        <Tab>Mobile Users</Tab>
        <Tab>Dispatch Sites</Tab>
        <Tab>Deliveries Sites</Tab>
        <Tab>Devices</Tab>
        <Tab>Barcodes</Tab>
      </TabList>

      <div className='allTabPanels'>
        <TabPanel >
          <div className='createColumn'>
            <CreateManger/>
          </div>
          <div className='viewColumn'>
            <ViewAllUsers/>
          </div>       
        </TabPanel>

        <TabPanel>
          <div className='createColumn'>
            <CreateMobileUsers/>
          </div>       
          <div className='viewColumn'>
            <ViewAllMobileUsers/>
          </div>       
        </TabPanel>

        <TabPanel>
          <div className='createColumn'>
            <CreateDispatchSites/>
          </div>
          <div className='viewColumn'>
            <ViewDispatchSite/>
          </div>
        </TabPanel>

        <TabPanel>
          <div className='createColumn'>
            <CreateDeliveriesSite/>
          </div> 
          <div className='viewColumn'>
            <ViewDeliveriesSite/>
          </div>
        </TabPanel>

        <TabPanel>
          <div className='createColumn'>
            <CreateDevice/>
          </div>
          <div className='viewColumn'>
            <ViewDevices/>
          </div>
        </TabPanel>

        <TabPanel>
          <div className='createColumn'>
            <CreateBarcodes/>
          </div>      
        </TabPanel>
      </div>
    </Tabs>
     
  </div>         
 
)
       
    
}
export default WorxitAdminDashboard;