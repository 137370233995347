import React, {useEffect, useState,useRef} from "react";
import {  useParams,useNavigate } from "react-router-dom";
import axios from "axios";
import { CSVLink } from "react-csv";
import "./showOrderDetails.css";
import ReactPaginate from 'react-paginate';
import { Spin } from "react-cssfx-loading";
import ExportToPDF from "./ExportToPDF/exportToPdf";
import { MapContainer, TileLayer, Marker,Popup } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import locationMarker from '../../../media/locationMarker.png';
import {useSetRecoilState, useRecoilValue, useResetRecoilState} from 'recoil'
import { AtomDispatchDetailState } from "../../../components/RecoilStates/AtomDispatchDetailState";
import { AtomDispatchDetailItemsState } from "../../../components/RecoilStates/AtomDispatchDetailItemsState";

function ShowOrderDetails() {

    const { orderNo } = useParams();  
    const [loading, setLoading] = useState(false);
    const dispatchDetailState = useRecoilValue(AtomDispatchDetailState);
    const setDispatchState = useSetRecoilState(AtomDispatchDetailState);  
    const dispatchItemState = useRecoilValue(AtomDispatchDetailItemsState);
    const setDispatchItemState = useSetRecoilState(AtomDispatchDetailItemsState);
    const [markerPopup, setMarkerPopup] = useState(false);
    const mapRef = useRef(null);

    const resetDispatchDetailsState = useResetRecoilState(AtomDispatchDetailState)
    const resetDispatchDetailItemState = useResetRecoilState(AtomDispatchDetailItemsState)

      //----------Pagination------------     
      const [rowsPerPage, setRowsPerPage] = useState(5);
      const usersPerPage = rowsPerPage;
      const [pageNumber, setPageNumber] = useState(0);
 
      const handleRowsPerPageChange = (event) => {
        setRowsPerPage(parseInt(event.target.value));
        setPageNumber(0); // Reset the page number to 0
      };

    useEffect(() => {
      dispatchTable();
      dispatchItemsTable();
      
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const navigate = useNavigate();

    const dispatchNavigate=()=>{
        navigate('/dispatched')
        resetDispatchDetailsState ();
        resetDispatchDetailItemState();

    }

  
    const combinedTbleDataHeaders =[
      { label: 'Order No', key: 'orderNo' },
      { label: 'Date Time', key: 'dateTime' },
      { label: 'Dispatch Site No', key: 'dispatchSiteNo' },
      { label: 'Delivery Address Code', key: 'deliveryAddNo' },
      { label: 'Dispatcher Name', key: 'dispatcherName' },
      { label: 'Door', key: 'doorNo' },
      { label: 'Vehicle Reg', key: 'vehicleReg' },
      { label: 'Vehicle Make', key: 'vehicleMake' },
      { label: 'Seal No', key: 'sealNo' },
      { label: 'Latitude', key: 'latitude' },
      { label: 'Longitude', key: 'longitude' },
      { label: 'Qty No', key: 'qtyNo' },
      { label: 'Barcode', key: 'barcode' },
      { label: 'Description', key: 'description' },
      { label: 'Pallet No', key: 'palletNoDis' },
    ];

    
    const combinedTbleData = [
      ...dispatchDetailState.map(row => ({
        orderNo: row.orderNo,
        dateTime: row.dateTime,
        dispatchSiteNo: row.dispatchSiteNo,
        deliveryAddNo: row.deliveryAddNo,
        dispatcherName: row.dispatcherName,
        doorNo: row.doorNo,
        driver: row.driver,
        vehicleReg: row.vehicleReg,
        vehicleMake: row.vehicleMake,
        vehicleColour: row.vehicleColour,
        sealNo: row.sealNo,
        latitude:row.latitude,
        longitude:row.longitude
      })),
      ...dispatchItemState.map(row => ({
        qtyNo: row.qtyNo,
        barcode: row.barcode,
        description: row.description || "No Description Found",
        palletNoDis: row.palletNoDis
      })),
     
   ];

   
   const dispatchTable = async () => {
    console.log(orderNo);
    setLoading(true);
    const result = await axios.get(
      "https://worxit.co.za/api/zamatrack/dispatchShowOrderDetails.php?orderNo=" +
        orderNo
    );
    setDispatchState(result.data.records);
    setLoading(false);
    console.log("Dispatch Details Atom: ",dispatchDetailState )
  };
  

    const dispatchItemsTable = async () => {
      console.log(orderNo);
      setLoading(true);
      const result = await axios.get(
        "https://worxit.co.za/api/zamatrack/dispatchItemsTable.php?orderNo=" +
          orderNo
      );
      setDispatchItemState(result.data.records);
      setLoading(false);
      console.log(result)
    };
    // Create a custom marker icon
    const customIcon = L.icon({
    iconUrl: locationMarker,
    iconSize: [14, 18], // Adjust the size as needed

    });
  
      
    return (
      <div className="dispatchContainer">

       <div className="dispatchTbl">
        <div>
          <label>Dispatch</label>
        </div>
          <table>
              <thead>
                <tr>
                  <th>Order No</th> 
                  <th>Date Time</th>
                  <th>Dispatch Site No</th>
                  <th>Delivery Address No</th>
                  <th>Dispatcher Name</th>
                  <th>Guards Name</th>
                  <th>Door</th>
                  <th>Vehicle Reg</th>
                  <th>Vehicle Make</th>
                  <th>Seal No</th> 
                </tr>
              </thead>
              <tbody>
              {loading ? (
                      <tr>
                        <td colSpan={11}>
                          <div className="loadingSpinComponent">
                            <Spin />
                          </div>
                        </td>
                      </tr>
                        ) : (dispatchDetailState && dispatchDetailState.map((details,index) => (
                  <tr key={index}>
                    <td>{orderNo}</td> 
                    <td>{details.dateTime}</td> 
                    <td>{details.dispatchSiteNo}</td> 
                    <td>{details.deliveryAddNo}</td>
                    <td>{details.dispatcherName}</td> 
                    <td>{details.guardsName}</td>
                    <td>{details.doorNo}</td> 
                    <td>{details.vehicleReg}</td> 
                    <td>{details.vehicleMake}</td> 
                    <td>{details.sealNo}</td> 
                  </tr>
                )))}
                
              </tbody>
            </table>
        </div>      
        <div className="viewContainer">
              {dispatchDetailState.length > 0 ? (
                <>
                <div id="map-container" className="map-container">
                  <MapContainer
                    center={[dispatchDetailState[0].latitude, dispatchDetailState[0].longitude]}
                    zoom={18}
                    style={{ height: "400px", width: "100%" }}
                    className="map"
                    ref={mapRef}
                  >
                    <TileLayer
                      attribution="Map data &copy; OpenStreetMap contributors"
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />

                    <Marker position={[dispatchDetailState[0].latitude, dispatchDetailState[0].longitude]} icon={customIcon}>
                      <Popup onClose={() => setMarkerPopup(false)}>
                        <div>
                          Latitude: {[dispatchDetailState[0].latitude]}<br />
                          Longitude: {[dispatchDetailState[0].longitude]}<br />
                        </div>
                      </Popup>
                    </Marker>
                  </MapContainer>  
                  
                </div> 
              <div className="video1ViewContainer">
                {/* {renderVideo1()} */}
                {dispatchDetailState.map((details, index) => (
              <div key={index}>
                <img className="imageUrlAll" src={details.imageUriAll} alt="imageUriAll" />
              </div>
            ))}
              </div> 
              <div className="video1ViewContainer">
              {/* {renderVideo2()} */}
              </div>
              </>
              ): (
                <tr>
                  <td colSpan={6}>No Data Found
                    {/* <div className="loadingSpinComponent">
                      <Spin />
                    </div> */}
                  </td> 
                </tr>
              )} 
            
            </div>
        <div className="dispatchItemsTbl">    
          <div>
            <label>Dispatch Items</label>
          </div>   
            <table>
              <thead>
                <tr>
                  <th>Order No</th> 
                  <th>Date Time</th>
                  <th>Quantity Number</th>
                  <th>Pallet No</th>
                  <th>Barcode</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
              {dispatchItemState.length > 0 ? (    
                          dispatchItemState && dispatchItemState
                          .slice(pageNumber * usersPerPage, (pageNumber + 1) * usersPerPage)
                          .map((details,index) => (
                  <tr key={index}>
                    <td>{orderNo}</td> 
                    <td>{details.dateTime}</td> 
                    <td>{details.qtyNo}</td> 
                    <td>{details.palletNoDis}</td>
                    <td>{details.barcode}</td>
                    <td style={{textAlign: 'left'}}>{details.description ? details.description : 'No description found'}</td>
                  </tr>
                ))
                ) : (
                  <tr>
                    {/* colSpan={5}. The 5 is the amount of columns */}
                    <td colSpan={6}>
                    <div className="loadingSpinComponent">
                        <Spin />
                      </div>
                    </td> 
                  </tr>
                )}
                
              </tbody>
            </table>
            
            <div className="rows-per-page">
              <label>Rows per page:</label>
              <select className="pageRows" value={rowsPerPage} onChange={handleRowsPerPageChange}>
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
              </select>
            

            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              pageCount={Math.ceil(dispatchItemState.length / usersPerPage)}
              onPageChange={({ selected }) => setPageNumber(selected)}
              containerClassName={"pagination"}
              previousLinkClassName={"pagination__link"}
              nextLinkClassName={"pagination__link"}
              disabledClassName={"pagination__link--disabled"}
              activeClassName={"pagination__link--active"}
            />
            </div>
        </div>   
          <div className="actionBtns">    
            <button className="orderBackBtn" onClick={dispatchNavigate}>Back</button>

            <CSVLink className="exportToCSVBtn"
              data={combinedTbleData}
              headers={combinedTbleDataHeaders}
              filename={"combinedTbleData.csv"}
              target="_blank">
                Export to csv
            </CSVLink>

            <ExportToPDF mapRef={mapRef}/>
            {/* <button className="exportMapBtn" onClick={exportMapToPDF}>
          Export Map to PDF
        </button> */}
        </div>
      </div>
    );
  }
  
  export default ShowOrderDetails;
  
  