import React, {useEffect, useState} from "react";
import {  useParams,useNavigate } from "react-router-dom";
import axios from "axios";
import { CSVLink } from "react-csv";
import 'jspdf-autotable';
import "./showClaimsDetails.css";
import ExportToPDF from "./ExportToPDF/exportToPdf";

function ShowClaimsDetails() {

    const { orderNo } = useParams();  
    const [loading, setLoading] = useState(false);
    const [showClaimsTbl, setShowClaimsTbl] = useState([]);
    const [showClaimsItemsTbl, setShowClaimsItemsTbl] = useState([]);
 
    useEffect(() => {
        claimsTbl();
        claimsItemsTbl();
             
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const navigate = useNavigate();

    const suppliersNavigate=()=>{
        navigate('/claims')
    }

    const combinedTbleDataHeaders =[
      {label: "Order No", key: "orderNo"},
      {label: "Date Time", key: "dateTime"},
      {label: "Site No", key: "siteNo"},
      {label: "Receivers Name", key: "receiversName"},
      {label: "Guards Name", key: "guardsName"},
      
      {label: "Barcode", key: "barcode"},
      {label: "Quantity", key: "qtyNo"},      
      {label: "Description", key: "description"},
      {label: "Reason", key: "reason"},

    ];

    const combinedTbleData = [
      ...showClaimsTbl.map(row => ({
        orderNo: row.orderNo,
        dateTime: row.dateTime,
        siteNo: row.siteNo,
        receiversName: row.receiversName,
        guardsName: row.guardsName,
      })),
      ...showClaimsItemsTbl.map(row => ({
        barcode: row.barcode,
        qtyNo: row.qtyNo,
        description: row.description,
        reason: row.reason
      })),
    ];

    const claimsTbl = async () => {
      console.log(orderNo);
      setLoading(true);
      const result = await axios.get(
        "https://worxit.co.za/api/zamatrack/showClaimsDetails.php?orderNo=" + 
        orderNo);
      setShowClaimsTbl(result.data.records);
      setLoading(false);
    };

    const claimsItemsTbl = async () => {
      console.log(orderNo);
      setLoading(true);
      const result = await axios.get(
        "https://worxit.co.za/api/zamatrack/claimsItemsTable.php?orderNo=" + 
        orderNo);
      setShowClaimsItemsTbl(result.data.records);
      setLoading(false);
    };

   
    return (
      <div>
        <div className="claimsTbl">
         <div>
            <label>Claims</label>
          </div>   
       <table>
          <thead>
            <tr>
              <th>Order No</th>
              <th>Date Time</th>
              <th>Site No</th>
              <th>Receivers Name</th>
              <th>Guards Name</th>
            </tr>
          </thead>
          <tbody>
          {loading ? (
                      <tr>
                        <td colSpan={11}>Loading...</td>
                      </tr>
                    ) : (    
            showClaimsTbl && showClaimsTbl.map((details,index) => (
              <tr key={index}>
                <td>{orderNo}</td> 
                <td>{details.dateTime}</td> 
                <td>{details.siteNo}</td> 
                <td>{details.receiversName}</td>
                <td>{details.guardsName}</td>
              </tr>
            )))}
            
          </tbody>
        </table>
        </div>
        <div className="claimsItemsTbl">    
          <div>
            <label>Claims Items</label>
          </div>   
            <table>
              <thead>
                <tr>
                  <th>Order No</th> 
                  <th>Barcode</th>
                  <th>Quantity</th>
                  <th>Description</th>
                  <th>Reason</th>
                </tr>
              </thead>
              <tbody>
              {loading ? (
                      <tr>
                        <td colSpan={11}>Loading...</td>
                      </tr>
                        ) : showClaimsItemsTbl.length > 0 ? (    
                          showClaimsItemsTbl && showClaimsItemsTbl.map((details,index) => (
                  <tr key={index}>
                    <td>{orderNo}</td> 
                    <td>{details.barcode}</td> 
                    <td>{details.qtyNo}</td> 
                    <td>{details.description ? details.description : 'No description found'}</td> 
                    <td>{details.reason}</td>
                  </tr>
                ))
                ) : (
                  <tr>
                    <td colSpan={11}>No data found</td>
                  </tr>
                )}
                
              </tbody>
            </table>
        </div>    
        <div className="actionBtns">          
          <button className="orderBackBtn" onClick={suppliersNavigate}>Back</button>

          <CSVLink className="exportToCSVBtn"
            data={combinedTbleData}
            headers={combinedTbleDataHeaders}
            filename={"combinedTbleData.csv"}
            target="_blank">
              Export to csv
          </CSVLink>

          <ExportToPDF/> 

        </div>
      </div>
    );
  }
  
  export default ShowClaimsDetails;
  