import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

// export const lpaAdminDetailsState = atom({
//   key: 'lpaAdminDetailsState',
//   default: {
//     auditCode: '',
//     section: '',
//     LPAQuestions: [],
//     responses: [],
//     notes: [],
//     scores: [],
//     totalScore: []
//   },
//   effects_UNSTABLE: [persistAtom],
// });
export const lpaAdminDetailsState = atom({
  key: 'lpaAdminDetailsState',
  default: {
    LPAQuestions: [],
    responses: [],
    totalScore: []
  },
  effects_UNSTABLE: [persistAtom],
});