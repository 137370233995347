import React, {useState, useEffect} from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import "./pallets.css";
import { AtomPalletsState } from "../../components/RecoilStates/AtomPalletsState"
import { useRecoilValue, useSetRecoilState } from "recoil";


const Pallets = () =>{

    const palletsData = useRecoilValue(AtomPalletsState);
    const setPalletsData = useSetRecoilState(AtomPalletsState);

    const [loading, setLoading] = useState(false);
    const [palletNoSearch, setPalletNoSearch] = useState("");
    const [dateTimeStart, setDateTimeStart] = useState(""); 
    const [message, setMessage] = useState("");

  
    
    //---------REFRESH PAGE AUTOMATICALLY----------
    

    useEffect(() => {
        const fetchData = async () => {
        const response = await axios.get('https://worxit.co.za/api/zamatrack/palletView.php');
        const newData = response.data.records;
        setPalletsData(newData);
      }; 
     
      fetchData();
      const intervalId = setInterval(fetchData, 3000);
      return () => clearInterval(intervalId);
    }, [setPalletsData]);


    const filteredPallets = palletsData.filter(details => {
      if (palletNoSearch !== "" || dateTimeStart !== "") {
        if (
          details.palletReturnsNo.includes(palletNoSearch) &&
          details.dateTime.includes(dateTimeStart)
        ) {
          return details;
        }
        return false;
      }
      return details;
    });
    
    useEffect(() => {
      if (filteredPallets.length === 0 && (palletNoSearch !== "" || dateTimeStart !== "")) {
        setMessage("No Match Found");
      } else {
        setMessage("");
      }
    }, [filteredPallets, palletNoSearch, dateTimeStart]);

     //----------Pagination------------     
      const [rowsPerPage, setRowsPerPage] = useState(5);
      const usersPerPage = rowsPerPage;
      const [pageNumber, setPageNumber] = useState(0);
 
      const handleRowsPerPageChange = (event) => {
        setRowsPerPage(parseInt(event.target.value));
        setPageNumber(0); // Reset the page number to 0
      };


    return(
      <>
        <div style={{width: "100%"}}>
            <div className=" row filter" >
                <div className="column1">
                    <h5>Pallet Returns Number:</h5>           
                </div>
                <div className="column2"> 
                    <input
                    className="searchInput"
                    type="text"
                    placeholder= "Search..."
                    onChange={(e) => setPalletNoSearch(e.target.value)}/>  
                </div>
                <div className="column3">
                    <h5>Date:</h5>
                </div>
                    <div className="column4">
                        <input
                            className="searchInput"
                            type="date"
                            placeholder= "Search..."
                            onChange={(e) => setDateTimeStart(e.target.value)}/>
                    </div> 
                   
        </div>
        <h6>Latest 5 Order Entries...</h6>
            <table>
                <thead>
                    <tr>
                        <th>Pallet Returns No</th>
                        <th>Date Time</th>
                        <th>Site No</th>
                        <th>Receivers Name</th>
                        <th>Guards Name</th>            
                    </tr>
                </thead>
                <tbody>
                {loading ? (
                      <tr>
                        <td colSpan={11}>Loading...</td>
                      </tr>
                    ) : (        
                                       
                      filteredPallets
                        .sort((a, b) => new Date(b.dateTime) - new Date(a.dateTime))
                        .slice(pageNumber * usersPerPage, (pageNumber + 1) * usersPerPage)
                        .map((details, index) =>(
                            <tr key={index}>   
                                <td ><Link className="btn btn-success" to={`/showpalletDetails/${details.palletReturnsNo}`}>
                                    {details.palletReturnsNo}
                                    </Link>
                                </td> 
                                <td>{details.dateTime}</td> 
                                <td >{details.siteNo}</td>
                                <td>{details.receiversName}</td> 
                                <td>{details.guardsName}</td>
                            </tr>
                            ))
            )}
                </tbody>
               
            </table>  
            <div className="rows-per-page">
              <label>Rows per page:</label>
              <select className="pageRows" value={rowsPerPage} onChange={handleRowsPerPageChange}>
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
              </select>
            

            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              pageCount={Math.ceil(filteredPallets.length / usersPerPage)}
              onPageChange={({ selected }) => setPageNumber(selected)}
              containerClassName={"pagination"}
              previousLinkClassName={"pagination__link"}
              nextLinkClassName={"pagination__link"}
              disabledClassName={"pagination__link--disabled"}
              activeClassName={"pagination__link--active"}
            />
            </div>
            {message && <p className="NoMatchFoundMsg">{message}</p>}
        </div>
      </>
    )
}


export default Pallets;