import React, {useEffect, useState} from "react";
import {  useParams,useNavigate } from "react-router-dom";
import axios from "axios";
import { CSVLink } from "react-csv";
import 'jspdf-autotable';
import "./showReturnedDetails.css";
import ExportToPDF from "./ExportToPDF/exportToPdf";

function ShowSuppliersDetails() {

    const { docNo } = useParams();  
    const [loading, setLoading] = useState(false);
    const [showReturnedTbl, setShowReturnedTbl] = useState([]);
    const [showReturnedItemsTbl, setShowReturnedItemsTbl] = useState([]);
       
    useEffect(() => {
        returnedTbl();
        returnedItemsTbl();
             
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const navigate = useNavigate();

    const suppliersNavigate=()=>{
        navigate('/returned')
    }

    const combinedTbleDataHeaders =[
      {label: "Doc No", key: "docNo"},
      {label: "Suppliers Name", key: "supplierName"},
      {label: "Transporter", key: "transporter"},
      {label: "Driver", key: "driverName"},
      {label: "Date Time", key: "dateTime"},
      {label: "Reason", key: "returnReason"},
      {label: "Vehicle Reg", key: "vehicleReg"},
      {label: "Vehicle Make", key: "vehicleMake"},
      {label: "Vehicle Colour", key: "vehicleColour"},
      {label: "Receivers Name", key: "receiversName"},
      {label: "Guard Name", key: "guardsName"},
      {label: "Quantity", key: "qtyNo"},
      {label: "Barcode", key: "barcode"},
      {label: "Description", key: "description"},
      {label: "Reason", key: "reason"},
    ];

    const combinedTbleData = [
      ...showReturnedTbl.map(row => ({
        docNo: row.docNo,
        supplierName: row.supplierName,
        transporter: row.transporter,
        driverName: row.driverName,
        dateTime: row.dateTime,
        sealIn: row.sealIn,
        sealOut: row.sealOut,
        returnReason: row.returnReason,
        vehicleReg: row.vehicleReg,
        vehicleMake: row.vehicleMake,
        vehicleColour: row.vehicleColour,
        receiversName: row.receiversName,
        guardsName: row.guardsName,        
      })),
      ...showReturnedItemsTbl.map(row => ({
        barcode: row.barcode,
        qtyNo: row.qtyNo,
        description: row.description || "No Description Found",
        reason: row.reason,
      })),
    ];

    
    const returnedTbl = async () => {
      console.log(docNo);
      setLoading(true);
      const result = await axios.get(
        "https://worxit.co.za/api/zamatrack/showReturnedDetails.php?docNo=" + 
        docNo);
      setShowReturnedTbl(result.data.records);
      setLoading(false);
    };

    const returnedItemsTbl = async () => {
      console.log(docNo);
      setLoading(true);
      const result = await axios.get(
        "https://worxit.co.za/api/zamatrack/returnedItemsTable.php?docNo=" + 
        docNo);
      setShowReturnedItemsTbl(result.data.records);
      setLoading(false);
    };

    return (
      <div>
        <div className="returnedTbl">
         <div>
            <label>Returned</label>
          </div>   
       <table>
          <thead>
            <tr>
              <th>Doc No</th>
              <th>Suppliers Name</th>
              <th>Date Time</th>
              <th>Reason</th>
              <th>Transporter</th>
              <th>Driver</th>
              <th>Vehicle Reg</th>
              <th>Vehicle Make</th>
              <th>Vehicle colour</th>
              <th>Receivers Name</th>
              <th>Guards Name</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
                        <tr>
                          <td colSpan={11}>Loading...</td>
                        </tr>
                    ) : (    
            showReturnedTbl && showReturnedTbl.map((details,index) => (
              <tr key={index}>
                <td>{docNo}</td> 
                <td>{details.supplierName}</td> 
                <td>{details.dateTime}</td> 
                <td>{details.returnReason}</td>
                <td>{details.transporter}</td> 
                <td>{details.driverName}</td>
                <td>{details.vehicleReg}</td>
                <td>{details.vehicleMake}</td>
                <td>{details.vehicleColour}</td>
                <td>{details.receiversName}</td>
                <td>{details.guardsName}</td>
              </tr>
            )))}
            
          </tbody>
        </table>
        </div>
        <div className="returnedItemsTbl">    
          <div>
            <label>Returned Items</label>
          </div>   
            <table>
              <thead>
                <tr>
                  <th>Doc No</th>
                  <th>Quantity</th>
                  <th>Barcode</th>
                  <th>Description</th>
                  <th>Reason</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                        <tr>
                          <td colSpan={11}>Loading...</td>
                        </tr>
                        ) : showReturnedItemsTbl.length > 0 ? (    
                          showReturnedItemsTbl && showReturnedItemsTbl.map((details,index) => (
                  <tr key={index}>
                    <td>{docNo}</td> 
                    <td>{details.qtyNo}</td>
                    <td>{details.barcode}</td>                    
                    <td>{details.description ? details.description : 'No description found'}</td>
                    <td>{details.reason}</td> 
                  </tr>
                ))
                ) : (
                  <tr>
                    <td colSpan={11}>No data found</td>
                  </tr>
                )}
                
              </tbody>
            </table>
        </div>   

        <div className="actionBtns">
          <button className="orderBackBtn" onClick={suppliersNavigate}>Back</button>

          <CSVLink className="exportToCSVBtn"
            data={combinedTbleData}
            headers={combinedTbleDataHeaders}
            filename={"combinedTbleData.csv"}
            target="_blank">
              Export to csv
          </CSVLink>       

          <ExportToPDF/>                       
        </div> 
      </div>
    );
  }
  
  export default ShowSuppliersDetails;
  