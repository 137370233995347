import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import '../../styles/custom.css'
import './StoreAudit.css'
import { auditHeaderDataAtom } from '../../components/RecoilStates/auditHeaderDataAtom';
import { auditCodeAtom } from '../../components/RecoilStates/auditCodeAtom';
import { lpaAdminQuestionsState } from '../../components/RecoilStates/LpaAdminQuestionsState';
import { lpaAdminDataState } from '../../components/RecoilStates/LpaAdminDataState';
import { lpaBackupQuestionsState } from '../../components/RecoilStates/LpaBackupQuestionsState';
import { lpaBackupDataState } from '../../components/RecoilStates/LpaBackupDataState';
import { lpaFrontlineQuestionsState } from '../../components/RecoilStates/LpaFrontlineQuestionsState';
import { lpaFrontlineDataState } from '../../components/RecoilStates/LpaFrontLineDataState';
import { lpaSecurityQuestionsState } from '../../components/RecoilStates/LpaSecurityQuestionsState';
import { lpaSecurityDataState } from '../../components/RecoilStates/LpaSecurityDataState';
import { lpaReceivingQuestionsState } from '../../components/RecoilStates/LpaReceivingQuestionsState';
import { lpaReceivingDataState } from '../../components/RecoilStates/LpaReceivingDataState';
import { lpaSalesFloorQuestionsState } from '../../components/RecoilStates/LpaSalesFloorQuestionsState';
import { lpaSalesFloorDataState } from '../../components/RecoilStates/LpaSalesFloorDataState';
import { lpaCashOfficeQuestionsState } from '../../components/RecoilStates/LpaCashOfficeQuestionsState';
import { lpaCashOfficeDataState } from '../../components/RecoilStates/LpaCashOfficeDataState';
import { auditLogsAtom } from '../../components/RecoilStates/auditlogsAtom';
import { lpaAdminDetailsState } from '../../components/RecoilStates/LpaAdminDetailsState';
import { lpaBackupsDetailsState } from '../../components/RecoilStates/LpaBackupsDetailsState'
import { lpaCashOfficeDetailsState } from '../../components/RecoilStates/LpaCashOfficeDetailsState';
import { lpaFrontlineDetailsState } from '../../components/RecoilStates/LpaFrontlineDetailsState';
import { lpaReceivingDetailsState } from '../../components/RecoilStates/LpaReceivingDetailsState';
import { lpaSalesFloorDetailsState } from '../../components/RecoilStates/LpaSalesFloorDetailsState';
import { lpaSecurityDetailsState } from '../../components/RecoilStates/LpaSecurityDetailsState';
import { auditSiteAtom } from '../../components/RecoilStates/auditSiteAtom';


const StoreAudit = () => {
    const auditSite = useRecoilValue(auditSiteAtom);
    const setAuditCodeValue = useSetRecoilState(auditCodeAtom)
    const lpaAdminQuestions = useRecoilValue(lpaAdminQuestionsState);
    const setLpaAdminQuestions = useSetRecoilState(lpaAdminQuestionsState)
    const lpaAdminData = useRecoilValue(lpaAdminDataState);

    const lpaBackupQuestions = useRecoilValue(lpaBackupQuestionsState);
    const setLpaBackupsQuestions = useSetRecoilState(lpaBackupQuestionsState)
    const lpaDataBackups = useRecoilValue(lpaBackupDataState);

    const lpaCashOfficeQuestions = useRecoilValue(lpaCashOfficeQuestionsState);
    const setLpaCashOfficeQuestions = useSetRecoilState(lpaCashOfficeQuestionsState);
    const lpaDataCashOffice = useRecoilValue(lpaCashOfficeDataState);

    const lpaFrontlineQuestions = useRecoilValue(lpaFrontlineQuestionsState);
    const setLpaFrontlineQuestions = useSetRecoilState(lpaFrontlineQuestionsState);
    const lpaDataFrontline = useRecoilValue(lpaFrontlineDataState);

    const lpaReceivingQuestions = useRecoilValue(lpaReceivingQuestionsState);
    const setLpaReceivingQuestions = useSetRecoilState(lpaReceivingQuestionsState);
    const lpaDataReceiving = useRecoilValue(lpaReceivingDataState);

    const lpaSalesFloorQuestions = useRecoilValue(lpaSalesFloorQuestionsState);
    const setLpaSalesFloorQuestions = useSetRecoilState(lpaSalesFloorQuestionsState)
    const lpaDataSalesFloor = useRecoilValue(lpaSalesFloorDataState);

    const lpaSecurityQuestions = useRecoilValue(lpaSecurityQuestionsState);
    const setLpaSecurityQuestions = useSetRecoilState(lpaSecurityQuestionsState);
    const lpaDataSecurity = useRecoilValue(lpaSecurityDataState);

  
    const [auditLogs, setAuditLogs] = useRecoilState(auditLogsAtom)
    const [isLoading, setIsLoading] = useState(true);
    const [clickedRowUrl, setClickedRowUrl] = useState(null);
    const [isDataReady, setIsDataReady] = useState(false);

    const navigate = useNavigate();

    const setLpaAdminDetails = useSetRecoilState(lpaAdminDetailsState);
    const setLpaBackupsDetails = useSetRecoilState(lpaBackupsDetailsState);
    const setLpaCashOfficeDetails = useSetRecoilState(lpaCashOfficeDetailsState);
    const setLpaFrontlineDetails = useSetRecoilState(lpaFrontlineDetailsState);
    const setLpaReceivingDetails = useSetRecoilState(lpaReceivingDetailsState);
    const setLpaSalesFloorDetails = useSetRecoilState(lpaSalesFloorDetailsState);
    const setLpaSecurityDetails = useSetRecoilState(lpaSecurityDetailsState);

       
    useEffect(() => {
        if (clickedRowUrl) {
            window.location.href = clickedRowUrl;
        }
    }, [clickedRowUrl]);

    useEffect(() => {
        // Fetch data from the API and update LPAQuestions in the atom
        const fetchData = async () => {
          try {
            const response = await axios.get('https://worxit.co.za/api/zamatrack/view/viewAuditLogs.php');
            const newData = response.data.records;
            // Update the LPAQuestions in the atom if newData is an array
            if (Array.isArray(newData)) {
                setAuditLogs(newData);
           
            }
            setIsLoading(false);
          } catch (error) {
            console.error('Error fetching data:', error);
            setIsLoading(false);
          }
        };
        
        fetchData()
      }, [setAuditLogs]);

    useEffect(() => {   
      fetchAdminQuestions()
      fetchAdminData()
      fetchBackupsQuestions()
      fetchBackupsData()
      fetchCashOfficeQuestions()
      fetchCashOfficeData()
      fetchFrontlineQuestions()
      fetchFrontlineData()
      fetchReceivingQuestions()
      fetchReceivingData()
      fetchSalesFloorQuestions()
      fetchSalesFloorData()
      fetchSecurityQuestions()
      fetchSecurityData()

          .then(() => setIsDataReady(true)) 
          .catch(() => setIsDataReady(true)); 
      },[]);

     

      

      //------------------ADMIN-----------------------

      const fetchAdminQuestions = async () => {
        try {
          const response = await axios.get('https://worxit.co.za/api/zamatrack/view/sectionAQuestions.php');
          const newData = response.data.records;
          // Update the LPAQuestions in the atom
          setLpaAdminQuestions((prevLpaData) => ({
            ...prevLpaData,
            LPAQuestions: newData,
          }));
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
      const fetchAdminData = async () => {
        try {
          const response = await axios.get('https://worxit.co.za/api/zamatrack/view/sectionATableDetails.php');
          const newData = response.data.records;
          // Update the LPAQuestions in the atom if newData is an array
          if (Array.isArray(newData)) {
            console.log('fetchAdminData:---------- ' ,newData)
            setLpaAdminDetails(newData);
          }
          setIsLoading(false);
        } catch (error) {
          console.error('Error fetching data:', error);
          setIsLoading(false);
        }
      };
    
      

      //-----------------BACKUPS---------------

      const fetchBackupsQuestions = async () => {
        try {
          const response = await axios.get('https://worxit.co.za/api/zamatrack/view/sectionBQuestions.php');
          const newData = response.data.records;
          //console.log("Questions: ",lpaDataQuestions.LPAQuestions.questions)
          // Update the LPAQuestions in the atom
          setLpaBackupsQuestions((prevLpaData) => ({
            ...prevLpaData,
            LPAQuestions: newData,
          }));
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      const fetchBackupsData = async () => {
        try {
          const response = await axios.get('https://worxit.co.za/api/zamatrack/view/sectionBTableDetails.php');
          const newData = response.data.records;
          // Update the LPAQuestions in the atom if newData is an array
          if (Array.isArray(newData)) {
            console.log('fetchBackupsData:---------- ' ,newData)
            setLpaBackupsDetails(newData);
          }
          setIsLoading(false);
        } catch (error) {
          console.error('Error fetching data:', error);
          setIsLoading(false);
        }
      };

      //--------------CASH OFFICE-----------------

      const fetchCashOfficeQuestions = async () => {
        try {
          const response = await axios.get('https://worxit.co.za/api/zamatrack/view/sectionGQuestions.php');
          const newData = response.data.records;
        
          // Update the LPAQuestions in the atom
          setLpaCashOfficeQuestions((prevLpaData) => ({
            ...prevLpaData,
            LPAQuestions: newData,
          }));
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      const fetchCashOfficeData = async () => {
        try {
          const response = await axios.get('https://worxit.co.za/api/zamatrack/view/sectionGTableDetails.php');
          const newData = response.data.records;
          // Update the LPAQuestions in the atom if newData is an array
          if (Array.isArray(newData)) {
            setLpaCashOfficeDetails(newData);
          }
          setIsLoading(false);
        } catch (error) {
          console.error('Error fetching data:', error);
          setIsLoading(false);
        }
      };

      //--------------FRONTLINE-------------------

      const fetchFrontlineQuestions = async () => {
        try {
          const response = await axios.get('https://worxit.co.za/api/zamatrack/view/sectionCQuestions.php');
          const newData = response.data.records;
         
          setLpaFrontlineQuestions((prevLpaData) => ({
            ...prevLpaData,
            LPAQuestions: newData,
          }));
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      const fetchFrontlineData = async () => {
        try {
          const response = await axios.get('https://worxit.co.za/api/zamatrack/view/sectionCTableDetails.php');
          const newData = response.data.records;
          // Update the LPAQuestions in the atom if newData is an array
          if (Array.isArray(newData)) {
            setLpaFrontlineDetails(newData);
         
          }
          setIsLoading(false);
        } catch (error) {
          console.error('Error fetching data:', error);
          setIsLoading(false);
        }
      };

      //-----------RECEIVING-----------------

      const fetchReceivingQuestions = async () => {
        try {
          const response = await axios.get('https://worxit.co.za/api/zamatrack/view/sectionEQuestions.php');
          const newData = response.data.records;
          //console.log("Questions: ",lpaDataQuestions.LPAQuestions.questions)
          // Update the LPAQuestions in the atom
          setLpaReceivingQuestions((prevLpaData) => ({
            ...prevLpaData,
            LPAQuestions: newData,
          }));
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      const fetchReceivingData = async () => {
        try {
          const response = await axios.get('https://worxit.co.za/api/zamatrack/view/sectionETableDetails.php');
          const newData = response.data.records;
          // Update the LPAQuestions in the atom if newData is an array
          if (Array.isArray(newData)) {
            setLpaReceivingDetails(newData);
         
          }
          setIsLoading(false);
        } catch (error) {
          console.error('Error fetching data:', error);
          setIsLoading(false);
        }
      };

      //--------------SALES FLOOR----------------

      const fetchSalesFloorQuestions = async () => {
        try {
          const response = await axios.get('https://worxit.co.za/api/zamatrack/view/sectionFQuestions.php');
          const newData = response.data.records;
          //console.log("Questions: ",lpaDataQuestions.LPAQuestions.questions)
          // Update the LPAQuestions in the atom
          setLpaSalesFloorQuestions((prevLpaData) => ({
            ...prevLpaData,
            LPAQuestions: newData,
          }));
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };


      const fetchSalesFloorData = async () => {
        try {
          const response = await axios.get('https://worxit.co.za/api/zamatrack/view/sectionFTableDetails.php');
          const newData = response.data.records;
          // Update the LPAQuestions in the atom if newData is an array
          if (Array.isArray(newData)) {
            setLpaSalesFloorDetails(newData);
         
          }
          setIsLoading(false);
        } catch (error) {
          console.error('Error fetching data:', error);
          setIsLoading(false);
        }
      };

      //---------------SECURITY---------------

      const fetchSecurityQuestions = async () => {
        try {
          const response = await axios.get('https://worxit.co.za/api/zamatrack/view/sectionDQuestions.php');
          const newData = response.data.records;
          //console.log("Questions: ",lpaDataQuestions.LPAQuestions.questions)
          // Update the LPAQuestions in the atom
          setLpaSecurityQuestions((prevLpaData) => ({
            ...prevLpaData,
            LPAQuestions: newData,
          }));
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      const fetchSecurityData = async () => {
        try {
          const response = await axios.get('https://worxit.co.za/api/zamatrack/view/sectionDTableDetails.php');
          const newData = response.data.records;
          // Update the LPAQuestions in the atom if newData is an array
          if (Array.isArray(newData)) {
            setLpaSecurityDetails(newData);
         
          }
          setIsLoading(false);
        } catch (error) {
          console.error('Error fetching data:', error);
          setIsLoading(false);
        }
      };
  

      if (!isDataReady) {
        return <div>Loading...</div>;
      }
    
      if (isLoading) {
        return <div>Loading...</div>;
      }
      

    const generateRandomCode = () => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        let code = '';
        for (let i = 0; i < 6; i++) {
          const randomIndex = Math.floor(Math.random() * characters.length);
          code += characters[randomIndex];
        }
        return code;
      };


    

    const handleNavClick = () => {
        console.log("lpaAdminData:", lpaAdminData);

        setAuditCodeValue((prevLpaData) => ({
            ...prevLpaData,
            auditCode: generateRandomCode()
          }));
    
        // Ensure each property is properly initialized as an empty array
        if (!Array.isArray(lpaAdminData.responses)) {
            lpaAdminData.responses = [];
        }
        if (!Array.isArray(lpaAdminData.notes)) {
            lpaAdminData.notes = [];
        }
        if (!Array.isArray(lpaAdminData.scores)) {
            lpaAdminData.scores = [];
        }

        if(auditSite.site > 0 ){
          alert("Please complete the current audit before starting a new one");
        }else{
          navigate('/auditerPage')
        }
    }
    

//Helper function to check if atoms are fully filled
const allAtomsAreFullyFilled = () => {
    if ( !lpaAdminData || !lpaDataBackups || !lpaDataCashOffice || !lpaDataFrontline || !lpaDataReceiving || !lpaDataSalesFloor || !lpaDataSecurity) return false; // If either lpaDataAdmin or lpaDataBackups is empty, return false
    const { responses: adminResponses, scores: adminScores } = lpaAdminData;
    const { responses: backupsResponses, scores: backupsScores } = lpaDataBackups;
    const { responses: cashOfficeResponses, scores: cashOfficeScores } = lpaDataCashOffice;
    const { responses: frontlineResponses, scores: frontlineScores } = lpaDataFrontline;
    const { responses: receivingResponses, scores: receivingScores } = lpaDataReceiving;
    const { responses: salesFloorResponses, scores: salesFloorScores } = lpaDataSalesFloor;
    const { responses: securityResponses, scores: securityScores } = lpaDataSecurity;
    return (
        // adminResponses.length === lpaAdminQuestions.LPAQuestions.length &&  
        // adminScores.length === lpaAdminQuestions.LPAQuestions.length 
        // &&
        backupsResponses.length === lpaBackupQuestions.LPAQuestions.length && 
        backupsScores.length === lpaBackupQuestions.LPAQuestions.length
        && 
        cashOfficeResponses.length === lpaCashOfficeQuestions.LPAQuestions.length &&  
        cashOfficeScores.length === lpaCashOfficeQuestions.LPAQuestions.length 
        && 
        frontlineResponses.length === lpaFrontlineQuestions.LPAQuestions.length &&  
        frontlineScores.length === lpaFrontlineQuestions.LPAQuestions.length 
        // && 
        // receivingResponses.length === lpaReceivingQuestions.LPAQuestions.length &&  
        // receivingScores.length === lpaReceivingQuestions.LPAQuestions.length 
        // && 
        // salesFloorResponses.length === lpaSalesFloorQuestions.LPAQuestions.length &&  
        // salesFloorScores.length === lpaSalesFloorQuestions.LPAQuestions.length 
        && 
        securityResponses.length === lpaSecurityQuestions.LPAQuestions.length &&  
        securityScores.length === lpaSecurityQuestions.LPAQuestions.length 
        
    );

};

    // Helper function to check if any of the atoms have data
    const anyAtomHasData = () => {
        const lpaDataAtoms = [
            lpaAdminData,
            lpaDataBackups,
            lpaDataCashOffice,
            lpaDataFrontline,
            lpaDataReceiving,
            lpaDataSalesFloor,
            lpaDataSecurity,
        ];

        return lpaDataAtoms.some((data) => {
            if (!data) return false; // If the data is empty, return false
            const { responses, scores } = data;
            return responses.length > 0 || scores.length > 0;
        });
    };


    const handleClick =() =>{
      navigate('/findingsLogs')
    }


      const auditLogsEmpty = () => auditLogs.every(data => !data);


         
      if (!Array.isArray(auditLogs)) {
        return <div>Loading...</div>; // or return an empty component if desired
      }

      const parseDateString = (dateString) => {
        const parts = dateString.split(' at ');
        const formattedDate = parts[0].replace('Friday, ', '').replace(/(st|nd|rd|th),/, ',') + ' ' + parts[1];
        return new Date(formattedDate);
      };

      const getLatestDate = () => {
        const dates = [
          lpaAdminData.dateTime,
          lpaDataBackups.dateTime,
          lpaDataCashOffice.dateTime,
          lpaDataFrontline.dateTime,
          lpaDataReceiving.dateTime,
          lpaDataSalesFloor.dateTime,
          lpaDataSecurity.dateTime,
        ];
      
        // Filter out undefined values and parse the dates manually
        const validDates = dates
          .filter(date => date)
          .map(date => new Date(date.replace(' at ', ' ')));
      
        // Find the maximum (latest) date
        const latestDate = new Date(Math.max(...validDates));
        
        // Format the latest date as needed
        const formattedLatestDate = latestDate.toLocaleString('en-US', {
          weekday: 'long',
          day: 'numeric',
          month: 'long',
          year: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
          hour12: true,
        });
        // console.log("Dates:", dates)
        // console.log("valid Dates:", validDates)
        // console.log("formatted Latest Date:", formattedLatestDate)
        return formattedLatestDate;
      };
      
      const latestFormattedDate = getLatestDate();

      const handleMaintenceClick =() =>{
        navigate('/auditMaintenance')
      }
      
    
    return (
        <div className='conatinerBackground'>
            <div className='btnContainer'>
                <div className='auditBtnContainer'>
                    <button className='startAuditBtn' onClick={handleNavClick}>Start New Audit</button>
                </div>
                <div className='auditMaintenBtnContainer'>
                    <button className='auditMaintenBtn' onClick={handleMaintenceClick}>Audit Maintenance</button>
                </div>
            </div>
           

             <div className='questionContainerStoreAudit'>
            {anyAtomHasData() ? (
                allAtomsAreFullyFilled() ? (
                    <table >
                        <thead>
                            <tr>
                                <th className="DateColWidth">Start Date</th>
                                <th className="siteNoColWidth">Site No</th>
                                <th className="ProgessColWidth">Progress</th>
                            </tr>
                        </thead>
                        <tbody>
                             {/* Show "Waiting for feedback" message with actual data  */}
                            <tr>
                                <td className="DateColWidth">{latestFormattedDate}</td>
                                <td className="siteNoColWidth">{auditSite.site}</td>
                                <td className="ProgessColWidth"><button className="btn btn-success" onClick={handleClick}>Findings</button></td>
                            </tr>
                        </tbody>
                    </table>
                ) : (
                    <table>
                        <thead>
                            <tr>
                                <th className="DateColWidth">Start Date</th>
                                <th className="siteNoColWidth">Site No</th>
                                <th className="ProgessColWidth">Progress</th>
                            </tr>
                        </thead>
                        <tbody>
                             {/* Show "Busy with Audit" message with actual data  */}
                            <tr>
                                <td className="DateColWidth">{latestFormattedDate}</td>
                                <td className="siteNoColWidth">{auditSite.site}</td>
                                <td className="ProgessColWidth"><Link className="busyWithAuditBtn" to={'/auditerPage'}>Busy with Audit</Link></td>
                            </tr>
                        </tbody>
                    </table>
                    )
                    ) : (
                        <div>No audits</div>
                    )}
                </div>
                <div className='passedTableTitle'>
                    <h4 className='passedTitleLbl'>Past Audits</h4>
                </div>
                <div className='passedTable'>
                    {!auditLogsEmpty() ? (
                        <table>
                            <thead>
                                <tr>
                                <th className='auditCodeColumnWidth'>Audit Code</th>
                                    <th className='compByColumnWidth'>Completed By</th>
                                    <th className='siteColumnWidth'>Site</th>
                                    <th className='dateColumnWidth'>Completion Date</th>
                                    <th className='latitudeColumnWidth'>Latitude</th>
                                    <th className='longitudeColumnWidth'>Longitude</th>
                                </tr>
                            </thead>
                            <tbody>
                                {auditLogs
                                    .sort((a, b) => parseDateString(b.completionDate) - parseDateString(a.completionDate))
                                    .map((details, index) => (
                                        <tr
                                            key={index}
                                            className='clickable-row'
                                            onClick={() => setClickedRowUrl(`/feedback/${details.auditCode}`)}
                                        >
                                             <td className='auditCodeColumnWidth'>{details.auditCode}</td>
                                            <td className='compByColumnWidth'>{details.user}</td>
                                            <td className='siteColumnWidth'>{details.site}</td>
                                            <td className='dateColumnWidth'>{details.completionDate}</td>
                                            <td className='latitudeColumnWidth'>{details.latitude}</td>
                                            <td className='longitudeColumnWidth'>{details.longitude}</td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                        ) : (
                        <div>
                            <label>No Data</label>
                        </div>
                    )}
                </div>
            </div>  
        );
    }

   

export default StoreAudit;

