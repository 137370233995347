import React from "react";
import { RecoilRoot } from "recoil";
import { Route, Routes, useLocation } from "react-router-dom";
import Login from "./Pages/Login/Login";
import NotFound from "./components/NotFound/NotFound"
import Sidebar from "./components/Sidebar/Sidebar";
import Protected from "./services/Protected";
import SuperAdminDashboard from './Pages/SuperAdminDashboard/SuperAdminDashboard';
import Dispatched from "./Pages/Dispatched/viewDispatch";
import Deliveries from "./Pages/Deliveries/viewDeliveries";
import ShowDispatchAddress from "./Pages/Dispatched/showDispatchAddress/showDispatchAdress";
import ShowDeliveryAddress from "./Pages/Dispatched/showDeliveryAddress/showDeliveryAddress";
import ShowOrderDetails from "./Pages/Dispatched/showOrderDetails/showOrderDetails";
import ShowDelDetails from "./Pages/Deliveries/deliveriesDetails/showDelDetails";
import Claims from "./Pages/Claims/Claims";
import Returned from "./Pages/Returned/Returned";
import Suppliers from "./Pages/Suppliers/Suppliers";
import ShowSuppliersDetails from "./Pages/Suppliers/suppliersDetails/showSuppliersDetails";
import ShowReturnedDetails from "./Pages/Returned/returnedDetails/showReturnedDetails";
import ShowClaimsDetails from "./Pages/Claims/clamisDetails/showClaimsDetails";
import WorxitAdminDashboard from "./Pages/WorxitAdminDashboard/WorxitAdminDashboard";
import AdminDashboard from "./Pages/AdminDashboard/AdminDashboard";
import EditCompany from "./components/Edit/EditCompany/EditCompany";
import EditAllUsers from "./components/Edit/EditAllUsers/EditAllUsers";
import EditAllMobileUsers from "./components/Edit/EditAllMobileUsers/EditAllMobileUsers";
import EditDispatchSite from "./components/Edit/EditDispatchSite/EditDispatchSite";
import EditDeliveriesSite from "./components/Edit/EditDeliveriesSite/EditDeliveriesSite";
import EditDevice from "./components/Edit/EditDevice/EditDevice";
import NoAccess from "./Pages/NoAccess/NoAccess";
import SupervisorDashboard from "./Pages/SupervisorDashboard/SupervisorDashboard";
import Orders from "./Pages/Orders/Orders";
import OrdersProgress from "./Pages/OrdersProgress/OrdersProgress";
import Pallets from "./Pages/pallets/pallets";
import ShowPalletDetails from "./Pages/pallets/showOrderDetails/showPalletDetails";
import StoreAudit from "./Pages/StoreAudit/StoreAudit";
import AuditerPage from "./Pages/AuditerPage/AuditerPage";
import Findinglogs from "./components/FindingsLogs/findingsLogs"
import Feedback from './components/FeedbackPage/feedbackPage';
import AuditMaintenance from "./Pages/AuditMaintenance/AuditMaintenance";

const AppRoutes = () => {
  const location = useLocation();
  const isLoggedIn = localStorage.getItem('status') === '1'; //Change this to a atom at a later stage
  return (

    <>
      <RecoilRoot>
        {location.pathname !== "/" && isLoggedIn && (
          <>
            <Sidebar>
              <Routes>
              <Route path="/superAdmin" element={
                <Protected>
                  <SuperAdminDashboard />
                </Protected>
              } />
              <Route path="/admin" element={
                <Protected>
                  <WorxitAdminDashboard />
                </Protected>
              } />
              <Route path="/manager" element={
                <Protected>
                  <AdminDashboard />
                </Protected>
              } />
              <Route path="/supervisor" element={
                <Protected>
                  <SupervisorDashboard />
                </Protected>
              } />
               <Route path="/orders" element={
                <Protected>
                  <Orders />
                </Protected>
              } />
              <Route path="/dispatched" element={
                <Protected>
                  <Dispatched />
                </Protected>
              } />
              <Route path="/deliveries" element={
                <Protected>
                  <Deliveries/>
                </Protected>
              } />          
              <Route path="/claims" element={
                <Protected>
                  <Claims/>
                </Protected>
              } />
              <Route path="/pallets" element={
                <Protected>
                  <Pallets/>
                </Protected>
              } />
              <Route path="/returned" element={
                <Protected>
                  <Returned/>
                </Protected>
              } />
              <Route path="/suppliers" element={
                <Protected>
                  <Suppliers/> 
                </Protected>
              } />
              <Route path="/ordersProgress" element={
                <Protected>
                  <OrdersProgress />
                </Protected>
              } />
              <Route path="/showDeliveryAddress/:deliveryAddNo" element={
                <Protected>
                  <ShowDeliveryAddress/>
                </Protected>
              } />
              <Route path="/showDispatchAddress/:dispatchSiteNo" element={
                <Protected>
                  <ShowDispatchAddress/>
                </Protected>
              } />
              <Route path="/showOrderDetails/:orderNo" element={
                <Protected>
                  <ShowOrderDetails/>
                </Protected>
              } />
              <Route path="/ShowDelDetails/:orderNo" element={
                <Protected>
                  <ShowDelDetails/>
                </Protected>
              } />
              <Route path="/showSuppliersDetails/:docNo" element={
                <Protected>
                  <ShowSuppliersDetails/>
                </Protected>
              } />
              <Route path="/showReturnedDetails/:docNo" element={
                <Protected>
                  <ShowReturnedDetails/>
                </Protected>
              } />
              <Route path="/showClaimsDetails/:orderNo" element={
                <Protected>
                  <ShowClaimsDetails/>
                </Protected>
              } />
               <Route path="/showPalletDetails/:palletReturnsNo" element={
                <Protected>
                  <ShowPalletDetails/>
                </Protected>
              } />
              <Route path="/editCompany/:coID" element={
                <Protected>
                  <EditCompany/>
                </Protected>
              } />
               <Route path="/editAllUsers/:id" element={
                <Protected>
                  <EditAllUsers/>
                </Protected>
              } />
              <Route path="/editAllMobileUsers/:id" element={
                <Protected>
                  <EditAllMobileUsers/>
                </Protected>
              } />
              <Route path="/editDispatchSite/:dispatchSiteNo" element={
                <Protected>
                  <EditDispatchSite/>
                </Protected>
              } />
                <Route path="/editDeliveriesSite/:deliveryAddNo" element={
                <Protected>
                  <EditDeliveriesSite/>
                </Protected>
              } />
              <Route path="/editDevice/:id" element={
                <Protected>
                  <EditDevice/>
                </Protected>
              } />
               <Route path="/storeAudit" element={
                <Protected>
                  <StoreAudit/>
                </Protected>
              } />
              <Route path="/auditerPage" element={
                <Protected>
                  <AuditerPage/>
                </Protected>
              } />
                 <Route path="/findingsLogs" element={
                <Protected>
                  <Findinglogs/>
                </Protected>
              } />
               <Route path= "/feedback/:auditCode" element={
                <Protected>
                  <Feedback/>
                </Protected>
              } />
              <Route path="/auditMaintenance" element={
                <Protected>
                  <AuditMaintenance/>
                </Protected>
              } />

              </Routes>
            </Sidebar>
          </>
        )}
         {location.pathname === "/" && <Login />}
        {!isLoggedIn && (
          <Routes>
            <Route path="/noAccess" element={<NoAccess />} />
            {/* <Route path="*" element={<NotFound />} /> */}
          </Routes>
        )}
      </RecoilRoot>
    </>
  );
};
export default AppRoutes;
