import React, {useState, useEffect} from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import "./Claims.css";

import 'jspdf-autotable';

const Claims = () =>{

    const [claims, setClaims]= useState([]);
    const [loading, setLoading] = useState(false);
    const [orderNoSearch, setOrderNoSearch] = useState("");
    const [dateTimeStart, setDateTimeStart] = useState(""); 
    const [message, setMessage] = useState("");
  
    
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const usersPerPage = rowsPerPage;
    const [pageNumber, setPageNumber] = useState(0);

    const handleRowsPerPageChange = (event) => {
      setRowsPerPage(parseInt(event.target.value));
      setPageNumber(0); // Reset the page number to 0
    };


    useEffect(() => {
      const fetchData = async () => {
        const response = await axios.get('https://worxit.co.za/api/zamatrack/claimsView.php');
        const newData = response.data.records;
        setClaims(newData);
      };
      fetchData();
      const intervalId = setInterval(fetchData, 3000);
      return () => clearInterval(intervalId);
    }, []);
    
    const filteredClaims= claims.filter(claims => {

        if (orderNoSearch !== "" || dateTimeStart !== "") {
          if (
            claims.docNo.includes(orderNoSearch) &&
            claims.dateTime.includes(dateTimeStart)
          ) {
            return claims;
          }
          return false;
        }
        return claims;
        });
      
      useEffect(() => {
        if (filteredClaims.length === 0 && (orderNoSearch !== "" || dateTimeStart !== "")) {
          setMessage("No Match Found");
        } else {
          setMessage("");
        }
      }, [filteredClaims, orderNoSearch, dateTimeStart]);


    return(
        
        <div style={{width: "100%"}}>
        <div className=" row filter" >
            <div className="column1">
                <h5>Doc Number:</h5>           
            </div>
            <div className="column2"> 
                <input
                className="searchInput"
                type="text"
                placeholder= "Search..."
                onChange={(e) => setOrderNoSearch(e.target.value)}/>  
            </div>
            <div className="column3">
                <h5>Date:</h5>
            </div>
                <div className="column4">
                    <input
                        className="searchInput"
                        type="date"
                        placeholder= "Search..."
                        onChange={(e) => setDateTimeStart(e.target.value, "from")}/>    
                </div>                  
    </div>
        <h7>Latest 5 Orders Completed...</h7>
        <table>
            <thead>
                <tr>
                    <th>Order No</th>
                    <th>Date Time</th>
                    <th>Site No</th>
                    <th>Receivers Name</th>
                    <th>Guards Name</th>
                </tr>
            </thead>
            <tbody>
            {loading ? (
                      <tr>
                        <td colSpan={11}>Loading...</td>
                      </tr>
                    ) : (        
                        filteredClaims
                        .sort((a, b) => new Date(b.dateTime) - new Date(a.dateTime))
                        .slice(pageNumber * usersPerPage, (pageNumber + 1) * usersPerPage)
                        .map((claims, index) =>(
                            <tr key={index}>   
                                <td ><Link className="btn btn-success" to={`/showClaimsDetails/${claims.orderNo}`}>
                                    {claims.orderNo}
                                    </Link>
                                </td>
                                {/* <td ><Link className="btn btn-success" to={`/showAddress/${dispatch.storeSiteNo}`}>
                                    {dispatch.storeSiteNo}
                                    </Link> 
                                </td> */}
                                 {/* <td>{receive.orderNo}</td>  */}
                                <td>{claims.dateTime}</td> 
                                <td>{claims.siteNo}</td> 
                                <td>{claims.receiversName}</td>
                                <td>{claims.guardsName}</td>
                            </tr>
                            ))
            )}
                </tbody>
            </table>   
            <div className="rows-per-page">
              <label>Rows per page:</label>
              <select className="pageRows" value={rowsPerPage} onChange={handleRowsPerPageChange}>
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
              </select>
            

            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              pageCount={Math.ceil(filteredClaims.length / usersPerPage)}
              onPageChange={({ selected }) => setPageNumber(selected)}
              containerClassName={"pagination"}
              previousLinkClassName={"pagination__link"}
              nextLinkClassName={"pagination__link"}
              disabledClassName={"pagination__link--disabled"}
              activeClassName={"pagination__link--active"}
            />
            </div>
            {message && <p className="NoMatchFoundMsg">{message}</p>}  
        </div>
        
        
    )
}

export default Claims;