import React, {useState, useEffect} from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import "./ViewMobileUsers.css";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import { red } from '@mui/material/colors';
import {
	Container,
	Grid,
	Paper,
} from "@mui/material";


const ViewMobileUsers = () =>{

    const [viewMobileUsers, setViewMobileUsers]= useState([]);
    const [loading, setLoading] = useState(false);
    const [searchColumn, setSearchColumn] = useState(""); 
    const [searchKeyword, setSearchKeyword] = useState(""); 
    const [message, setMessage] = useState("");
      
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const usersPerPage = rowsPerPage;
    const [pageNumber, setPageNumber] = useState(0);

    const handleRowsPerPageChange = (event) => {
      setRowsPerPage(parseInt(event.target.value));
      setPageNumber(0); // Reset the page number to 0
    };

    
    const usersTable = async () => {
      const response = await axios.get('https://worxit.co.za/api/zamatrack/view/viewMobileUsers.php');
      const newData = response.data.records;
      setViewMobileUsers(newData);
    };
    useEffect(() => {
      
      usersTable();
      const intervalId = setInterval(usersTable, 3000);
      return () => clearInterval(intervalId);
    }, []);
    
    const filteredSearch = viewMobileUsers.filter((user) => {
      if (searchColumn !== "") {
        return user[searchColumn].toLowerCase().includes(searchKeyword.toLowerCase());
      }
      // If "All" is selected, check if any column contains the search keyword
      return Object.values(user).some((value) =>
        value.toLowerCase().includes(searchKeyword.toLowerCase())
      );
    });
      
    useEffect(() => {
      if (filteredSearch.length === 0 && searchKeyword !== "") {
        setMessage("No Match Found");
      } else {
        setMessage("");
      }
    }, [filteredSearch, searchColumn, searchKeyword]);


      const deleteCompany = (id) =>{
        axios.delete("https://worxit.co.za/api/zamatrack/delete/deleteMobileUser.php", {data: {id: id}})
        .then(()=>{
          usersTable();
        }).catch(()=>{
            alert("Error, Cannot Delete User!");
        })
    }

    return(
        
        <div className="viewMobileUserDet">
        <Container maxWidth="sm" >
        <Grid
        container
        spacing={2}
        direction="column"
        justifyContent="center"
        style={{ minHeight: "30vh", }}
      >
      <Paper elelvation={2} sx={{ padding: 5 }}>
      <Grid container direction="column" spacing={2}>
        <Grid>
        <div className="row filter">
          <div className="column1AllUsers">
            <h5>Search By:</h5>
          </div>
          <div className="column2AllUsers">
            <select
              className="searchInput"
              value={searchColumn}
              onChange={(e) => setSearchColumn(e.target.value)}
            >
              <option value="">All</option>
              <option value="username">Username</option>
              <option value="role">Role</option>
              <option value="coID">Company ID</option>
            </select>
          </div>
        
          <div className="column3AllUsers">
            <input
              type="text"
              className="searchInput"
              value={searchKeyword}
              onChange={(e) => setSearchKeyword(e.target.value)}
            />
          </div>
        </div>
        
          {/* <h7>Latest 5 Orders Completed...</h7> */}
         <table className="mobileUsersTbl">
             <thead>
                 <tr>
                     <th>Username</th>
                     <th>role</th>
                    <th>Company ID</th>
                     <th>Site</th>
                     <th>Action</th>
                 </tr>
             </thead>
             <tbody>
             {loading ? (
                       <tr>
                         <td colSpan={11}>Loading...</td>
                       </tr>
                     ) : (        
                      filteredSearch
                         // .sort((a, b) => new Date(b.dateTime) - new Date(a.dateTime))
                        .slice(pageNumber * usersPerPage, (pageNumber + 1) * usersPerPage)
                         .map((viewAllMobUsers, index) =>(
                             <tr key={index}>                                  
                                 <td>{viewAllMobUsers.username}</td> 
                                <td>{viewAllMobUsers.mobileRole}</td> 
                                 <td>{viewAllMobUsers.coID}</td>
                                 <td>{viewAllMobUsers.site}</td>
                                 <td>
                                 <Link to={`/editAllUsers/${viewAllMobUsers.id}`}><EditIcon/></Link> 
                                 <button onClick={() => deleteCompany(viewAllMobUsers.id)}>< DeleteForeverIcon sx={{ color: red[900] }}/></button>
                               
                                 </td>
                             </tr>
                             ))
             )}
                 </tbody>
             </table>   
             <div className="rows-per-page">
               <label className="lblRowCo">Rows per page:</label>
               <select className="pageRowsCo" value={rowsPerPage} onChange={handleRowsPerPageChange}>
                 <option value={5}>5</option>
                 <option value={10}>10</option>
                 <option value={25}>25</option>
                 <option value={50}>50</option>
               </select>
            

             <ReactPaginate
               previousLabel={"<"}
               nextLabel={">"}
               pageCount={Math.ceil(filteredSearch.length / usersPerPage)}
               onPageChange={({ selected }) => setPageNumber(selected)}
               containerClassName={"pagination"}
               previousLinkClassName={"pagination__link"}
               nextLinkClassName={"pagination__link"}
               disabledClassName={"pagination__link--disabled"}
               activeClassName={"pagination__link--active"}
            />
             </div>
             {message && <p className="NoMatchFoundMsg">{message}</p>}  
        
        </Grid>
      </Grid>
      </Paper>
      </Grid>
      
      </Container>
          </div> 
    )
}

export default ViewMobileUsers;