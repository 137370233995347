import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Container,
  Button,
  Grid,
  Paper,
} from "@mui/material";
import { useRecoilState, useRecoilValue } from 'recoil';
import { companyIdState } from '../../RecoilStates/companyIdState';
import './CreateCompany.css'

const CreateCompany = () => {
  const [message, setMessage] = useState("");
  const [data, setData] = useState({
    coName: "",
    coID: ""
  })

  const [coId, setCoID] = useRecoilState(companyIdState)

  const companyID = useRecoilValue(companyIdState)

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  }

  useEffect(()=>{
    console.log("company ID: " , companyID)
  },[companyID])

  const handleSubmit = (e) => {
    e.preventDefault();

    const sendData = {
      coName: data.coName,
      coID: data.coID
    }
    console.log(sendData)
    axios.post("https://worxit.co.za/api/zamatrack/create/createCompany.php", sendData)
      .then((res) => {
        setMessage(res.data.message);
        setData({ coName: "", coID: "" });

        // Update Recoil state here
        setCoID(data.coID);
      })
      .catch((err) => {
        console.error(err);
        setMessage("An error occurred.");
      });

  }

  return (
    <div className="createCompany-form" >
      <Container maxWidth="sm" >
        <Grid
          container
          spacing={2}
          direction="column"
          justifyContent="center"
          style={{ minHeight: "60vh", }}
        >
          <Paper elelvation={2} sx={{ padding: 5 }}>
            <form onSubmit={handleSubmit}>
              <Grid container direction="column" spacing={2}>
                <h4 className="regTitle">Create Company</h4>
                <Grid item>
                  <input
                    type="text"
                    name="coName"
                    placeholder="Company Name"
                    variant="outlined"
                    required
                    value={data.coName}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item>
                  <input
                    type="text"
                    name="coID"
                    placeholder="Company ID"
                    variant="outlined"
                    required
                    value={data.coID}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item>
                  <Button type="submit" fullWidth variant="contained">
                    Create Company
                  </Button>
                  {message && <p className="errorMsg">{message}</p>}
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Grid>

      </Container>
    </div>
  );
};

export default CreateCompany;
