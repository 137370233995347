import React, {useEffect, useState,useRef} from "react";
import {  useParams,useNavigate } from "react-router-dom";
import axios from "axios";
import { CSVLink } from "react-csv";
import { Spin } from "react-cssfx-loading";
import ReactPaginate from 'react-paginate';
import 'jspdf-autotable';
import "./showDelDetails.css";
import Modal from 'react-modal';
import ExportToPDF from "./ExportToPDF/exportToPdf";
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import locationMarker from '../../../media/locationMarker.png';
import { AtomDispatchDetailState } from "../../../components/RecoilStates/AtomDispatchDetailState";
import { AtomDispatchDetailItemsState } from "../../../components/RecoilStates/AtomDispatchDetailItemsState"
import { AtomDeliveriesDetailState } from "../../../components/RecoilStates/AtomDeliveriesDetailState";
import { AtomDeliveriesDetailItemsState } from "../../../components/RecoilStates/AtomDeliveriesDetailItemsState"
import {useSetRecoilState, useRecoilValue, useResetRecoilState} from 'recoil'

function ShowRecDetails() {

    const { orderNo } = useParams();  
    const [loading, setLoading] = useState(false);
    const deliveriesDetData = useRecoilValue(AtomDeliveriesDetailState)
    const setDeliveriesDetData = useSetRecoilState(AtomDeliveriesDetailState)
    
    const deliveriesItemsDetData = useRecoilValue(AtomDeliveriesDetailItemsState);
    const setDeliveriesItemsDetState = useSetRecoilState(AtomDeliveriesDetailItemsState);

    
    const dispatchDetData = useRecoilValue(AtomDispatchDetailState);
    const setDispatchDetData = useSetRecoilState(AtomDispatchDetailState);

    const dispatchItemsDetData = useRecoilValue(AtomDispatchDetailItemsState)
    const setDispatchItemsDetData = useSetRecoilState(AtomDispatchDetailItemsState)

    const [csfModalIsOpen, setCsvModalIsOpen] = useState(false);   
    const [markerPopup, setMarkerPopup] = useState(false);
    const mapRef = useRef(null);

    const resetDispatchDetailsData = useResetRecoilState(AtomDispatchDetailState)
    const resetDeliveriesDetailsData = useResetRecoilState(AtomDeliveriesDetailState)
    const resetDispatchItemsDetData = useResetRecoilState(AtomDispatchDetailItemsState)
    const resetDeliveriesItemsDetData = useResetRecoilState(AtomDeliveriesDetailItemsState)

      //----------Pagination------------     
      const [rowsPerPage, setRowsPerPage] = useState(5);
      const usersPerPage = rowsPerPage;
      const [pageNumber, setPageNumber] = useState(0);
 
      const handleRowsPerPageChange = (event) => {
        setRowsPerPage(parseInt(event.target.value));
        setPageNumber(0); // Reset the page number to 0
      };
    
    const [data, setData] = useState({
      selectedData:"PleaseSelectTable"
    })
 
    useEffect(() => {
        deliveriesTbl();
        deliveriesItemsTbl();
        dispatchTbl();
        dispatchItemsTbl();
      
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const navigate = useNavigate();

    const receivingNavigate=()=>{
        navigate('/deliveries');
        resetDispatchDetailsData();
        resetDeliveriesDetailsData();
        resetDispatchItemsDetData();
        resetDeliveriesItemsDetData();
    }
  
    const dispatchTblHeaders =[
      {label: "Order No", key: "orderNo"},
      {label: "Date Time", key: "dateTime"},
      {label: "Dispatch Site No", key: "dispatchSiteNo"},
      {label: "Delivery Address No", key: "deliveryAddNo"},
      {label: "Dispatcher Name", key: "dispatcherName"},
      {label: "Door", key: "doorNo"},
      {label: "Vehicle Reg", key: "vehicleReg"},
      {label: "Vehicle Make", key: "vehicleMake"},
      {label: "Latitude", key: "latitude"},
      {label: "Longitude", key: "longitude"},
      {label: "Seal No", key: "sealNo"},
      {label: "Quantity No", key: "qtyNo"},
      {label: "Barcode", key: "barcode"},
      {label: "Description", key: "description"},
      {label: "Pallet No", key: "palletNoDis"},
    ]

    const dispatchTblData = [
      ...dispatchDetData.map(row => ({
        orderNo: row.orderNo,
        dateTime: row.dateTime,
        dispatchSiteNo: row.dispatchSiteNo,
        deliveryAddNo: row.deliveryAddNo,
        dispatcherName: row.dispatcherName,
        doorNo: row.doorNo,
        vehicleReg: row.vehicleReg,
        vehicleMake: row.vehicleMake,
        sealNo: row.sealNo,
        latitude: row.latitude,
        longitude: row.longitude
      })),
      ...dispatchItemsDetData.map(row => ({
        qtyNo: row.qtyNo,
        barcode: row.barcode,
        description: row.description || "No Description Found",
        palletNoDis: row.palletNoDis
      })),
    ];


    const deliveriesTblHeaders =[
      {label: "Order No", key: "orderNo"},
      {label: "Seal In", key: "sealInRec"},
      {label: "Seal Out", key: "sealOutRec"},
      {label: "Date Time", key: "dateTimeRec"},
      {label: "Receivers Name", key: "receiversName"},
      {label: "Guards Name", key: "guardsName"},
      {label: "Quantity No", key: "qtyNo"},
      {label: "Barcode", key: "barcode"},
      {label: "Description", key: "description"},
      {label: "Pallet No", key: "palletNoRec"},
    ]

    const deliveriesTblData = [
      ...deliveriesDetData.map(row => ({
        orderNo: row.orderNo,
        sealInRec: row.sealInRec,
        sealOutRec: row.sealOutRec,
        dateTimeRec: row.dateTimeRec,
        receiversName: row.receiversName,
        guardsName: row.guardsName,
      })),
      ...deliveriesItemsDetData.map(row => ({
        qtyNo: row.qtyNo,
        barcode: row.barcode,
        description: row.description || "No Description Found",
        palletNoRec: row.palletNoRec
      })),
    ];

  
    
    const deliveriesTbl = async () => {
      console.log(orderNo);
      setLoading(true);
      const result = await axios.get(
        "https://worxit.co.za/api/zamatrack/dispatchShowDelDetails.php?orderNo=" +
          orderNo
      );
      setDeliveriesDetData(result.data.records);
      setLoading(false);

     
    };

    const deliveriesItemsTbl = async () => {
      console.log(orderNo);
      setLoading(true);
      const result = await axios.get(
        "https://worxit.co.za/api/zamatrack/deliveriesItemsTable.php?orderNo=" +
          orderNo
      );
      setDeliveriesItemsDetState(result.data.records);
      setLoading(false);
    };

    const dispatchTbl = async () => {
      console.log(orderNo);
      setLoading(true);
      const result = await axios.get(
        "https://worxit.co.za/api/zamatrack/dispatchShowOrderDetails.php?orderNo=" +
          orderNo
      );
      setDispatchDetData(result.data.records);
      setLoading(false);

    };

    const customIcon = L.icon({
      iconUrl: locationMarker,
      iconSize: [14, 18], // Adjust the size as needed
    
      });

    const dispatchItemsTbl = async () => {
      console.log(orderNo);
      setLoading(true);
      const result = await axios.get(
        "https://worxit.co.za/api/zamatrack/dispatchItemsTable.php?orderNo=" +
          orderNo
      );
      setDispatchItemsDetData(result.data.records);
      setLoading(false);
    };

    const openModal = () => {
      setCsvModalIsOpen(true);  
    }
 
    return (
      <div>
        <div className="dispatchTbl">    
          <div>
            <label>Dispatch</label>
          </div>   
            <table>
              <thead>
                <tr>
                  <th>Order No</th>
                  <th className="dateTimeWidth">Date Time</th>
                  <th>Dispatch Site No</th>
                  <th>Delivery Address No </th>
                  <th>Dispatcher Name</th>
                  <th>Guards Name</th>
                  <th>Door</th>
                  <th>Vehicle Reg</th>
                  <th>Vehicle Make</th>
                  <th>Seal No</th>                   
                </tr>
              </thead>
              <tbody>
              {loading ? (
                      <tr>
                        <td colSpan={11}>
                          <div className="loadingSpinComponent">
                            <Spin />
                          </div>
                        </td>
                      </tr>
                        ) : dispatchDetData.length > 0 ? (    
                          dispatchDetData && dispatchDetData.map((details,index) => (
                        <tr key={index}>
                          <td>{orderNo}</td> 
                          <td>{details.dateTime}</td> 
                          <td>{details.dispatchSiteNo}</td> 
                          <td>{details.deliveryAddNo}</td>
                          <td>{details.dispatcherName}</td> 
                          <td>{details.guardsName}</td>
                          <td>{details.doorNo}</td> 
                          <td>{details.vehicleReg}</td> 
                          <td>{details.vehicleMake}</td> 
                          <td>{details.sealNo}</td> 
                        </tr>
                      ))
                      ) : (
                        <tr>
                          <td colSpan={11}>No data found</td>
                        </tr>
                      )}
              </tbody>
            </table>
           <div className="mapContainer">
            {dispatchDetData.length > 0 ? (
                <>
                <div id="map-container" className="map-container">
                  <MapContainer
                    center={[dispatchDetData[0].latitude, dispatchDetData[0].longitude]}
                    zoom={18}
                    style={{ height: "400px", width: "100%" }}
                    className="map"
                    ref={mapRef}
                  >
                    <TileLayer
                      attribution="Map data &copy; OpenStreetMap contributors"
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />

                    <Marker position={[dispatchDetData[0].latitude, dispatchDetData[0].longitude]} icon={customIcon}>
                      <Popup onClose={() => setMarkerPopup(false)}>
                        <div>
                          Latitude: {[dispatchDetData[0].latitude]}<br />
                          Longitude: {[dispatchDetData[0].longitude]}<br />
                        </div>
                      </Popup>
                    </Marker>
                  </MapContainer>  
                </div>
                 <div className="imageViewContainer">
                 
                 {dispatchDetData.map((details, index) => (
               <div key={index}>
                 <img className="imageUrlAll" src={details.imageUriAll} alt="imageUriAll" />
               </div>
             ))}
               </div> 
               </>
              ) : (
                <tr>
                  <td colSpan={6}>
                  No Data Found
                  </td> 
                </tr>
              )}
            </div> 
            
        </div>  
        <div className="dispatchItemsTbl">    
          <div>
            <label>Dispatch Items</label>
          </div>   
            <table>
              <thead>
                <tr>
                  <th>Order No</th> 
                  <th>Date Time</th>
                  <th>Quantity Number</th>
                  <th>Pallet No</th>
                  <th>Barcode</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
              {dispatchItemsDetData.length > 0 ? (    
                dispatchItemsDetData && dispatchItemsDetData
                .slice(pageNumber * usersPerPage, (pageNumber + 1) * usersPerPage)
                .map((details,index) => (
                  <tr key={index}>
                    <td>{orderNo}</td> 
                    <td>{details.dateTime}</td> 
                    <td>{details.qtyNo}</td> 
                    <td>{details.palletNoDis}</td>
                    <td>{details.barcode}</td>
                    <td>{details.description ? details.description : 'No description found'}</td>
                  </tr>
                ))
                ) : (
                  <tr>
                  {/* colSpan={5}. The 5 is the amount of columns */}
                  <td colSpan={6}>
                  <div className="loadingSpinComponent">
                      <Spin />
                    </div>
                  </td> 
                </tr>
                )}
                
              </tbody>
            </table>
            <div className="rows-per-page">
              <label>Rows per page:</label>
              <select className="pageRows" value={rowsPerPage} onChange={handleRowsPerPageChange}>
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
              </select>
            
            <div className="paginationRows">
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              pageCount={Math.ceil(dispatchItemsDetData.length / usersPerPage)}
              onPageChange={({ selected }) => setPageNumber(selected)}
              containerClassName={"pagination"}
              previousLinkClassName={"pagination__link"}
              nextLinkClassName={"pagination__link"}
              disabledClassName={"pagination__link--disabled"}
              activeClassName={"pagination__link--active"}
            />
            </div>
            </div>
        </div>         
        <div>
         <div>
            <label>Deliveries</label>
          </div>   
       <table>
          <thead>
            <tr>
              <th>Order No</th> 
              <th>Seal In</th>
              <th>Seal Out</th>
              <th>Date Time</th>             
              <th>Receivers Name</th>
              <th>Guards Name</th>
            </tr>
          </thead>
          <tbody>
          {loading ? (
                      <tr>
                        <td colSpan={11}>Loading...</td>
                      </tr>
                    ) : (    
                      deliveriesDetData && deliveriesDetData.map((details,index) => (
              <tr key={index}>
                <td>{orderNo}</td> 
                <td>{details.sealInRec}</td> 
                <td>{details.sealOutRec}</td> 
                <td>{details.dateTimeRec}</td> 
                <td>{details.receiversName}</td> 
                <td>{details.guardsName}</td> 

              </tr>
            )))}
            
          </tbody>
        </table>
        <div className="mapContainer">
            {deliveriesDetData.length > 0 ? (
                <>
                <div id="map-container" className="map-container">
                  <MapContainer
                    center={[deliveriesDetData[0].latitude, deliveriesDetData[0].longitude]}
                    zoom={18}
                    style={{ height: "400px", width: "100%" }}
                    className="map"
                    ref={mapRef}
                  >
                    <TileLayer
                      attribution="Map data &copy; OpenStreetMap contributors"
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />

                    <Marker position={[deliveriesDetData[0].latitude, deliveriesDetData[0].longitude]} icon={customIcon}>
                      <Popup onClose={() => setMarkerPopup(false)}>
                        <div>
                          Latitude: {[deliveriesDetData[0].latitude]}<br />
                          Longitude: {[deliveriesDetData[0].longitude]}<br />
                        </div>
                      </Popup>
                    </Marker>
                  </MapContainer>  
                </div>
                 <div className="imageViewContainer">
                 
                 {deliveriesDetData.map((details, index) => (
               <div key={index}>
                 <img className="imageUrlAll" src={details.imageUriAll} alt="imageUriAll" />
               </div>
             ))}
               </div> 
               </>
              ) : (
                <tr>
                  <td colSpan={6}>
                  No Data Found
                  </td> 
                </tr>
              )}
            </div> 
           
        </div>
        <div className="deliveriesItemsTbl">    
          <div>
            <label>Deliveries Items</label>
          </div>   
            <table>
              <thead>
                <tr>
                  <th>Order No</th> 
                  <th>Date Time</th>
                  <th>Quantity Number</th>
                  <th>Pallet No</th>
                  <th>Barcode</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
              {deliveriesItemsDetData.length > 0 ? (    
                          deliveriesItemsDetData && deliveriesItemsDetData
                          .slice(pageNumber * usersPerPage, (pageNumber + 1) * usersPerPage)
                          .map((details,index) => (
                  <tr key={index}>
                    <td>{orderNo}</td> 
                    <td>{details.dateTime}</td> 
                    <td>{details.qtyNo}</td> 
                    <td>{details.palletNoRec}</td>
                    <td>{details.barcode}</td>
                    <td>{details.description ? details.description : 'No description found'}</td>
                  </tr>
                ))
                ) : (
                  <tr>
                  {/* colSpan={5}. The 5 is the amount of columns */}
                  <td colSpan={6}>
                  <div className="loadingSpinComponent">
                      <Spin />
                    </div>
                  </td> 
                </tr>
                )}
                
              </tbody>
            </table>
            <div className="rows-per-page">
              <label>Rows per page:</label>
              <select className="pageRows" value={rowsPerPage} onChange={handleRowsPerPageChange}>
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
              </select>
            

            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              pageCount={Math.ceil(deliveriesItemsDetData.length / usersPerPage)}
              onPageChange={({ selected }) => setPageNumber(selected)}
              containerClassName={"pagination"}
              previousLinkClassName={"pagination__link"}
              nextLinkClassName={"pagination__link"}
              disabledClassName={"pagination__link--disabled"}
              activeClassName={"pagination__link--active"}
            />
            </div>
        </div>       
        <div className="actionBtns">  
          <button className="orderBackBtn" onClick={receivingNavigate}>Back</button>

          <button className="exportToCSVBtn" onClick={() => openModal()}>Export To CSV</button>     
          <Modal className="modal-container" isOpen={csfModalIsOpen} onRequestClose={() => setCsvModalIsOpen(false)} appElement={document.getElementById('root')}>
            <h6 className="modalTitle">Please Select Table</h6>
              <select className="modalDropdownMenu" value={data.selectedData} onChange={(e) => setData({ ...data, selectedData: e.target.value })}>
                <option value="PleaseSelectTable">Please Select Table</option>
                <option value="dispatchedTbl">Dispatched Report</option>
                <option value="deliveriesTbl">Deliveries Report</option>
              </select>
        
              {(() => {
                if (data.selectedData === "dispatchedTbl") {
                  return (
                    <div>
                      <CSVLink className="csvBtn"
                      data={dispatchTblData}
                      headers={dispatchTblHeaders}
                      filename={"Dispatched_Report.csv"}
                      target="_blank">
                        Export to csv
                      </CSVLink>
                    </div>
                  )
                }  else if (data.selectedData === "deliveriesTbl"){
                  return (
                    <div>
                      <CSVLink className="csvBtn"
                      data={deliveriesTblData}
                      headers={deliveriesTblHeaders}
                      filename={"Deliveries_Reports.csv"}
                      target="_blank">
                        Export to csv
                      </CSVLink>
                    </div>
                  )
                }
              })
              ()}
              <button className="modalCloseBtn" onClick={() => setCsvModalIsOpen(false)}>Close</button>
          </Modal> 

          <ExportToPDF mapRef={mapRef}/>   
       
        </div>
      </div>
    );
  }
  
  export default ShowRecDetails;
  