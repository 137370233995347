import React, {  useState } from "react";
import axios from "axios";
import {
  Container,
  Button,
  Grid,
  Paper,
} from "@mui/material";
import './CreateBarcodes.css'
import Papa from 'papaparse';

const CreateBarcodes = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [message, setMessage] = useState("");
  const [data, setData] = useState({
    barcode: "",
    description: "",
  })


  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  }

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const sendData = {
      barcode: data.barcode,
      description: data.description
    }
    console.log(sendData)
    axios.post("https://worxit.co.za/api/zamatrack/create/createBarcode.php", sendData)
      .then((res) => {
        setMessage(res.data.message);
        setData({ barcode: "", description: "" });
      })
      .catch((err) => {
        console.error(err);
        setMessage("An error occurred.");
      });

  }

  const handleUpload = async (event) => {
    event.preventDefault();
    
    if (selectedFile) {
      try {
        const formData = new FormData();
        formData.append('csvFile', selectedFile);

        axios.post("https://worxit.co.za/api/zamatrack/create/uploadBarcodeCSV.php", formData)
      .then((res) => {
        setMessage(res.data.message);
      })
      } catch (error) {
        setMessage("An error occurred.");
        console.error('Error uploading file:', error);
      }
    }
  };

  return (
    <div className="createBarcodes-form" >
      <Container maxWidth="sm" >
        <Grid
          container
          spacing={2}
          direction="column"
          justifyContent="center"
          style={{ minHeight: "60vh", }}
        >
          <Paper elelvation={2} sx={{ padding: 5 }}>
            <form onSubmit={handleSubmit}>
              <Grid container direction="column" spacing={2}>
                <h4 className="regTitle">Create Barcode</h4>
                <Grid item>
                  <input
                    type="text"
                    name="barcode"
                    placeholder="Barcode"
                    variant="outlined"
                    value={data.barcode}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item>
                  <input
                    type="text"
                    name="description"
                    placeholder="Desciption"
                    variant="outlined"
                    value={data.description}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item>
                  <Button type="submit" fullWidth variant="contained">
                    Create Barcodes
                  </Button>
                  <div>
                </div>
                  {message && <p className="errorMsg">{message}</p>}
                </Grid>
              </Grid>
            </form>
            {/* <form onSubmit={handleUpload}>
            <label>
              Select a CSV file:
              <input type="file" accept=".csv" onChange={handleFileChange} />
            </label>
            <button type="submit">Upload</button>
          </form> */}
          </Paper>
        </Grid>

      </Container>
    </div>
  );
};

export default CreateBarcodes;
