import React, { useEffect,useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import "../../../styles/LpaStoreAnalysisStyles.css";
import { lpaBackupsDetailsState } from '../../../components/RecoilStates/LpaBackupsDetailsState'
import { auditCodeAtom } from '../../../components/RecoilStates/auditCodeAtom';

const LpaBackupsFeedback = () => {
  const { auditCode } = useParams();
  
  const lpaBackupsDetails = useRecoilValue(lpaBackupsDetailsState);
  

  // Check if lpaAdminDetails is an array before using map()
  if (!Array.isArray(lpaBackupsDetails)) {
    return <div>Loading...</div>; // or return an empty component if desired
  }

  const filteredBackupsDetails = lpaBackupsDetails.filter(details => details.auditCode === auditCode);
 

  
    return (
        <div className='questionContainer'>
            <div className='titleHeader'>
                <h4 className='titleh4'>Section A</h4>
            </div>
            <table className='lpaStoreAnalysisTbl'>
                <thead className='lpaStoreAnalysisHeader'>
                    <tr className='lpaStoreAnalysisHeaderRow'>
                        {/* <th className="typeWidth">Type</th> */}
                        <th className="scoreTypeColumnWidth">Type</th>
                        <th className="questionsColumnWidth">LPA Questions</th>
                        <th className="answersColumnWidth" >Observation/Responses</th>
                        <th className="notesColumnWidth">Notes </th>
                    </tr>
                </thead>
                <tbody className='lpaStoreAnalysisBody'>
                    {filteredBackupsDetails.map((details, index) => (
                        <tr className='lpaStoreAnalysisBodyRow' key={index}>
                        <td className="scoreTypeColumnWidth">
                            {details.scores === 'Pass' ? (
                                <div className='passCircle'></div>
                            ) : details.scores === 'Fail' ? (
                                <div className='failCircle'></div>
                            ) : (
                                <div className='naCircle'></div>
                            )}
                        </td>
                        <td className="questionsColumnWidth">{details.question}</td>
                        <td className="answersColumnWidth">{details.responses} </td>
                        <td className="notesColumnWidth">{details.notes} </td>
                        </tr>
                    ))}
                    </tbody>
            </table>  
            <div className='titleFooter'>
                {filteredBackupsDetails.length > 0 && (
                    <h4 className='titleFooterH4'>Section A Score = {filteredBackupsDetails[0].totalScore}%</h4>
                )}
            </div>
        </div>
   
    )
}

export default LpaBackupsFeedback;